import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Viewer from "v-viewer/src/component.vue";
import map from "lodash/map";
import uniq from "lodash/uniq";
import includes from "lodash/includes";
import sumBy from "lodash/sumBy";
import words from "lodash/words";
import upperCase from "lodash/upperCase";
import remove from "lodash/remove";
import snakeCase from "lodash/snakeCase";
import lowerCase from "lodash/lowerCase";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";
import Dropzone from "vue2-dropzone";
import { Quill, quillEditor } from "vue-quill-editor";
import ImageResize from "quill-image-resize-module";
import axios from "axios";
import { Prop } from "vue-property-decorator";
import startCase from "lodash/startCase";
import debounce from "lodash/debounce";
import orderBy from "lodash/orderBy";

// Base Page
import BasePage from "@/pages/Base";

// Component Detail
import dataOrderId from "@/components/detailOrders/orderId.vue";
import dataCurrency from "@/components/detailOrders/currencyInfo.vue";

// Local Components
import PaymentHistory from "@/containers/PaymentHistory/PaymentHistory.vue";
import ProofOfPayment from "../ProofOfPayment/ProofOfPayment.vue";

// Services
import OrderFunctions from "../OrderBaseFunctions";
import Calculate from "@/services/Calculate";
import OrdersService from "@/services/Orders";
import StatusService from "@/services/Status";
import CityService from "@/services/City";
import ProvinceService from "@/services/Province";
import UserSettingsService from "@/services/UserSettings";
import CalculateFromExcelService from "@/services/CalculateFromExcel";
import PackageService from "@/services/Package";
import QualityControlService from "@/services/QualityControl";
import SettingsService from "@/services/GlobalSettings";
import CountryService from "@/services/Country";
import CourierService from "@/services/Courier";
import SupplierService from "@/services/Suppliers";
import Users from "@/services/UserSettings";
import ProductService from "@/services/Products";
import CategoryRules from "@/services/Rules";
import PaymentChannelsService from "@/services/PaymentChannels";
import CurrencyService from "@/services/Currency";
import WarehouseService from "@/services/Warehouses/warehouses";

// Configs
import config from "@/configs";
import QualityControl from "@/services/QualityControl";
import { filter, sortBy } from "lodash";

// Global Constant Variables
const storage = window.localStorage;
const baseUrl = config.api.baseURL;
Quill.register("modules/imageResize", ImageResize);

@Component({
  components: {
    Viewer,
    Dropzone,
    quillEditor,
    PaymentHistory,
    ProofOfPayment,
    dataOrderId,
    dataCurrency
  }
})
export default class OrderDetails extends BasePage {
  constructor() {
    super();
  }

  customChargesAfterSelection: any = [];
  filterSupplierNameSelected: any = "";
  filterCountrySelected: any = "";
  filterLoading: boolean = false;
  supplierIdAdditionalInfo: any = "";
  supplierNameAdditionalInfo: any = "";
  theSelectSuppName: any = "";
  theSelectCityName: any = "";

  editItemCityList: any = [];
  editItemSupplierList: any = [];
  searchEditItemSupplier: any = "";
  searchEditItemCity: any = "";
  searchEditItemCityById: any = "";
  num_the_check: number = 25;

  total_product_original_cost: number = 0;
  userCreatedOrder: any = "-";
  userUpdatedOrder: any = "-";

  // BaseURL
  baseUrl = baseUrl;

  payloadCal: any = {};
  payloadCalRev: any = {};

  hasInsuranceInCustomChargeDetail: boolean = false;
  hasFreightInCustomChargeDetail: boolean = false;

  insuranceInCustomChargeDetail: any = {};
  freightInCustomChargeDetail: any = {};

  insurance_value: number;
  freight_value: number;

  conversion: number = 0;
  user_conversion: number = 0;

  currentOrderCode: any = null;

  dateCreated: any = null;
  // dateCreated: any = '2020-05-14'
  dateMenu: boolean = false;

  modalGeneralInfoOrderType: any = {
    order_type: "",
    order_category: "",
    terms: "",
    load_type: "",
    insurance_value: 0,
    freight_value: 0,
    freightInCustomChargeDetail: 0,
    insuranceInCustomChargeDetail: 0
  };

  totalCustomChargeDetail: number = 0;
  totalCustomChargeDetailOriginal: number = 0;
  originalInsuranceCustomChargeDetail: number = 0;
  originalFreightCustomChargeDetail: number = 0;

  // currentShipmentMethod: string = ''
  setViewer: any = [];
  setMarkingCode: string = "";
  userMarkingCode: any = [];
  orderItemHasChangedTax: boolean = false;
  orderItemHasRevisedQty: number = 0;
  responseCalculation: any = {};
  responseCalculationRevised: any = {};
  responseLatestChild: any = {};

  user_id: number;
  memberOrderDetailMembership: string = "";
  logistic_total_original: number;
  taxCustomCharge: number;
  custom_charges: any = [];
  sum_total_duty_value: number;
  sum_total_wht_value: number;
  sum_total_vat_value: number;
  sum_total_volume: number;
  sum_total_weight: number;

  // Order Detail
  currentOrderDetails: any = {};
  currentOrderDetailsIncluded: any = {};
  orderDetailPane: boolean[] = [true];
  currentOrder: any = {};
  paymentStatus: string = "";
  remarkStatus: string = "";
  skuStatus: string = "";

  // Modal Billing
  billingModal: any = {
    open: false
  };

  // Modal Shipping
  shippingModal: any = {
    open: false
  };

  showingData: boolean = false;
  // Modal Packaging List
  packagingListModal: any = {
    open: false
  };

  packagingListDeleteModal: any = {
    open: false
  };

  idDelete: string = null;

  //Product Items
  products: any = [];
  orderItems: any = [
    {
      id: "",
      productQuantity: "",
      productUnit: "",
      products: null
    }
  ];
  tab: any = null;

  inputPackagingListName: string = null;
  inputPackagingListId: string = null;
  inputPackagingListCourierName: string = null;
  inputPackagingListCourierNameSlug: string = null;
  inputPackagingListCourierNew: string = null;
  inputCourierItem: boolean = false;
  inputPackagingListCourierCode: string = null;
  inputPackagingListTrackingCode: string = null;
  inputPackagingListLastMileCourier: string = null;
  inputPackagingListLastMileCourierSlug: string = null;
  inputFile: string = null;
  sisa: number = 0;
  packagingListData: any = {
    id: "",
    name: "",
    trackingCode: ""
  };

  tablePackageList: object[] = [
    {
      text: "Actions",
      align: "center",
      sortable: false,
      value: "images",
      class: "action-table-width"
    },
    {
      text: "Package Shipment ID",
      align: "center",
      sortable: false,
      class: "action-table-width"
    },
    {
      text: "Images",
      align: "center",
      sortable: false
    },
    {
      text: "Package Shipment Name",
      align: "center",
      sortable: false
    },
    {
      text: "First Mile Courier",
      align: "center",
      sortable: false
    },
    {
      text: "First Mile Tracking Code",
      align: "center",
      sortable: false
    },
    {
      text: "Last Mile Courier",
      align: "center",
      sortable: false
    },
    {
      text: "Last Mile Tracking Code",
      align: "center",
      sortable: false
    }
  ];

  tableInvoiceNumber: object[] = [
    {
      text: "Actions",
      align: "center",
      sortable: false,
      value: "images",
      class: "action-table-width"
    },
    {
      text: "Supplier Name",
      align: "center",
      sortable: false,
      class: "action-table-width"
    },
    {
      text: "Invoice No.",
      align: "center",
      sortable: false,
      class: "action-table-width"
    },
    {
      text: "Warehouse",
      align: "center",
      sortable: false,
      class: "action-table-width"
    },
    {
      text: "Tracking Code per Supplier",
      align: "center",
      sortable: false,
      class: "action-table-width"
    },
    {
      text: "Courier Name",
      align: "center",
      sortable: false,
      class: "action-table-width"
    }
  ];

  tableInvoiceNumberList: object[] = [];
  tableInvoiceNumberLoading: boolean = false;
  modalInvoiceNumber: boolean = false;
  selectedInvoiceNumber: any = null;
  supplierNameInvoice: any = null;
  supplierNameInvoiceCode: any = null;
  modalTrackingAndCourier: boolean = false;
  supplierNameTrackingCode: any = null;
  supplierNameCourierName: any = null;

  onInvoiceNumberModalOpen(invoice: any) {
    this.modalInvoiceNumber = true;
    this.selectedInvoiceNumber = invoice;
    this.supplierNameInvoice = invoice.supplierName;
    this.supplierNameInvoiceCode = invoice.invoice_code;
  }
  onTrackingAndCourierModalOpen(invoice: any) {
    this.modalTrackingAndCourier = true;
    this.selectedInvoiceNumber = invoice;
    this.supplierNameTrackingCode = invoice.tracking_code;
    this.supplierNameCourierName = invoice.courier_name;
  }
  async onEditInvoiceNumberSave() {
    try {
      this.showLoading({ text: "Saving..." });

      var setPayload = {
        supplier_id: this.selectedInvoiceNumber.supplier_id,
        order_id: this.$route.params.id,
        integrated_sub_order_id: this.selectedInvoiceNumber
          .integrated_sub_order_id,
        invoice_code: this.supplierNameInvoiceCode
      };

      // updateOrderEdit
      var setParams = {};
      var returnResp = await OrdersService.editInvoiceNumber(
        this.selectedInvoiceNumber.id,
        setPayload,
        setParams
      );
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
      this.getOrderDetails();
      this.modalInvoiceNumber = false;
      this.showSnackbar({
        text: "Edited Invoice Number Successfully!",
        color: "teal",
        timeout: 1500
      });
    }
  }
  async onEditTrackingAndCourierSave() {
    try {
      this.showLoading({ text: "Saving..." });

      var setPayload = {
        supplier_id: this.selectedInvoiceNumber.supplier_id,
        order_id: this.$route.params.id,
        integrated_sub_order_id: this.selectedInvoiceNumber
          .integrated_sub_order_id,
        tracking_code: this.supplierNameTrackingCode,
        courier_name: this.supplierNameCourierName
      };
      var setParams = {};
      var returnResp = await OrdersService.editInvoiceNumber(
        this.selectedInvoiceNumber.id,
        setPayload,
        setParams
      );
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
      this.getOrderDetails();
      this.modalTrackingAndCourier = false;
      this.showSnackbar({
        text: "Edited Tracking Code and Courier Name Successfully!",
        color: "teal",
        timeout: 1500
      });
    }
  }

  tableQC: object[] = [
    {
      text: "Actions",
      align: "center",
      sortable: false,
      value: "images",
      class: "action-table-width"
    },
    {
      text: "Product Name",
      align: "center",
      sortable: false,
      class: "productName"
    },
    // {
    //  text: "Passed",
    //  align: "center",
    //  sortable: false,
    //  class: "passed"
    // },
    // {
    //  text: "Failed",
    //  align: "center",
    //  sortable: false,
    //  class: "failed"
    // },
    {
      text: "Quality Control",
      align: "center",
      sortable: false,
      class: "qualityControl"
    },
    {
      text: "Size",
      align: "center",
      sortable: false,
      class: "size"
    },
    {
      text: "Strange",
      align: "center",
      sortable: false,
      class: "strange"
    }
  ];

  tableItemsPackageList: object[] = [];
  dataPackageList: object[] = [];
  dataPackaging: object[] = [];
  tableItemsPackageItem: object[] = [];
  tableItemsShippingStatus: object[] = [];
  tableItemsQC: object[] = [];

  // Updated Billing & Shipping Details
  newAddress: any = {
    newFirstName: null,
    newLastName: null,
    newCountry: null,
    newProvince: null,
    newCity: {
      text: null,
      value: null
    },
    newSubdistrict: null,
    newStreetAddress: null,
    newPostCode: null,
    newPhone: null,
    newCompany: null,
    useContact: false,
    contact: {
      items: [],
      keyword: null,
      loading: false,
      selected: null
    }
  };

  // shipmentMethodInit: any = {
  //  currentShipmentMethod: null,
  //  items: [
  //    {
  //      text: "Air Cargo",
  //      value: "air"
  //    },
  //    {
  //      text: "Ocean Cargo",
  //      value: "ocean"
  //    },
  //    {
  //      text: "Air Cargo Door to Door",
  //      value: "air_dtd"
  //    },
  //    {
  //      text: "Ocean Cargo Door to Door",
  //      value: "ocean_dtd"
  //    }
  //  ]
  // }

  // Modal for general info edit order type
  // editGeneralInfoOrderType
  generalInfoOrderTypeModal: any = {
    open: false
  };

  orderDateModal: any = {
    open: false
  };

  orderCodeModal: any = {
    open: false
  };

  // Modal for edit currency info
  // editCurrencyInfo
  currencyInfoModal: any = {
    open: false
  };
  orderCurrency: string = "USD";
  goodsCurrency: string = "IDR";
  goodsCurrencyItems: any = ["IDR", "USD", "THB", "SGD", "PHP", "CNY", "AUD"];

  orderTypeTerms: any = [
    "EXW",
    "FCA",
    "FAS",
    "FOB",
    "CIF",
    "CNF(CFR)",
    "DDP",
    "DDU"
  ];

  orderCategory: any = [
    "import",
    "export",
    "local",
    "virtual",
    "inbound",
    "outbound"
  ];

  orderTypeLoad: any = ["FCL", "LTL", "FTL", "LCL"];

  pay_custom: boolean = false;
  pay_via_ali: boolean = false;

  // Modal Custom Charges
  modalCustomCharges: any = {
    open: false,
    newLabelBm: "BM",
    newValueBm: 0,
    newLabelVat: "VAT",
    newValueVat: 0,
    newLabelWHT: "WHT",
    newValueWHT: 0

    // newTotalDutyValue: 0,
    // newTotalWhtValue: 0,
    // newTotalVatValue: 0,
  };

  // orderType: any = {
  //  items: [
  //    {
  //      text: "Trading",
  //      value: "Trading"
  //    },
  //    {
  //      text: "Forwarding",
  //      value: "Forwarding"
  //    }
  //  ]
  // }

  orderType: any = ["trading", "forwarding"];

  // testValueOrderType: any = {
  //  text: "Trading",
  //  value: "Trading"
  // }

  // Modal Logistics
  logisticModal: any = {
    currencyOrder: null,
    currencyOrigin: null,
    conversion: null,
    weightMeasurement: "Kg",
    defaultVAT: null,
    terms: "EXW",
    loadType: "LCL",

    // orderType: {
    //  items: [
    //    {
    //      text: "Trading",
    //      value: "Trading"
    //    },
    //    {
    //      text: "Forwarding",
    //      value: "Forwarding"
    //    }
    //  ]
    // },

    currentShipmentMethod: null,
    shipmentMethodAgentInit: {
      items: [
        {
          text: "Air Cargo",
          value: "air"
        },
        {
          text: "Ocean Cargo",
          value: "ocean"
        },
        {
          text: "Air Cargo Door to Door",
          value: "air_dtd"
        },
        {
          text: "Ocean Cargo Door to Door",
          value: "ocean_dtd"
        },
        {
          text: "Air Courier",
          value: "air_courier"
        },
        {
          text: "Domestic",
          value: "domestic"
        },
        {
          text: "Official Trucking",
          value: "official_trucking"
        },
        {
          text: "Unofficial Trucking",
          value: "unofficial_trucking"
        }
      ]
    },
    shipmentMethodNonAgentInit: {
      items: [
        {
          text: "Air Cargo",
          value: "air"
        },
        {
          text: "Ocean Cargo",
          value: "ocean"
        }
      ]
    },

    open: false,
    newLogisticCost: null,
    newLogisticPerUnit: null,
    newInsuranceValue: 0,
    newFreightValue: 0,
    customCharges: [],

    volume_weight_charge: [],
    custom_logistic_charge: [],
    custom_charge_original: 0,
    logistic_charge_original: 0,

    total_goods_original: 0,
    total_logistic_all_in: 0,
    grand_total: 0
  };

  activeIndex: number = 0;

  // Modal Edit Weight & CBM
  editWeightOrCBM: any = {
    open: false,
    newAmount: null,
    currentlyEditing: null // 'weight' or 'cbm'
  };

  modalMarkingCode: any = {
    open: false
  };

  // Modal Edit Weight & CBM
  editPaidStatus: any = {
    // items: [
    //  {
    //    text: "Paid",
    //    value: "paid"
    //  },
    //  {
    //    text: "Partially Paid",
    //    value: "partial"
    //  },
    //  {
    //    text: "Unpaid",
    //    value: "unpaid"
    //  }
    // ],
    paymentItems: [],
    open: false,
    newStatus: null,
    valueStatus: null,
    paidDate: {
      open: false,
      date: null,
      dateFormatted: null,
      tempDate: null
    }
  };

  //remark status
  editRemarkStatus: any = {
    remarkItems: [],
    newRemark: null,
    valueRemark: null,
    open: false,
    notify: false,
    notes: null,
    nameRemark: null
  };

  // Order Status History
  orderStatusHistory: any = {
    open: false
  };
  // Modal edit Order Status History
  editOrderStatusHistoryModalDialog: any = {
    open: false
  };

  dateAddedDialogOpen: boolean = false;
  editOrderStatusHistoryData: any = {};

  tableOrderStatus: object[] = [
    {
      text: "Date Added",
      align: "left",
      sortable: false,
      value: "images",
      class: "action-table-width"
    },
    {
      text: "Notification",
      align: "left",
      sortable: false
    },
    {
      text: "Additional Note",
      align: "left",
      sortable: false
    },
    {
      text: "Order Status",
      align: "center",
      sortable: false
    },
    {
      text: "Customer Notified",
      align: "center",
      sortable: false
    },
    {
      text: "Actions",
      align: "center",
      sortable: false
    }
  ];
  tableItemsStatusOrder: any[] = [];
  tableLoadingStatusOrder: boolean = false;
  tableTotalItemsStatusOrder: string | number = 0;
  tableRowsPerPageItemsStatusOrder: number[] = [5, 10, 15, 25, 50, 100];
  tablePaginationStatusOrder: any = {
    sortBy: "created_at",
    page: 1,
    rowsPerPage: 10,
    descending: true,
    totalItems: 0
  };
  pagination: any = {
    sortBy: "id"
  };

  // Edit Product
  editProduct: any = {
    open: false,
    selectedProduct: null,
    skuStatusItems: []
  };

  productData: any = {
    landed_cost: null,
    revised_landed_cost: null
  };

  // Add Quality Control
  selectedQC: any = null;
  selectedDeleteQC: any = null;
  showDeleteQCModal: boolean = false;
  showDeleteAllQCModal: boolean = false;
  addQualityControl: any = {
    id: null,
    selectedIdOrderItem: null,
    open: false,
    groupQCItems: [],
    newQCGroup: null,
    valueGroupQC: null,
    failed: 0,
    passed: 0,
    currentGroup: null,
    product_order_id: null,
    selectedProduct: null,
    order_item_id: null,
    productName: null,
    note: null,
    image: null,
    selectedFile: null,
    currentGroupQualityControl: null,
    group: null
  };
  inputGroupItem: boolean = false;
  idQCGroup: number = null;

  dataQCs: any = {
    items: []
  };

  // Token
  token: string = storage.getItem("access_token");

  // Images
  options: any = {
    url: "https://httpbin.org/post", // dummy post data. this will change later after adding an image
    headers: {
      Authorization: "Bearer " + this.token,
      Identifier: "manage"
    },
    addRemoveLinks: true,
    dictDefaultMessage: "Click Here or Drop Image Here To Upload",
    autoProcessQueue: false,
    acceptedFileTypes: "image/*",
    maxNumberOfFiles: 1,
    maxFileSizeInMB: 2,
    duplicateCheck: true,
    thumbnailHeight: 250,
    thumbnailWidth: 250,
    paramName: "image"
  };

  optionsPackage: any = {
    url: "https://httpbin.org/post",
    headers: {
      Authorization: "Bearer " + this.token,
      Identifier: "manage"
    },
    addRemoveLinks: true,
    dictDefaultMessage: "Click Here or Drop Image Here To Upload",
    autoProcessQueue: false,
    acceptedFileTypes: "image/*",
    maxNumberOfFiles: 10,
    maxFileSizeInMB: 2,
    duplicateCheck: true,
    thumbnailHeight: 250,
    thumbnailWidth: 250,
    paramName: "images",
    parallelUploads: 10,
    uploadMultiple: true
  };
  dataImage: any = [];
  modalPackageImages: boolean = false;
  packageId: any = "";
  packageImagesDeleted: any = [];

  // Table Detail
  tableHeaders: object[] = [
    {
      text: "Actions",
      align: "center",
      sortable: false,
      value: "images",
      class: "action-table-width"
    },
    // {
    //   text: "Order Code",
    //   align: "left",
    //   sortable: false,
    //   value: "order_code"
    // },
    // {
    //   text: "Source",
    //   align: "left",
    //   sortable: false,
    //   value: "source_name"
    // },
    {
      text: "Product Name",
      align: "left",
      sortable: false,
      value: "productName"
    },
    {
      text: "Original Name",
      align: "left",
      sortable: false,
      value: "originalName"
    },
    {
      text: "Supplier",
      align: "left",
      sortable: false,
      value: "supplier"
    },
    {
      text: "Order Note",
      align: "center",
      sortable: false,
      value: "note"
    },
    {
      text: "Categories",
      align: "center",
      sortable: false,
      value: "category"
    },
    {
      text: "SKU",
      align: "center",
      sortable: false,
      value: "sku"
    },
    {
      text: "CBM (Volume)",
      align: "center",
      sortable: false,
      value: "qtyCBM"
    },
    {
      text: "Weight (KG)",
      align: "center",
      sortable: false,
      value: "weight"
    },
    {
      text: "Hscode",
      align: "left",
      sortable: false,
      value: "hscode"
    },
    {
      text: "Taxes",
      align: "left",
      sortable: false,
      value: "taxes"
    },
    {
      text: "Price",
      align: "right",
      sortable: false,
      value: "price"
    },
    {
      text: "Quantity",
      align: "center",
      sortable: false,
      value: "quantity"
    },
    {
      text: "Subtotal",
      align: "right",
      sortable: false,
      value: "subtotal"
    },
    {
      text: "Sku Status",
      align: "center",
      sortable: false,
      value: "skustatus"
    }
  ];

  newVal: object[] = [];
  tableItems: any = [];
  firstMileItems: object[] = [];
  lastMileItems: object[] = [];
  tableTotalItems: number | string = 0;
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100];
  tablePagination: any = {
    sortBy: "created_at",
    page: 1,
    rowsPerPage: 50,
    descending: true,
    totalItems: 0
  };
  tableLoading: boolean = false;
  tableLoadingPackage: boolean = false;
  tableLoadingQc: boolean = false;
  total_taxes: number;
  sum_total_taxes_per_item: number;
  sum_current_total_goods_value: number;
  cities: any = {
    items: [],
    selected: []
  };
  // Order id to get payment history
  orderId: string = "";

  // product id get from sending data
  skuId: string | number = "";

  renderPayment: string | number = 1;
  renderProof: string | number = 1;

  searchFirstMile: string = "";
  searchLastMile: string = "";

  originDestinationSupplierModal: any = {
    originCity: null,
    originProvince: null,
    origin: "",
    destination: "",
    supplierName: "",
    open: false
  };

  supplierItems: any = [];
  countryItems: any = [];
  cityItems: any = [];
  provinceItems: any = [];
  searchSupplier: string = "";
  searchCity: string = "";
  showAnalysis: boolean = false;
  totalLogisticValue: number = 0;
  totalLogisticValueRevised: number = 0;
  totalRequiredLogisticRevised: number = 0;
  totalCustomLogisticRevised: number = 0;
  totalRequiredLogistic: number = 0;
  totalCustomLogistic: number = 0;

  // Agent
  agents: any = {
    data: [],
    selected: [],
    loading: false,
    is_agent_value: false,
    keyword: null
  };
  pending_commission: number = 0;
  delAgentModal: boolean = false;
  responseOrder: any = {};
  searchContact: Function;
  //-Additional Info-//
  updateAdditionalInfo: boolean = false;
  editAdditionalInfo: any = {
    senderName: null,
    newNumberOrder: null,
    platforms: {
      items: [
        { text: "Woocommerce", value: "woocommerce" },
        { text: "Shopify", value: "shopify" },
        { text: "Shopee", value: "shopee" },
        { text: "Lazada", value: "lazada" },
        { text: "Tokopedia", value: "tokopedia" },
        { text: "Bukalapak", value: "bukalapak" }
      ],
      selected: null
    }
  };
  //-End-//

  addDocModal: boolean = false;
  addDocData: any = {};
  documents: any = [];
  selectedDoc: string = "";
  delDocModal: boolean = false;

  canAddProduct: boolean = false;
  addProductDialog: boolean = false;
  addProductData: any = {
    product: {
      loading: false,
      keyword: null,
      selected: null,
      items: []
    },
    variations: {
      getData: [],
      variants: [],
      headers: [],
      loading: false
    }
  };

  addSupplierData: any = {
    loading: false,
    keyword: null,
    selected: null,
    items: []
  };

  addCityData: any = {
    loading: false,
    keyword: null,
    selected: null,
    items: []
  };

  searchProduct: Function;
  deleteProductDialog: boolean = false;
  selectedDelProduct: string = "";

  checkQty: boolean = false;
  selectedBulkPrice: any = {};
  viaPlaceholder: any =
    "https://via.placeholder.com/150?text=No Image Available";

  // Payment Methods
  paymentChannelsList: any = [];
  paymentMethods: any = {
    payment_channel_ids: [],
    instruction: null
  };
  paymentMethodsModal: boolean = false;
  dataPaymentChannel: any = [];

  @Prop({ default: () => {} })
  imageOptions: object;

  get isPaid() {
    return this.currentOrderDetails.status === "paid";
  }

  get isPartiallyPaid() {
    return this.currentOrderDetails.status === "paid";
  }

  // @Watch('users.keyword')
  mounted() {
    // OrderFunctions
    this.getDocuments();
    this.getPaymentChannelsList();
    this.searchContact = debounce(this.getAllContact, 500);
    this.searchProduct = debounce(this.getProducts, 500);
  }

  @Watch("tablePaginationStatusOrder", { deep: true })
  onChangedStatus() {
    this.getStatusOrderHistory();
  }

  @Watch("tablePagination", { deep: true })
  onChanged() {
    this.$vuetify.goTo(0);
    this.getOrderDetails();
    this.getQCGroup();
    this.getQualityControlList();
    this.getPaymentStatus();
    this.getSkuStatus();
    this.getRemarkStatus();
    this.getCities();
    this.getPackageList();
    // this.checkMethod()
  }

  @Watch("newAddress.contact.keyword")
  async onChangedContact() {
    this.searchContact();
  }

  @Watch("agents.keyword")
  onAgentsChanged() {
    this.getAgents();
  }

  @Watch("searchFirstMile")
  async onSearchFirstMile() {
    if (
      this.searchFirstMile != "AsiaCommerce" &&
      this.searchFirstMile != this.inputPackagingListCourierName &&
      this.searchFirstMile != "Others..."
    ) {
      await this.getFirstMileCouriers(null);
      // console.log('this.inputPackagingListCourierName', this.inputPackagingListCourierName)
    }
  }

  @Watch("searchLastMile")
  async onSearchLastMile() {
    if (this.searchLastMile != this.inputPackagingListLastMileCourier) {
      await this.getLastMileCouriers();
      // console.log('this.inputPackagingListLastMileCourier', this.inputPackagingListLastMileCourier)
    }
  }

  @Watch("searchSupplier")
  async onSearchSupplier() {
    if (
      this.searchSupplier != this.originDestinationSupplierModal.supplierName
    ) {
      await this.getSupplier();
    }
  }

  @Watch("addProductData.product.keyword")
  async onSearchProduct() {
    this.searchProduct();
  }

  @Watch("addSupplierData.keyword")
  async onSearchSupplierEditItem() {
    // console.log("aaaaaaaav",this.addSupplierData.keyword)
    this.searchEditItemSupplier = this.addSupplierData.keyword;
    await this.editItemGetSupplier();

    // await this.getSupplier()
    // this.searchProduct()
  }

  @Watch("addCityData.keyword")
  async onSearchCityEditItem() {
    // console.log("aaaaaaaav",this.addSupplierData.keyword)
    this.searchEditItemCity = this.addCityData.keyword;
    await this.editItemGetCity();

    // await this.getSupplier()
    // this.searchProduct()
  }

  // @Watch("searchCity")
  // onSearchCity(){
  //  if(this.searchCity != null){
  //    this.getOriginCities()
  //    // const getDebounceCities = debounce(() => this.getOriginCities(), 1000)
  //    // getDebounceCities()
  //  }
  // }

  /**
   * [getMembershipUserDetail description]
   * @param {[number]} user_id [user id]
   */
  async getMembershipUserDetail(user_id) {
    // const responseUser = await UserSettingsService.getOneUser(user_id)
    const responseUser = await UserSettingsService.getOneUserWithInclude(
      user_id,
      {}
    );

    var membership_include_id =
      responseUser.data.data.relationships.memberships[0].id;
    var memberships =
      responseUser.data.included.memberships[membership_include_id].attributes
        .name;

    // set marking code to global variable this.userMarkingCode

    if (responseUser.data.data.relationships.codes.length > 0) {
      // this.userMarkingCode = []
      var user_relationship_markingcode =
        responseUser.data.data.relationships.codes;
      forEach(user_relationship_markingcode, itemMarkingCode => {
        var userMarkingCode = {
          marking_code:
            responseUser.data.included.codes[itemMarkingCode.id].attributes
              .code,
          id_marking_code:
            responseUser.data.included.codes[itemMarkingCode.id].attributes.id,
          user_id_marking_code:
            responseUser.data.included.codes[itemMarkingCode.id].attributes
              .user_id
        };
        this.userMarkingCode.push(userMarkingCode);
      });
      // code...
    }
    // else {
    //  var userMarkingCode = {}
    //  this.userMarkingCode.push(userMarkingCode)
    // }
    // console.log('this.userMarkingCode',this.userMarkingCode)

    return memberships;
  }

  openModalOrderDetail() {
    this.getStatusOrderHistory();
    this.orderStatusHistory.open = true;
  }
  onEditOrderStatusHistoryOpen(props) {
    this.editOrderStatusHistoryData = {
      status_history_id: props.item.id,
      dateAdded_dateFormated: this.toFullDate(props.item.dateAdded),
      dateAdded: this.toDatabaseDate(props.item.dateAdded),
      dateAdded_temp: null,
      addtional_notes: props.item.addtional_notes,
      remarkStatus: find(
        this.editRemarkStatus.remarkItems,
        item => item.text === props.item.order_status
      )
    };
    this.editOrderStatusHistoryModalDialog.open = true;
    this.orderStatusHistory.open = false;
  }
  onEditOrderStatusHistoryClosed() {
    this.editOrderStatusHistoryData = {};
    this.editOrderStatusHistoryModalDialog.open = false;
    this.orderStatusHistory.open = true;
  }
  onSelectDateAddedInput(event) {
    this.editOrderStatusHistoryData.dateAdded_temp = event;
  }
  onSelectDateAddedCancel() {
    this.dateAddedDialogOpen = false;
  }
  onSelectDateDateAddedDone() {
    if (this.editOrderStatusHistoryData.dateAdded_temp) {
      this.editOrderStatusHistoryData.dateAdded = this.editOrderStatusHistoryData.dateAdded_temp;
      this.editOrderStatusHistoryData.dateAdded_dateFormated = this.toFullDate(
        this.editOrderStatusHistoryData.dateAdded_temp
      );
    }
    this.dateAddedDialogOpen = false;
  }
  async deleteOrderStatusHistory(id) {
    try {
      this.showLoading({ text: "Deleting..." });
      const response = await OrdersService.deleteOrderStatusHistory(id);
      await this.getStatusOrderHistory();
      this.showSnackbar({
        text: "Deleted successfully",
        color: "green",
        timeout: 3500
      });
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }
  async onEditOrderStatusHistorySave() {
    try {
      this.showLoading({ text: "Saving..." });
      const validationResponse = await this.$validator.validateAll(
        "editOrderStatusHistoryScope"
      );
      if (validationResponse) {
        var setPayload = {
          order_id: +this.$route.params.id,
          status_id: this.editOrderStatusHistoryData.remarkStatus.value,
          date: this.editOrderStatusHistoryData.dateAdded,
          notes: this.editOrderStatusHistoryData.addtional_notes
        };
        // updateonEditOrderStatusHistorySave
        var setParams = {};
        var returnResp = await OrdersService.editOrderStatusHistory(
          this.editOrderStatusHistoryData.status_history_id,
          setPayload,
          setParams
        );
      } else {
        this.showSnackbar({
          text: "Please check all filed(s) requirements",
          color: "red",
          timeout: 3500
        });
      }
    } catch (error) {
      this.showSnackbar({
        text: error.response.data.message,
        color: "red",
        timeout: 3500
      });
    } finally {
      this.editOrderStatusHistoryData = {};
      this.editOrderStatusHistoryModalDialog.open = false;
      this.openModalOrderDetail();
      this.closeLoading();
    }
  }
  async getStatusOrderHistory() {
    const opts: any = {
      params: {
        "page[num]": this.tablePaginationStatusOrder.page,
        "page[limit]": this.tablePaginationStatusOrder.rowsPerPage,
        sort: this.tablePaginationStatusOrder.descending
          ? "-" + this.tablePaginationStatusOrder.sortBy
          : this.tablePaginationStatusOrder.sortBy,
        include: "status_histories"
      }
    };
    try {
      this.tableLoadingStatusOrder = true;
      const response: any = await OrdersService.getOneOrder(
        this.$route.params.id,
        opts
      );
      const statusHistoris = response.data.attributes.remark_status_histories;
      // console.log(response.data.attributes)
      this.tableItemsStatusOrder = [];
      forEach(statusHistoris, status_historis => {
        // console.log(status_historis, 'status history')
        for (const items of this.editRemarkStatus.remarkItems) {
          var statusName = "";
          if (items.value === status_historis.status_id) {
            statusName = items.text;
            break;
          }
        }
        // console.log(statusName, 'name')
        var datas = {
          id: status_historis.id,
          dateAdded: status_historis.date,
          notification: status_historis.title,
          addtional_notes: status_historis.notes,
          customer_notified: status_historis.is_notified,
          actionButton: false,
          order_status: statusName
        };
        this.tableItemsStatusOrder.push(datas);
        const findIndexStatusOrder = findIndex(
          this.tableItemsStatusOrder,
          table => table.id === datas.id
        );
        if (findIndexStatusOrder === -1 || findIndexStatusOrder === undefined) {
          this.tableItemsStatusOrder.push(datas);
        } else {
          this.tableItemsStatusOrder[findIndexStatusOrder] = datas;
        }
      });
      // console.log(this.tableItemsStatusOrder, 'items')
      this.tableTotalItemsStatusOrder = this.tableItemsStatusOrder.length;
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.tableLoadingStatusOrder = false;
    }
  }

  showData() {
    this.showingData = !this.showingData;
    if (
      this.productData.landed_cost === null &&
      this.productData.revised_landed_cost === null
    ) {
      for (let i = 0; i < this.tableItems.length; i++) {
        this.productData.landed_cost +=
          this.tableItems[i].landed_cost * this.tableItems[i].quantity;
        this.productData.revised_landed_cost +=
          this.tableItems[i].revised_landed_cost * this.tableItems[i].quantity;
      }
    }
  }

  // sini
  async getOrderDetails() {
    const hoho = await OrderFunctions.checkFunction();
    // console.log("hoho", hoho)
    try {
      const opts: any = {
        params: {
          include:
            "items,invoices,platforms,parent,child,packaging,user,channels,status_histories,custom_invoices"
        }
      };
      this.tableLoading = true;
      const response = await OrdersService.getOneOrder(
        this.$route.params.id,
        opts
      );

      this.responseOrder = response;
      const resultNewCalc = await OrderFunctions.setPayloadCalculation(
        response
      );

      this.currentOrderDetails = response.data.attributes;
      this.currentOrderDetailsIncluded = response.included;

      this.tableInvoiceNumberList = [];
      forEach(response.data.relationships.custom_invoices, async item => {
        const items = {
          ...response.included.custom_invoices[Number(item.id)].attributes,
          supplierName: await this.getSupplierById(
            response.included.custom_invoices[Number(item.id)].attributes
              .supplier_id
          )
        };
        // add warehouse in invoice number detail section
        var warehouseName: string = "";
        if (items.supplierName === "AsiaCommerce") {
          items.warehouseName = warehouseName;
        } else {
          var warehousesNameTemp = [];
          var isMixedWarehouse: boolean = false;
          for (let i = 0; i < response.data.attributes.details.length; i++) {
            if (
              response.data.attributes.details[i].attributes.supplier_name ===
              items.supplierName
            ) {
              warehousesNameTemp.push(
                response.data.attributes.details[i].attributes.warehouse
              );
              warehouseName =
                response.data.attributes.details[i].attributes.warehouse;
              items.warehouseName = warehouseName;
            }
          }
          for (let i = 0; i < warehousesNameTemp.length; i++) {
            if (warehousesNameTemp[i] !== warehousesNameTemp[0]) {
              isMixedWarehouse = true;
            }
          }
          if (isMixedWarehouse) {
            warehouseName = "Mixed Warehouse";
            items.warehouseName = warehouseName;
          } else {
            warehouseName = warehousesNameTemp[0];
            items.warehouseName = warehouseName;
          }
        }
        this.tableInvoiceNumberList.push(items);
      });

      this.getPaymentChannelOrder(this.currentOrderDetailsIncluded);
      let created_by_user_id = response.data.attributes.created_by;
      if (created_by_user_id.length > 0) {
        const responseCreatedBy = await UserSettingsService.getOneUser(
          created_by_user_id
        );
        this.userCreatedOrder = responseCreatedBy.data.data.attributes.name;
      }

      // Updated By
      let updated_by_user_id = response.data.attributes.updated_by;
      if(response.data.attributes.updated_by != null) {
          const responseUpdatedBy = await UserSettingsService.getOneUser(updated_by_user_id);
          this.userUpdatedOrder = responseUpdatedBy.data.data.attributes.name;
      }

      // Agent
      let ordered_by_user_id = response.data.attributes.ordered_by_user_id;
      if (response.data.attributes.ordered_by_user_id != null) {
        const response = await UserSettingsService.getOneUser(
          ordered_by_user_id
        );
        const responseData = response.data.data;
        this.currentOrderDetails.agent_name = responseData.attributes.name;
        this.currentOrderDetails.agent_id = responseData.attributes.id;
        this.agents.selected = responseData.attributes;
        this.agents.data = [];
        this.agents.data.push(responseData.attributes);
      }

      this.orderItemHasRevisedQty = this.currentOrderDetails.details.filter(
        x =>
          x.attributes.revised_quantity !== null &&
          x.attributes.revised_quantity !== x.attributes.quantity
      ).length;

      await this.getSelectedCities(this.currentOrderDetails.origin_city);
      this.originDestinationSupplierModal.origin = this.currentOrderDetails.origin;
      this.originDestinationSupplierModal.destination = this.currentOrderDetails.destination;
      this.originDestinationSupplierModal.supplierName = this.currentOrderDetails.supplier_name;

      const user_id = response.data.attributes.user_id;
      this.user_id = user_id;

      var userMembership = await this.getMembershipUserDetail(this.user_id);
      if (this.userMarkingCode.length > 0) {
        // code...
        this.currentOrderDetails.marking_code = this.userMarkingCode[0].marking_code;
        this.setMarkingCode = this.currentOrderDetails.marking_code;
      }

      this.memberOrderDetailMembership = userMembership;

      var arrCustomCharge = [];
      if (!isEmpty(response.data.relationships.customCharge)) {
        arrCustomCharge =
          response.included.customCharge[
            response.data.relationships.customCharge.id
          ].attributes.custom_charge_detail;
      }

      // set custom_logistic_charges for calculation formula
      var custom_logistic_charges = [];
      if (!isEmpty(arrCustomCharge)) {
        forEach(arrCustomCharge, charge => {
          var set_charge = {
            type: "custom-optional",
            group: charge.attributes.name,
            name: charge.attributes.name,
            price: charge.attributes.sub_total,
            // vat dihilangkan dulu
            // "vat": charge.attributes.vat
            vat: 0
          };
          custom_logistic_charges.push(set_charge);
        });
      }

      this.custom_charges = custom_logistic_charges;

      // // set shipment from if from record db is null
      if (response.data.attributes.shipment_from !== null) {
        response.data.attributes.shipment_from = Number(
          response.data.attributes.shipment_from
        );
      } else {
        response.data.attributes.shipment_from = 44;
      }
      // set shipment to if from record db is null
      if (response.data.attributes.shipment_to !== null) {
        response.data.attributes.shipment_to = Number(
          response.data.attributes.shipment_to
        );
      } else {
        response.data.attributes.shipment_to = 99;
      }

      // set pay custom
      if (response.data.attributes.pay_custom === 1) {
        this.pay_custom = true;
      }
      if (response.data.attributes.pay_custom === 0) {
        this.pay_custom = false;
        this.count_pay_custom++;
      }

      if (response.data.attributes.pay_via_ali === 1) {
        this.pay_via_ali = true;
      }
      if (response.data.attributes.pay_via_ali === 0) {
        this.pay_via_ali = false;
        this.count_pay_via_ali++;
      }

      var setCountryById = await CountryService.getCountryById(
        response.data.attributes.shipment_to
      );

      var setCurrencyOrder = setCountryById.data.data.attributes.currency;

      var setCountryById = await CountryService.getCountryById(
        response.data.attributes.shipment_from
      );

      var setCurrencyOrigin = response.data.attributes.currency_origin;
      var payload_from_order: any = {
        type: "order",
        ship_from: response.data.attributes.shipment_from,
        ship_to: response.data.attributes.shipment_to,
        shipping_method: response.data.attributes.shipment_method,
        logistic_per_unit: this.currentOrderDetails.logistic_per_unit,
        currency_order: setCurrencyOrder,
        currency_origin: setCurrencyOrigin,
        weight_measurement: "kg",
        insurance_value: response.data.attributes.insurance_value,
        freight_value: response.data.attributes.freight_value,
        insurance_value_order: response.data.attributes.insurance_value_order,
        freight_value_order: response.data.attributes.freight_value_order,
        order_type: this.currentOrderDetails.order_type,
        products: [],
        custom_logistic_charges: custom_logistic_charges,
        conversion_rate: this.currentOrderDetails.conversion,
        user_conversion: this.currentOrderDetails.user_conversion
      };

      if (response.data.attributes.freight_value_order === 0) {
        delete payload_from_order.freight_value_order;
      }

      if (response.data.attributes.insurance_value_order === 0) {
        delete payload_from_order.insurance_value_order;
      }

      payload_from_order.pay_custom = this.pay_custom;
      payload_from_order.pay_via_ali = this.pay_via_ali;
      //sini calculation baseline
      forEach(response.data.attributes.details, item => {
        if (isEmpty(item.attributes.item_rule.attributes.taxes)) {
          item.attributes.item_rule.attributes.taxes = [
            { label: "BM", value: 0 },
            { label: "PPN", value: 0 },
            { label: "PPH", value: 0 }
          ];
        }

        var arrItem: any = {};
        // check has variant or not
        if (item.attributes.variants[0].hasOwnProperty("origin")) {
          arrItem = {
            unit_name: item.attributes.unit_name,
            id: item.attributes.product_id,
            variants: []
          };

          // 0 = BM = duty
          // 1 = PPN = VAT
          // 2 = PPH = WHT

          var objVar = {
            price_custom_edit:
              Number(item.attributes.original_price) *
              Number(response.data.attributes.user_conversion),
            price_edit: item.attributes.cost,
            quantity: item.attributes.quantity,
            attributes: item.attributes.variants,
            settings: [
              {
                name: item.attributes.item_rule.attributes.taxes[0].label,
                value: item.attributes.item_rule.attributes.taxes[0].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[2].label,
                value: item.attributes.item_rule.attributes.taxes[2].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[1].label,
                value: item.attributes.item_rule.attributes.taxes[1].value
              },
              {
                name: "setting_weight",
                value: item.attributes.item_rule.attributes.weight
              },
              {
                name: "setting_volume_cbm",
                value: item.attributes.item_rule.attributes.qty_cbm
              }
            ]
          };
          arrItem.variants.push(objVar);
        } else {
          arrItem = {
            unit_name: item.attributes.unit_name,
            price_custom_edit:
              Number(item.attributes.original_price) *
              Number(response.data.attributes.user_conversion),
            price_edit: item.attributes.cost,
            id: item.attributes.product_id,
            type: "single",
            quantity: item.attributes.quantity,
            settings: [
              {
                name: item.attributes.item_rule.attributes.taxes[0].label,
                value: item.attributes.item_rule.attributes.taxes[0].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[2].label,
                value: item.attributes.item_rule.attributes.taxes[2].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[1].label,
                value: item.attributes.item_rule.attributes.taxes[1].value
              },
              {
                name: "setting_weight",
                value: item.attributes.item_rule.attributes.weight
              },
              {
                name: "setting_volume_cbm",
                value: item.attributes.item_rule.attributes.qty_cbm
              }
            ]
          };
        }
        payload_from_order.products.push(arrItem);
      });
      this.payloadCal = payload_from_order;
      var resultResponseCalculation = {
        data: {
          data: {}
        }
      };
      resultResponseCalculation = await Calculate.calculateOrder(
        payload_from_order
      );
      this.responseCalculation = resultResponseCalculation.data.data;

      var payload_from_order_revised: any = {
        type: "order",
        ship_from: response.data.attributes.shipment_from,
        ship_to: response.data.attributes.shipment_to,
        shipping_method: response.data.attributes.shipment_method,
        logistic_per_unit: this.currentOrderDetails.logistic_per_unit,
        currency_order: setCurrencyOrder,
        currency_origin: setCurrencyOrigin,
        weight_measurement: "kg",
        insurance_value: response.data.attributes.insurance_value,
        freight_value: response.data.attributes.freight_value,
        insurance_value_order: response.data.attributes.insurance_value_order,
        freight_value_order: response.data.attributes.freight_value_order,
        order_type: this.currentOrderDetails.order_type,
        products: [],
        custom_logistic_charges: custom_logistic_charges,
        revision: {},
        conversion_rate: this.currentOrderDetails.conversion,
        user_conversion: this.currentOrderDetails.user_conversion
      };

      if (response.data.attributes.freight_value_order === 0) {
        delete payload_from_order_revised.freight_value_order;
      }

      if (response.data.attributes.insurance_value_order === 0) {
        delete payload_from_order_revised.insurance_value_order;
      }

      payload_from_order_revised.pay_custom = this.pay_custom;
      payload_from_order_revised.pay_via_ali = this.pay_via_ali;

      // set revised total cbm for calculation
      if (
        this.currentOrderDetails.revised_total_cbm !== null &&
        this.currentOrderDetails.revised_total_cbm !== 0
      ) {
        payload_from_order_revised.revision.total_cbm = this.currentOrderDetails.revised_total_cbm;
      }

      // set revised total weight for calculation
      if (
        this.currentOrderDetails.revised_total_weight !== null &&
        this.currentOrderDetails.revised_total_weight !== 0
      ) {
        payload_from_order_revised.revision.total_weight = this.currentOrderDetails.revised_total_weight;
      }

      //sini calculation revised
      forEach(response.data.attributes.details, item => {
        var arrItem: any = {};
        // check has variant or not
        if (isEmpty(item.attributes.item_rule.attributes.taxes)) {
          item.attributes.item_rule.attributes.taxes = [
            { label: "BM", value: 0 },
            { label: "PPN", value: 0 },
            { label: "PPH", value: 0 }
          ];
        }

        if (item.attributes.variants[0].hasOwnProperty("origin")) {
          arrItem = {
            unit_name: item.attributes.unit_name,
            id: item.attributes.product_id,
            variants: []
          };

          // check qty revised
          if (item.attributes.revised_quantity !== null) {
            var objVar = {
              price_custom_edit:
                Number(item.attributes.original_price) *
                Number(response.data.attributes.user_conversion),
              price_edit: item.attributes.cost,
              quantity: item.attributes.revised_quantity,
              attributes: item.attributes.variants,
              settings: [
                {
                  name: item.attributes.item_rule.attributes.taxes[0].label,
                  value: item.attributes.item_rule.attributes.taxes[0].value
                },
                {
                  name: item.attributes.item_rule.attributes.taxes[2].label,
                  value: item.attributes.item_rule.attributes.taxes[2].value
                },
                {
                  name: item.attributes.item_rule.attributes.taxes[1].label,
                  value: item.attributes.item_rule.attributes.taxes[1].value
                },
                {
                  name: "setting_weight",
                  value: item.attributes.item_rule.attributes.weight
                },
                {
                  name: "setting_volume_cbm",
                  value: item.attributes.item_rule.attributes.qty_cbm
                }
              ]
            };
          } else {
            var objVar = {
              price_custom_edit:
                Number(item.attributes.original_price) *
                Number(response.data.attributes.user_conversion),
              price_edit: item.attributes.cost,
              quantity: item.attributes.quantity,
              attributes: item.attributes.variants,
              settings: [
                {
                  name: item.attributes.item_rule.attributes.taxes[0].label,
                  value: item.attributes.item_rule.attributes.taxes[0].value
                },
                {
                  name: item.attributes.item_rule.attributes.taxes[2].label,
                  value: item.attributes.item_rule.attributes.taxes[2].value
                },
                {
                  name: item.attributes.item_rule.attributes.taxes[1].label,
                  value: item.attributes.item_rule.attributes.taxes[1].value
                },
                {
                  name: "setting_weight",
                  value: item.attributes.item_rule.attributes.weight
                },
                {
                  name: "setting_volume_cbm",
                  value: item.attributes.item_rule.attributes.qty_cbm
                }
              ]
            };
          }
          arrItem.variants.push(objVar);
        } else {
          if (item.attributes.revised_quantity !== null) {
            arrItem = {
              unit_name: item.attributes.unit_name,
              price_custom_edit:
                Number(item.attributes.original_price) *
                Number(response.data.attributes.user_conversion),
              price_edit: item.attributes.cost,
              id: item.attributes.product_id,
              type: "single",
              quantity: item.attributes.revised_quantity,
              settings: [
                {
                  name: item.attributes.item_rule.attributes.taxes[0].label,
                  value: item.attributes.item_rule.attributes.taxes[0].value
                },
                {
                  name: item.attributes.item_rule.attributes.taxes[2].label,
                  value: item.attributes.item_rule.attributes.taxes[2].value
                },
                {
                  name: item.attributes.item_rule.attributes.taxes[1].label,
                  value: item.attributes.item_rule.attributes.taxes[1].value
                },
                {
                  name: "setting_weight",
                  value: item.attributes.item_rule.attributes.weight
                },
                {
                  name: "setting_volume_cbm",
                  value: item.attributes.item_rule.attributes.qty_cbm
                }
              ]
            };
          } else {
            arrItem = {
              unit_name: item.attributes.unit_name,
              price_custom_edit:
                Number(item.attributes.original_price) *
                Number(response.data.attributes.user_conversion),
              price_edit: item.attributes.cost,
              id: item.attributes.product_id,
              type: "single",
              quantity: item.attributes.quantity,
              settings: [
                {
                  name: item.attributes.item_rule.attributes.taxes[0].label,
                  value: item.attributes.item_rule.attributes.taxes[0].value
                },
                {
                  name: item.attributes.item_rule.attributes.taxes[2].label,
                  value: item.attributes.item_rule.attributes.taxes[2].value
                },
                {
                  name: item.attributes.item_rule.attributes.taxes[1].label,
                  value: item.attributes.item_rule.attributes.taxes[1].value
                },
                {
                  name: "setting_weight",
                  value: item.attributes.item_rule.attributes.weight
                },
                {
                  name: "setting_volume_cbm",
                  value: item.attributes.item_rule.attributes.qty_cbm
                }
              ]
            };
          }
        }
        payload_from_order_revised.products.push(arrItem);
      });

      var custom_charge_detail =
        response.included.customCharge[
          response.data.relationships.customCharge.id
        ].attributes.custom_charge_detail;
      var amountCustomChargeDetail =
        response.included.customCharge[
          response.data.relationships.customCharge.id
        ].attributes.amount;
      this.totalCustomChargeDetail = Number(amountCustomChargeDetail);
      this.totalCustomChargeDetailOriginal = Number(amountCustomChargeDetail);

      // check hasIsurance
      var ins = find(
        custom_charge_detail,
        item => item.attributes.name === "Insurance"
      );
      var fr = find(
        custom_charge_detail,
        item => item.attributes.name === "Freight"
      );

      this.conversion = Number(this.currentOrderDetails.conversion);
      this.user_conversion = Number(this.currentOrderDetails.user_conversion);

      if (typeof ins === "undefined") {
        // code...
        this.hasInsuranceInCustomChargeDetail = false;
      } else {
        this.hasInsuranceInCustomChargeDetail = true;
        this.insuranceInCustomChargeDetail = ins;
        this.originalInsuranceCustomChargeDetail = Number(
          ins.attributes.sub_total
        );
      }

      if (typeof fr === "undefined") {
        // code...
        this.hasFreightInCustomChargeDetail = false;
      } else {
        this.hasFreightInCustomChargeDetail = true;
        this.freightInCustomChargeDetail = fr;
        this.originalFreightCustomChargeDetail = Number(
          fr.attributes.sub_total
        );
      }

      var setTotalDuty = Number(this.responseCalculation.total_duty);
      var setTotalWht = Number(this.responseCalculation.total_wht);
      var setTotalVat = Number(this.responseCalculation.total_vat);

      payload_from_order_revised.revision.custom_charges = [
        {
          key: "total_duty",
          value: setTotalDuty
        },
        {
          key: "total_wht",
          value: setTotalWht
        },
        {
          key: "total_vat",
          value: setTotalVat
        }
      ];

      //sini calculation revised
      this.payloadCalRev = payload_from_order_revised;
      var resultResponseCalculationRevised = {
        data: {
          data: {}
        }
      };
      resultResponseCalculationRevised = await Calculate.calculateOrder(
        payload_from_order_revised
      );
      this.responseCalculationRevised =
        resultResponseCalculationRevised.data.data;

      var requiredLogistic = resultNewCalc.data.customized_logistic_charge;
      for (let i = 0; i < requiredLogistic.length; i++) {
        if (requiredLogistic[i].type !== "required") {
          requiredLogistic[i].type = "custom-optional";
        }
        this.responseCalculation.custom_logistic_charges = requiredLogistic;
        this.responseCalculationRevised.custom_logistic_charges = requiredLogistic;
      }

      var totalLogisticRevised = this.responseCalculationRevised
        .custom_logistic_charges;
      for (let i = 0; i < totalLogisticRevised.length; i++) {
        if (totalLogisticRevised[i].type === "required") {
          this.totalRequiredLogisticRevised += totalLogisticRevised[i].total;
        }
        if (totalLogisticRevised[i].type === "custom-optional") {
          this.totalCustomLogisticRevised += totalLogisticRevised[i].total;
        }
        this.totalLogisticValueRevised =
          this.totalRequiredLogisticRevised + this.totalCustomLogisticRevised;
        this.responseCalculationRevised.grand_total =
          this.responseCalculationRevised.total_goods_value +
          this.totalLogisticValueRevised +
          this.responseCalculationRevised.total_custom_charge;
      }

      var totalLogistic = this.responseCalculation.custom_logistic_charges;
      for (let i = 0; i < totalLogistic.length; i++) {
        if (totalLogistic[i].type === "required") {
          this.totalRequiredLogistic += totalLogistic[i].total;
        }
        if (totalLogistic[i].type === "custom-optional") {
          this.totalCustomLogistic += totalLogistic[i].total;
        }
        this.totalLogisticValue =
          this.totalRequiredLogistic + this.totalCustomLogistic;
        this.responseCalculation.grand_total =
          this.responseCalculation.total_goods_value +
          this.totalLogisticValue +
          this.responseCalculation.total_custom_charge;
      }

      const dataDetails = response.data.attributes;

      // cek item rule dan taxes
      forEach(dataDetails.details, detail => {
        // no tax in rule
        if (detail.attributes.item_rule.attributes.taxes === null) {
          const taxDefault = [
            {
              label: "BM",
              value: 0
            },
            {
              label: "PPN",
              value: 0
            },
            {
              label: "PPH",
              value: 0
            }
          ];
          detail.attributes.item_rule.attributes.taxes = taxDefault;
        }
      });

      this.currentOrder = response;

      // set total cbm
      this.currentOrderDetails.total_cbm = this.currentOrderDetails.total_cbm;

      // set total weight
      var sum_total_weight = this.currentOrderDetails.total_weight;
      this.sum_total_weight = sum_total_weight;
      this.currentOrderDetails.total_weight = this.currentOrderDetails.total_weight;

      this.tableItems = [];

      // set order code and source name for table
      var set_source_name = "";
      if (
        typeof response.included.platform === "object" &&
        response.included.platform.constructor === Object
      ) {
        var platform_id = parseInt(response.data.relationships.platform.id);
        var order_code = response.data.attributes.order_infos.order_id;
        set_source_name =
          response.included.platform[platform_id].attributes.name;
      } else {
        var order_code = response.data.attributes.order_code;
        const aliOrderCode = words(
          this.toStartCase(response.data.attributes.order_code)
        );
        var ali_2_order_code = `${aliOrderCode[0]}`;
        set_source_name = ali_2_order_code;
      }

      var total_product_original_cost = 0;
      forEach(dataDetails.details, async (dataDetail: any, index) => {
        var tempValeOrigin = 0;

        tempValeOrigin =
          Number(dataDetail.attributes.quantity) *
          Number(dataDetail.attributes.original_price);

        if (this.orderItemHasRevisedQty > 0) {
          tempValeOrigin =
            Number(dataDetail.attributes.revised_quantity) *
            Number(dataDetail.attributes.original_price);
        }

        total_product_original_cost =
          Number(total_product_original_cost) + Number(tempValeOrigin);

        this.total_product_original_cost = total_product_original_cost;
        var volume_from_cbm =
          1 / Number(dataDetail.attributes.item_rule.attributes.qty_cbm);
        if (isFinite(volume_from_cbm)) {
          var set_volume_from_cbm = Number(volume_from_cbm.toFixed(4));
        } else {
          var set_volume_from_cbm = 0;
        }

        var taxes_parse: any = {};
        if (
          typeof dataDetail.attributes.item_rule.attributes.taxes !== "object"
        ) {
          taxes_parse = JSON.parse(
            dataDetail.attributes.item_rule.attributes.taxes
          );
        } else {
          taxes_parse = dataDetail.attributes.item_rule.attributes.taxes;
        }

        var set_taxes_parse: any = {};
        if (taxes_parse === null) {
          set_taxes_parse = [
            {
              value: 0
            },
            {
              value: 0
            },
            {
              value: 0
            }
          ];
        } else {
          set_taxes_parse = taxes_parse;
        }

        // set price per item from calculation get from baseline
        var price_per_item = dataDetail.attributes.cost;

        if (
          this.responseCalculation.products[index].hasOwnProperty("variants")
        ) {
          var price_per_item = this.responseCalculation.products[index]
            .variants[0].price_per_item;
        } else {
          var price_per_item = this.responseCalculation.products[index]
            .price_edit;
        }

        const subtotal =
          Number(price_per_item) * Number(dataDetail.attributes.quantity);
        const city_id = dataDetail.attributes.city_id;
        let city_name = dataDetail.attributes.city_name;

        let theBm = find(
          set_taxes_parse,
          item => item.label.toLowerCase() === "bm"
        );
        let thePpn = find(
          set_taxes_parse,
          item => item.label.toLowerCase() === "ppn"
        );
        let thePph = find(
          set_taxes_parse,
          item => item.label.toLowerCase() === "pph"
        );
        const item: any = {
          ...dataDetail.attributes,
          currency_from_order: dataDetails.currency,
          cost_per_product: price_per_item,
          id: dataDetail.attributes.id,
          productName: dataDetail.attributes.product_name,
          originalName: dataDetail.attributes.original_name,
          original_source_url:
            dataDetail.attributes.product.attributes.original_source_url,
          original_url: dataDetail.attributes.product.attributes.original_url,
          original_variants: dataDetail.attributes.variants,
          order_code: order_code,
          source_name: set_source_name,
          images: [dataDetail.attributes.image_url],

          taxes: {
            bm: {
              original_value: theBm.value,
              edited: theBm.value
            },
            ppn: {
              original_value: thePpn.value,
              edited: thePpn.value
            },
            pph: {
              original_value: thePph.value,
              edited: thePph.value
            }
          },

          volume: set_volume_from_cbm,
          weight_in_kilos: Number(
            dataDetail.attributes.item_rule.attributes.weight
          ),
          item_rule: dataDetail.attributes.item_rule,
          rule:
            dataDetail.attributes.item_rule == null
              ? null
              : dataDetail.attributes.item_rule,
          total: subtotal,
          ratio_goods:
            (Number(subtotal.toFixed(2)) /
              Number(this.currentOrderDetails.cost)) *
            100,
          ratio_goods_per_qty:
            (Number(dataDetail.attributes.cost) /
              Number(this.currentOrderDetails.cost)) *
            100,
          // sini sku
          sku_status: dataDetail.attributes.item_status[0].attributes.name,
          custom_charge: this.custom_charges
          // city_name: city_name ? city_name : "-"
        };
        console.log("item.taxes", item.taxes);

        // Show Taxes in table as a text
        item.taxesText = `
          <b>BM: </b>${item.taxes.bm.original_value}% <br/>
          <b>PPN: </b>${item.taxes.ppn.original_value}% <br/>
          <b>PPH: </b>${item.taxes.pph.original_value}% <br/>
        `;

        if (
          city_id === null ||
          city_id === "0" ||
          city_id === 0 ||
          city_id === ""
        ) {
          item.city_name = "-";
        } else {
          item.city_name = await this.getCityById(city_id);
        }

        if (
          dataDetail.attributes.supplier_id === null ||
          dataDetail.attributes.supplier_name === null
        ) {
          item.supplier_name = "-";
        }
        if (dataDetail.attributes.note === null) {
          item.note = "-";
        }
        this.tableItems.push(item);
      });

      forEach(this.tableItems, (tableItem: any) => {
        if (!isEmpty(tableItem.variants)) {
          let text = tableItem.productName;
          forEach(tableItem.variants, (variantDetail: any) => {
            text =
              text + " - " + variantDetail.name + ": " + variantDetail.option;
          });

          this.products.push({
            text: text,
            value: tableItem.id
          });
        } else {
          this.products.push({
            text: tableItem.productName,
            value: tableItem.id
          });
        }
      });

      this.orderItems[0].products = this.products;
      this.tableTotalItems = this.tableItems.length;
      this.getCurrentPaymentStatus(
        this.currentOrder.data,
        this.currentOrder.included.statuses
      );

      this.reRenderPaymentHistory();
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.tableLoading = false;
      this.closeLoading();
    }
  }

  count_pay_custom: number = 0;
  @Watch("pay_custom")
  async on_pay_custom_changed() {
    if (this.count_pay_custom > 0) {
      // code...
      // console.log('this.pay_custom',this.pay_custom)
      // console.log('this.payloadCal',this.payloadCal)
      // console.log('this.pay_via_ali',this.pay_via_ali)
      // console.log('this.payloadCalRev',this.payloadCalRev)
      // console.log('this.currentOrderDetails',this.currentOrderDetails)

      try {
        this.showLoading({ text: "Saving..." });

        // sini
        // 1. dihitung dengan calculation dulu
        // 2. hasil calculation di update ke tabel order

        this.payloadCal.pay_custom = this.pay_custom;
        // ini ganti
        const responseCalculation = await Calculate.calculateOrder(
          this.payloadCal
        );
        // console.log('responseCalculation.data.data', responseCalculation.data.data)
        // this.responseCalculation = responseCalculation.data.data

        var setPayload = {
          pay_custom: this.pay_custom,
          total_duty: responseCalculation.data.data.total_duty,
          total_vat: responseCalculation.data.data.total_vat,
          total_wht: responseCalculation.data.data.total_wht
        };

        // updateOrderEdit
        var setParams = {};
        var returnResp = await OrdersService.updateOrderEdit(
          setPayload,
          setParams,
          this.$route.params.id
        );
      } catch (error) {
        this.catchHandler(error);
      } finally {
        // this.closeLoading()
        this.getOrderDetails();
        this.count_pay_custom++;
        // this.showSnackbar({
        //  text: "Saved Pay Custom Successfully!",
        //  color: "teal",
        //  timeout: 1500
        // })
      }
    }

    this.count_pay_custom++;
  }

  count_pay_via_ali = 0;
  @Watch("pay_via_ali")
  async on_pay_via_ali_changed() {
    if (this.count_pay_via_ali > 0) {
      try {
        this.showLoading({ text: "Saving..." });

        var setPayload = {
          pay_via_ali: this.pay_via_ali
        };

        // updateOrderEdit
        var setParams = {};
        var returnResp = await OrdersService.updateOrderEdit(
          setPayload,
          setParams,
          this.$route.params.id
        );
      } catch (error) {
        this.catchHandler(error);
      } finally {
        // this.closeLoading()
        this.getOrderDetails();
        this.count_pay_via_ali++;
        // this.showSnackbar({
        //  text: "Saved Pay Via ALI Successfully!",
        //  color: "teal",
        //  timeout: 1500
        // })
      }
    }
    this.count_pay_via_ali++;
  }

  // Get Payment Status
  async getPaymentStatus() {
    try {
      const opts: any = {
        params: {
          "filter[status_type][is]": 1
        }
      };

      const response = await StatusService.getOrderStatus(opts);
      const paymentStatus = response.data;

      for (const dataStatus of paymentStatus) {
        if (dataStatus.attributes.name !== "null") {
          const status: any = {
            text: this.toStartCase(dataStatus.attributes.name),
            value: dataStatus.attributes.id
          };
          this.editPaidStatus.paymentItems.push(status);
        }
      }
    } catch (error) {
      this.catchHandler(error);
    }
  }

  // Get Remark Status
  async getRemarkStatus() {
    try {
      const opts: any = {
        params: {
          "filter[status_type][is]": 2
        }
      };

      const response = await StatusService.getOrderStatus(opts);
      const paymentStatus = response.data;

      for (const dataStatus of paymentStatus) {
        const status: any = {
          text: this.toStartCase(dataStatus.attributes.name),
          value: dataStatus.attributes.id
        };
        this.editRemarkStatus.remarkItems.push(status);
      }
      // console.log(this.editRemarkStatus.remarkItems, 'item remark')
    } catch (error) {
      this.catchHandler(error);
    }
  }

  // Get SKU Status
  async getSkuStatus() {
    try {
      const opts: any = {
        params: {
          "filter[status_type][is]": 4
        }
      };

      const response = await StatusService.getOrderStatus(opts);
      const skuStatus = response.data;

      for (const dataStatus of skuStatus) {
        const status: any = {
          text: this.toStartCase(dataStatus.attributes.name),
          id: dataStatus.attributes.id
        };
        this.editProduct.skuStatusItems.push(status);
      }
    } catch (error) {
      this.catchHandler(error);
    }
  }

  // Show all status order
  getCurrentPaymentStatus(data, currentStatus) {
    for (const dataStatus of data.relationships.statuses) {
      var id_status = dataStatus.id;
      if (this.currentOrder.included.statuses[id_status].attributes.type == 1) {
        this.paymentStatus = this.currentOrder.included.statuses[
          id_status
        ].attributes.name;
        this.editPaidStatus.newStatus = {
          text: this.paymentStatus,
          value: this.currentOrder.included.statuses[id_status].attributes.id
        };
        this.editPaidStatus.valueStatus = this.currentOrder.included.statuses[
          id_status
        ].attributes.id;
        // this.editPaidStatus.newStatus = this.currentOrder.included.statuses[id_status].attributes.id
      } else if (
        this.currentOrder.included.statuses[id_status].attributes.type == 2
      ) {
        this.remarkStatus = this.currentOrder.included.statuses[
          id_status
        ].attributes.name;
        this.editRemarkStatus.newRemark = {
          text: this.remarkStatus,
          value: this.currentOrder.included.statuses[id_status].attributes.id
        };
        this.editRemarkStatus.valueRemark = this.currentOrder.included.statuses[
          id_status
        ].attributes.id;
        // this.editPaidStatus.newRemark = this.currentOrder.included.statuses[id_status].attributes.id
      }
    }
  }

  onMarkingCodeModalOpen() {
    this.modalMarkingCode.open = true;
  }

  onMarkingCodeModalClosed() {
    this.modalMarkingCode.open = false;
    this.setMarkingCode = this.currentOrderDetails.marking_code;
    // this.$validator.errors.clear()
    // this.$validator.reset()
  }

  onAddNewMarkingCode() {
    // console.log('vvv', this.currentOrderDetails.user_id)
    // console.log(this.$route)

    window.open("/manage-user", "_blank");
  }

  async onMarkingCodeModalSave() {
    try {
      this.showLoading({ text: "Saving..." });

      var setPayload = {
        marking_code: this.setMarkingCode
      };

      // updateOrderEdit
      var setParams = {};
      var returnResp = await OrdersService.updateOrderEdit(
        setPayload,
        setParams,
        this.$route.params.id
      );
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
      this.getOrderDetails();
      this.modalMarkingCode.open = false;
      this.showSnackbar({
        text: "Saved Marking Code Successfully!",
        color: "teal",
        timeout: 1500
      });
    }
  }

  // Edit Paid Status Methods
  onEditStatusModalOpen() {
    if (this.editPaidStatus.newStatus === null) {
      this.editPaidStatus.newStatus = {
        text: "unpaid all",
        value: 1
      };
      this.editPaidStatus.valueStatus = 1;
      // this.editPaidStatus.newStatus = 1
    }
    // if (this.editPaidStatus.newRemark === null) {
    //   this.editPaidStatus.newRemark = {
    //     text: "pending",
    //     value: 81
    //   }
    // this.editPaidStatus.valueRemark = 81
    // this.editPaidStatus.newRemark = 81
    // }

    if (this.currentOrderDetails.paid_date !== null) {
      // this.editPaidStatus.paidDate.date = new Date()
      this.editPaidStatus.paidDate.date = this.toDatabaseDate(
        this.currentOrderDetails.paid_date
      );
      this.editPaidStatus.paidDate.dateFormatted = this.toFullDate(
        this.currentOrderDetails.paid_date
      );
    }

    // if (this.currentOrderDetails.paid_date === null) {
    //  this.editPaidStatus.newStatus = "unpaid"
    // } else {
    //  this.editPaidStatus.newStatus = "paid"
    //  this.editPaidStatus.paidDate.date = new Date()
    //  this.editPaidStatus.paidDate.dateFormatted = this.toFullDate(
    //    this.editPaidStatus.paidDate.date
    //  )
    // }
    this.editPaidStatus.open = true;
  }

  // Edit Remark Status
  onEditRemarkModalOpen() {
    if (this.editRemarkStatus.newRemark === null) {
      // console.log('masuk null')
      this.editRemarkStatus.newRemark = {
        text: "pending",
        value: 81
      };
      this.editRemarkStatus.valueRemark = 81;
      // this.editPaidStatus.newRemark = 81
    }
    // else {
    //   console.log('masuk tidak null')
    //   const findStatus = this.editRemarkStatus.newRemark

    // }
    // console.log(this.editRemarkStatus.valueRemark, 'valueRemark')
    // console.log(this.currentOrderDetails.remark_status_details, 'hmmmmmm')
    // console.log(this.currentOrderDetailsIncluded.status_histories, 'status history')
    // if(!isEmpty(this.currentOrderDetailsIncluded.status_histories)){
    //   console.log('masuk')
    //   let find_status = filter(this.currentOrderDetailsIncluded.status_histories, historyStatus => historyStatus.attributes.status_id === this.editRemarkStatus.valueRemark)
    //   console.log(find_status, 'status')
    //   var arrayStatus = find_status.map(item => {
    //     return {...item, 'string_date': new Date(item.attributes.created_at).getTime()/1000}
    //   })

    //   let createdAtNewStatus = orderBy(arrayStatus, 'string_date', 'desc')
    //   console.log(createdAtNewStatus)
    // if(!isEmpty(createdAtNewStatus)){
    const nameStatus = find(
      this.editRemarkStatus.remarkItems,
      item => item.value === this.editRemarkStatus.valueRemark
    );
    // console.log(nameStatus)
    // console.log(this.editRemarkStatus.remarkItems.value, 'this', this.editRemarkStatus.valueRemark);

    this.editRemarkStatus.nameRemark = nameStatus.text;
    if (this.currentOrderDetails.remark_status_details.is_notified === "yes") {
      this.editRemarkStatus.notify = true;
    } else {
      this.editRemarkStatus.notify = false;
    }
    this.editRemarkStatus.notes = this.currentOrderDetails.remark_status_details.notes;

    //   }
    // }
    // console.log(this.editRemarkStatus.newRemark)
    // this.getOrderDetails()
    this.editRemarkStatus.open = true;
  }

  onRemarkStatusModalClosed() {
    this.editRemarkStatus.open = false;
    // this.editRemarkStatus.newRemark = null
    this.$validator.errors.clear();
    this.$validator.reset();
  }

  onEditStatusModalClosed() {
    this.editPaidStatus.newStatus = null;
    this.editPaidStatus.open = false;
    this.$validator.errors.clear();
    this.$validator.reset();
  }

  /**
   * [set all item tax to 0 for shipment method using door to door]
   */
  setTaxDoorToDoorInsideRuleItem() {
    const taxes = [
      {
        label: "BM",
        value: 0
      },
      {
        label: "PPN",
        value: 0
      },
      {
        label: "PPH",
        value: 0
      }
    ];
    return taxes;
  }

  /**
   * [onEditShipmentMethodChanged description]
   * set shipment method
   * air_dtd => set all tax to 0
   * ocean_dtd => set all tax to 0
   */
  onEditShipmentMethodChanged() {
    // console.log('in')
    // this.logisticModal.currentShipmentMethod
    if (this.logisticModal.currentShipmentMethod === "air_dtd") {
      forEach(this.currentOrderDetails.details, detail => {
        var taxes = this.setTaxDoorToDoorInsideRuleItem();
        detail.attributes.item_rule.attributes.taxes = taxes;
      });
    } else if (this.logisticModal.currentShipmentMethod === "ocean_dtd") {
      forEach(this.currentOrderDetails.details, detail => {
        var taxes = this.setTaxDoorToDoorInsideRuleItem();
        detail.attributes.item_rule.attributes.taxes = taxes;
      });
    } else if (this.logisticModal.currentShipmentMethod === "ocean") {
      var stringToFind = "(1 kg)";
      var stringForChange = "Estimate Volume Charge (1 CBM)";
      //fix total logistic charge
      var currentLogisticCharges = this.logisticModal.customCharges;
      var theSelected = null;
      forEach(currentLogisticCharges, theItemCharge => {
        var name = theItemCharge.name;
        var lowerName = name.toLowerCase();

        var group = theItemCharge.group;
        var lowerGroup = group.toLowerCase();

        if (
          lowerName.includes(stringToFind) ||
          lowerGroup.includes(stringToFind)
        ) {
          theSelected = theItemCharge.charge_detail_id;
        }
      });

      var checkCurrentCustomCharges = this.logisticModal.customCharges;
      var selectedToChange = {};
      var selectedIndex = null;

      if (theSelected !== null) {
        forEach(checkCurrentCustomCharges, (theItemCharge, index) => {
          if (theItemCharge.charge_detail_id === Number(theSelected)) {
            selectedToChange = {
              charge_detail_id: theItemCharge.charge_detail_id,
              grand_total: theItemCharge.grand_total,
              group: stringForChange,
              is_vat: theItemCharge.is_vat,
              name: stringForChange,
              sub_total: theItemCharge.sub_total,
              total: theItemCharge.total,
              vat: theItemCharge.vat,
              vat_value: theItemCharge.vat_value
            };
            selectedIndex = index;
          }
        });

        checkCurrentCustomCharges[selectedIndex] = selectedToChange;
      }
      this.logisticModal.customCharges = checkCurrentCustomCharges;
      this.customChargesAfterSelection = checkCurrentCustomCharges;
    } else if (this.logisticModal.currentShipmentMethod === "air") {
      var stringToFind = "(1 cbm)";
      var stringForChange = "Estimate Weight Charge (1 KG)";

      var currentLogisticCharges = this.logisticModal.customCharges;
      var theSelected = null;
      forEach(currentLogisticCharges, theItemCharge => {
        var name = theItemCharge.name;
        var lowerName = name.toLowerCase();

        var group = theItemCharge.group;
        var lowerGroup = group.toLowerCase();

        if (
          lowerName.includes(stringToFind) ||
          lowerGroup.includes(stringToFind)
        ) {
          theSelected = theItemCharge.charge_detail_id;
        }
      });

      var checkCurrentCustomCharges = this.logisticModal.customCharges;
      var selectedToChange = {};
      var selectedIndex = null;

      if (theSelected !== null) {
        forEach(checkCurrentCustomCharges, (theItemCharge, index) => {
          if (theItemCharge.charge_detail_id === Number(theSelected)) {
            selectedToChange = {
              charge_detail_id: theItemCharge.charge_detail_id,
              grand_total: theItemCharge.grand_total,
              group: stringForChange,
              is_vat: theItemCharge.is_vat,
              name: stringForChange,
              sub_total: theItemCharge.sub_total,
              total: theItemCharge.total,
              vat: theItemCharge.vat,
              vat_value: theItemCharge.vat_value
            };
            selectedIndex = index;
          }
        });

        checkCurrentCustomCharges[selectedIndex] = selectedToChange;
      }
      this.logisticModal.customCharges = checkCurrentCustomCharges;
      this.customChargesAfterSelection = checkCurrentCustomCharges;
    } else {
      // console.log('edited to air')
    }
  }

  onEditStatusChanged() {
    this.editPaidStatus.paidDate.date = this.toDatabaseDate(
      new Date().toString()
    );
    this.editPaidStatus.paidDate.dateFormatted = this.toFullDate(
      this.editPaidStatus.paidDate.date
    );
    this.editPaidStatus.valueStatus = this.editPaidStatus.newStatus;
    // if (this.editPaidStatus.newStatus !== "paid") {
    //  this.editPaidStatus.paidDate.date = this.toDatabaseDate(
    //    new Date().toString()
    //  )
    //  this.editPaidStatus.paidDate.dateFormatted = this.toFullDate(
    //    this.editPaidStatus.paidDate.date
    //  )
    // } else {
    //  this.editPaidStatus.paidDate.date = null
    //  this.editPaidStatus.paidDate.dateFormatted = null
    // }
  }
  async onEditMarkingCodeChanged() {}
  onEditRemarkChanged() {
    this.editRemarkStatus.valueRemark = this.editRemarkStatus.newRemark;
    const nameStatus = find(
      this.editRemarkStatus.remarkItems,
      item => item.value === this.editRemarkStatus.valueRemark
    );
    this.editRemarkStatus.nameRemark = nameStatus.text;
  }
  onEditRemarkChangedInOrderStatusHistory() {
    var newRemarkChangeValue = this.editOrderStatusHistoryData.remarkStatus;
    this.editOrderStatusHistoryData.remarkStatus = find(
      this.editRemarkStatus.remarkItems,
      item => item.value === newRemarkChangeValue
    );
  }
  onEditStatusItemChanged() {
    // this.editPaidStatus.valueRemark = this.editPaidStatus.newRemark
    // console.log(this.editProduct.selectedProduct.new_status_per_item)
  }
  onPaidDateInput(event) {
    this.editPaidStatus.tempDate = event;
  }
  onPaidDateCancel() {
    this.editPaidStatus.paidDate.open = false;
  }
  onPaidDateDone() {
    this.editPaidStatus.paidDate.date = this.editPaidStatus.tempDate;
    this.editPaidStatus.paidDate.dateFormatted = this.toFullDate(
      this.editPaidStatus.tempDate
    );
    this.editPaidStatus.paidDate.open = false;
  }
  async onEditStatusModalSave() {
    try {
      const valid = await this.$validator.validateAll("editStatus");
      if (valid) {
        this.showLoading({ text: "Saving..." });

        // const payload: any = {
        //  status: this.editPaidStatus.newStatus,
        //  paid_date: this.editPaidStatus.paidDate.date
        // }

        const payload_payment: any = {
          order_id: this.$route.params.id,
          status_id: this.editPaidStatus.valueStatus,
          status_type: 1,
          paid_date: this.editPaidStatus.paidDate.date,
          notify: this.editRemarkStatus.notify,
          notes: this.editRemarkStatus.notes
        };

        // const payload_remark: any = {
        //   order_id: this.$route.params.id,
        //   status_id: this.editRemarkStatus.valueRemark,
        //   status_type: 2,
        //   paid_date: this.editPaidStatus.paidDate.date,
        //   notify: this.editRemarkStatus.notify,
        //   notes: this.editRemarkStatus.notes
        // }

        // const setPayloadMarkingCode: any = {
        //     "marking_code": this.currentOrderDetails.marking_code
        // }
        // // updateOrderEdit
        // var setParamsMarkingCode = {}
        // var returnResp = await OrdersService.updateOrderEdit(
        //  setPayloadMarkingCode,
        //  setParamsMarkingCode,
        //  this.$route.params.id
        // )
        // console.log('returnResp',returnResp)

        // await OrdersService.updateOrder(this.$route.params.id, payload)
        await StatusService.setOrderStatus(payload_payment);
        // await StatusService.setOrderStatus(payload_remark)
        // console.log(payload_payment, 'payload payment')
        // console.log(payload_remark, 'payload remark')
        this.showSnackbar({
          text: "Saved Successfully!",
          color: "teal",
          timeout: 1500
        });

        this.onEditStatusModalClosed();
        this.getOrderDetails();
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  async onRemarkStatusModalSave() {
    try {
      const valid = await this.$validator.validateAll("editRemark");
      if (valid) {
        this.showLoading({ text: "Saving..." });
        // const payload_payment: any = {
        //   order_id: this.$route.params.id,
        //   status_id: this.editPaidStatus.valueStatus,
        //   status_type: 1,
        //   paid_date: this.editPaidStatus.paidDate.date,
        //   notify: this.editRemarkStatus.notify,
        //   notes: this.editRemarkStatus.notes
        // }

        const payload_remark: any = {
          order_id: this.$route.params.id,
          status_id: this.editRemarkStatus.valueRemark,
          status_type: 2,
          paid_date: this.editPaidStatus.paidDate.date,
          notify: this.editRemarkStatus.notify,
          notes: this.editRemarkStatus.notes
        };
        // await StatusService.setOrderStatus(payload_payment)
        await StatusService.setOrderStatus(payload_remark);
        // console.log(payload_payment, 'payload payment')
        // console.log(payload_remark, 'payload remark')
        this.showSnackbar({
          text: "Saved Successfully!",
          color: "teal",
          timeout: 1500
        });

        this.onRemarkStatusModalClosed();
        this.getOrderDetails();
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  // Billing & Shipping City Details
  async getCities() {
    try {
      const response = await CityService.getCities();
      if (!isEmpty(response.data.data.rajaongkir)) {
        const responseData = response.data.data.rajaongkir.results;
        forEach(responseData, dataCity => {
          const city = {
            ...dataCity,
            text: `${dataCity.type} ${dataCity.city_name}`,
            value: JSON.stringify(dataCity)
          };
          this.cities.items.push(city);
        });
      }
    } catch (error) {
      this.catchHandler(error);
    }
  }

  async cityAutoChange() {
    this.cities.selected = this.cities.items.find(
      x => x.text == this.cities.selected
    );
    const city = JSON.parse(this.cities.selected.value);
    this.newAddress.newProvince = city.province;
    this.newAddress.newPostCode = city.postal_code;
    this.newAddress.newCity.text = `${city.type} ${city.city_name}`;
    this.newAddress.newCity.value = city.city_id;
  }

  async getOrderItem(packingId) {
    try {
      const opts: any = {
        params: {
          include: "items"
        }
      };

      const response = await PackageService.getOnePacking(packingId, opts);

      return response;
    } catch (error) {
      this.catchHandler(error);
    }
  }

  async getPackageList() {
    try {
      const opts: any = {
        params: {
          //   "page[num]": 1,
          //   "page[limit]": 10,
          //   sort: "-created_at",
          include: "packaging,images",
          "filter[order_id][is]": this.$route.params.id
          //   page: this.tablePagination.page,
          //   limit: this.tablePagination.rowsPerPage,
          //   sortedBy: this.tablePagination.descending ? "desc" : "asc",
          //   orderBy: this.tablePagination.sortBy,
          //   include: "details.ref.images,details.ref.variation,user"
        }
      };
      this.tableLoadingPackage = true;
      this.tableItemsPackageList = [];
      this.dataImage = [];

      const response = await PackageService.getPackageLists(opts);

      if (!isEmpty(response.data)) {
        this.dataPackageList = response;
        forEach(response.data, (dataPackageList: any) => {
          const newData = {
            id: dataPackageList.attributes.id,
            name: dataPackageList.attributes.name,
            tracking_code: dataPackageList.attributes.tracking_code,
            courier_name: dataPackageList.attributes.courier_name,
            courier_name_slug: dataPackageList.attributes.courier_name_slug,
            tracking_code_courier:
              dataPackageList.attributes.tracking_code_courier,
            last_mile_slug: dataPackageList.attributes.last_mile_slug,
            last_mile_courier: dataPackageList.attributes.last_mile_courier,
            images: null
          };

          this.tableItemsPackageList.push(newData);

          if (!isEmpty(dataPackageList.relationships.images)) {
            forEach(dataPackageList.relationships.images, image => {
              this.dataImage.push(response.included.images[image.id]);
              newData.images =
                response.included.images[image.id].attributes.source;
            });
          }
        });
      }

      var availableOrderItem = [];
      let allPromise = [];

      forEach(response.included.packaging, (dataPacking: any) => {
        const orderItems = this.getOrderItem(dataPacking.id);
        allPromise.push(orderItems);
      });

      await Promise.all(allPromise).then(response => {
        this.dataPackaging = response;
        forEach(response, (result: any) => {
          if (!isEmpty(result.data.relationships.items)) {
            forEach(result.included.items, (dataItems: any) => {
              availableOrderItem.push({
                id: dataItems.attributes.id,
                qty:
                  dataItems.attributes.pivot.qty *
                  result.data.attributes.package_qty
              });
            });
          }
        });
      });

      // const optsPacking: any = {
      //  params: {
      //    'filter[id][in]': map(response.included.packaging, 'attributes.id'),
      //    include: "items,package"
      //  }
      // }

      // const responsePacking = await OrdersService.getByIdPacking(
      //  optsPacking
      // )

      // var availableOrderItem = []

      // forEach(responsePacking.data, (packing: any) => {
      //  if (packing.relationships.items.length !== 0) {
      //    forEach(packing.relationships.items, (item: any) => {
      //      console.log('packing', packing)
      //      console.log('responsePacking.included.items', responsePacking.included.items)
      //      availableOrderItem.push({
      //        id: Number(item.id),
      //        qty: responsePacking.included.items[Number(item.id)].attributes.pivot.qty * packing.attributes.package_qty
      //      })
      //    })
      //  }
      // })

      // console.log('availableOrderItem', availableOrderItem)

      var availableItem = [];
      const idUniq = uniq(map(availableOrderItem, "id"));
      var temp;
      forEach(idUniq, (iU: any) => {
        temp = 0;
        forEach(availableOrderItem, (aOI: any) => {
          if (iU == aOI.id) {
            temp += aOI.qty;
          }
        });
        availableItem.push({
          id: iU,
          qty: temp
        });
      });

      const optsOrder: any = {
        params: {
          include: "items,invoices,platforms,parent,child,packaging,user"
        }
      };

      const responseOrder = await OrdersService.getOneOrder(
        this.$route.params.id,
        optsOrder
      );

      this.sisa = 0;
      var dataDetails = responseOrder.data.attributes.details;
      forEach(dataDetails, (dataItem: any) => {
        const data = dataItem.attributes;
        if (availableItem.length !== 0) {
          if (includes(map(availableItem, "id"), data.id)) {
            if (
              data.quantity > find(availableItem, aOI => aOI.id === data.id).qty
            ) {
              this.sisa++;
            }
          } else {
            this.sisa++;
          }
        } else {
          this.sisa++;
        }
      });
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.tableLoadingPackage = false;
    }
  }

  async getFirstMileCouriers(param) {
    this.firstMileItems = [];
    const opts: any = {
      params: {}
    };

    if (!isEmpty(this.searchFirstMile)) {
      var textFilter = "filter[name][like]";
      opts.params[textFilter] = this.searchFirstMile;
    } else {
      var textFilter = "filter[name][like]";
      delete opts.params[textFilter];
    }

    var response = await CourierService.getCouriers(opts);
    // dataCourier.then(response => {
    if (response.data.success) {
      this.firstMileItems.push(
        {
          name: "Others...",
          name_slug: "oth"
        },
        {
          name: "AsiaCommerce",
          name_slug: "acn"
        }
      );
      // this.inputPackagingListCourierNameSlug = 'acn'
      forEach(response.data.data, (courierItem: any) => {
        this.firstMileItems.push({
          name: courierItem.attributes.name,
          name_slug: courierItem.attributes.name_slug
        });
      });
    } else {
      this.showSnackbar({
        text: "First Mile Courier Not Found!",
        color: "red",
        timeout: 3000
      });
    }

    if (!isEmpty(param)) {
      var packageList: any = {};
      packageList = find(
        this.tableItemsPackageList,
        (item: any) => item.id == param
      );

      if (
        packageList.courier_name_slug != "acn" &&
        packageList.courier_name_slug != null
      ) {
        this.firstMileItems.push({
          name: packageList.courier_name,
          name_slug: packageList.courier_name_slug
        });
      }

      if (
        packageList.last_mile_slug != null &&
        packageList.last_mile_courier != null
      ) {
        this.lastMileItems.push({
          name: packageList.last_mile_courier,
          name_slug: packageList.last_mile_slug
        });
      }

      this.inputPackagingListId = packageList.id;
      this.inputPackagingListName =
        packageList.name === "null" ? "" : packageList.name;
      this.inputPackagingListCourierNameSlug =
        packageList.courier_name_slug === "null"
          ? ""
          : packageList.courier_name_slug;
      this.inputPackagingListCourierName =
        packageList.courier_name === "null" ? "" : packageList.courier_name;
      this.inputPackagingListTrackingCode =
        packageList.tracking_code === "null" ? "" : packageList.tracking_code;
      this.inputPackagingListLastMileCourier =
        packageList.last_mile_courier === "null"
          ? ""
          : packageList.last_mile_courier;
      this.inputPackagingListLastMileCourierSlug =
        packageList.last_mile_slug === "null" ? "" : packageList.last_mile_slug;
      this.inputPackagingListCourierCode =
        packageList.tracking_code_courier === "null"
          ? ""
          : packageList.tracking_code_courier;
    }
  }

  getLastMileCouriers() {
    this.lastMileItems = [];

    const opts: any = {
      params: {}
    };

    if (!isEmpty(this.searchLastMile)) {
      var textFilter = "filter[name][like]";
      opts.params[textFilter] = this.searchLastMile;
    } else {
      var textFilter = "filter[name][like]";
      delete opts.params[textFilter];
    }

    var dataCourier = CourierService.getCouriers(opts);
    dataCourier.then(response => {
      if (response.data.success) {
        forEach(response.data.data, (courierItem: any) => {
          this.lastMileItems.push({
            name: courierItem.attributes.name,
            name_slug: courierItem.attributes.name_slug
          });
        });
      } else {
        this.showSnackbar({
          text: "Last Mile Couriers Not Found!",
          color: "red",
          timeout: 3000
        });
      }
    });
  }

  getSupplierLoading: boolean = false;

  setSupplierForAddInfo(val) {
    // console.log("setSupplierForAddInfo", val)
    this.supplierIdAdditionalInfo = val;
  }

  async getSupplierForAddInfo(name) {
    const opts: any = {
      params: {}
    };

    opts.params = {
      ...opts.params,
      "filter[name][like]": name
    };

    const dataSupplier = await SupplierService.getSuppliers(opts);
    return dataSupplier.data[0].attributes;
  }

  getSupplier() {
    this.getSupplierLoading = true;
    this.supplierItems = [];

    const opts: any = {
      params: {}
    };

    if (!isEmpty(this.searchSupplier)) {
      opts.params = {
        ...opts.params,
        "filter[name][like]": this.searchSupplier
      };
    } else {
      var textFilter = "filter[name][like]";
      delete opts.params[textFilter];
    }

    if (this.supplierNameAdditionalInfo.length > 0) {
      // console.log("masuk", this.supplierNameAdditionalInfo)
      opts.params = {
        ...opts.params,
        "filter[name][like]": this.supplierNameAdditionalInfo
      };
    }

    const dataSupplier = SupplierService.getSuppliers(opts);
    dataSupplier.then(response => {
      if (response.success) {
        forEach(response.data, (supplierItem: any) => {
          this.supplierItems.push(supplierItem.attributes.name);
        });

        if (this.originDestinationSupplierModal.supplierName != null) {
          this.supplierItems.push(
            this.originDestinationSupplierModal.supplierName
          );
        }

        if (this.supplierNameAdditionalInfo.length > 0) {
          // console.log("this.supplierNameAdditionalInfo.length > 0",response.data[0].attributes.id)
          // console.log("this.supplierNameAdditionalInfo.length > 0",this.supplierItems)
          // this.setSupplierForAddInfo(response.data[0].attributes.id)
          this.supplierIdAdditionalInfo = response.data[0].attributes.id;
          // this.supplierIdAdditionalInfo = response.data[0].attributes.id
          // supplierNameAdditionalInfo
        }
      } else {
        this.showSnackbar({
          text: "Supplier Not Found!",
          color: "red",
          timeout: 3000
        });
      }
      // console.log('response', response)
    });

    this.getSupplierLoading = false;
  }

  async getSupplierById(id: any) {
    const opts: any = {
      params: {
        "filter[id][is]": id
      }
    };

    const responseSupplier = await SupplierService.getSuppliers(opts);
    return responseSupplier.data[0].attributes.name;
  }

  async editItemGetSupplier() {
    const opts: any = {
      params: {}
    };

    // console.log((!isEmpty(this.searchEditItemSupplier)))
    // console.log(this.searchEditItemSupplier.length)
    // console.log(this.searchEditItemSupplier)
    // this.editProduct.selectedProduct.supplier_name = this.searchEditItemSupplier

    if (this.searchEditItemSupplier !== null) {
      if (this.searchEditItemSupplier.length > 0) {
        opts.params = {
          ...opts.params,
          "filter[name][like]": this.searchEditItemSupplier
        };
      } else {
        var textFilter = "filter[name][like]";
        delete opts.params[textFilter];
      }

      const dataSupplier = await SupplierService.getSuppliers(opts);
      // console.log("dataSupplier",dataSupplier)
      var arrSupp = [];
      forEach(dataSupplier.data, (supplierItem: any) => {
        var theItemSup = {
          name: supplierItem.attributes.name,
          id: supplierItem.attributes.id
        };
        arrSupp.push(theItemSup);
        // this.supplierItems.push(supplierItem.attributes.name)
      });

      // untuk dimasukkan ke dalam items supplier
      this.editItemSupplierList = arrSupp;
      // console.log("arrSupp", arrSupp)

      // editItemSupplierList
      // searchEditItemSupplier

      // console.log("this.editProduct.selectedProduct",this.editProduct.selectedProduct)
      if (this.editItemSupplierList.length === 1) {
        this.theSelectSuppName = this.editItemSupplierList[0].name;
      }
    } else {
    }

    // old
    // if (this.searchEditItemSupplier.length > 0) {
    //   opts.params = {
    //     ...opts.params,
    //     "filter[name][like]": this.searchEditItemSupplier
    //   }
    // } else {
    //   var textFilter = "filter[name][like]"
    //   delete opts.params[textFilter]
    // }

    // const dataSupplier = await SupplierService.getSuppliers(opts)
    // // console.log("dataSupplier",dataSupplier)
    // var arrSupp = []
    // forEach(dataSupplier.data, (supplierItem: any) => {
    //   var theItemSup = {
    //     name:supplierItem.attributes.name,
    //     id:supplierItem.attributes.id
    //   }
    //   arrSupp.push(theItemSup)
    //   // this.supplierItems.push(supplierItem.attributes.name)
    // })

    // // untuk dimasukkan ke dalam items supplier
    // this.editItemSupplierList = arrSupp
    // // console.log("arrSupp", arrSupp)

    // // editItemSupplierList
    // // searchEditItemSupplier

    // // console.log("this.editProduct.selectedProduct",this.editProduct.selectedProduct)
    // if(this.editItemSupplierList.length === 1) {
    //   this.theSelectSuppName = this.editItemSupplierList[0].name
    // }

    // const dataSupplier = SupplierService.getSuppliers(opts)

    // console.log("editItemGetSupplier", dataSupplier)
  }

  async editItemGetCity() {
    const opts: any = {
      params: {}
    };
    // console.log((!isEmpty(this.searchEditItemSupplier)))
    // console.log(this.searchEditItemCity.length)
    // console.log(this.searchEditItemCity)

    if (this.searchEditItemCity !== null) {
      opts.params = {
        ...opts.params,
        "filter[name][like]": this.searchEditItemCity
      };
    } else {
      var textFilter = "filter[name][like]";
      delete opts.params[textFilter];
    }

    // this.searchEditItemCityById
    // OLD
    // console.log("this.searchEditItemCityById", this.searchEditItemCityById)
    // if(this.searchEditItemCityById.length > 0) {
    if (this.searchEditItemCityById !== null) {
      var textFilter = "filter[id][is]";
      delete opts.params[textFilter];
    } else {
      opts.params = {
        ...opts.params,
        "filter[id][is]": Number(this.searchEditItemCityById)
      };
    }

    // console.log("opts", opts)

    const dataCities = await CityService.getCities(opts);
    // console.log("dataCities",dataCities)
    var arrCity = [];
    forEach(dataCities.data.data, (cityItem: any) => {
      // console.log("cityItem",cityItem)
      var theItemSup = {
        name: cityItem.attributes.name,
        id: cityItem.attributes.id
      };
      // console.log("theItemSup", theItemSup)
      arrCity.push(theItemSup);

      // this.supplierItems.push(supplierItem.attributes.name)
    });

    // untuk dimasukkan ke dalam items supplier
    this.editItemCityList = arrCity;

    // editItemSupplierList
    // searchEditItemSupplier

    // const dataSupplier = SupplierService.getSuppliers(opts)

    // console.log("editItemGetSupplier", dataSupplier)
  }

  async onOriginCountryChanged() {
    this.originDestinationSupplierModal.originProvince = null;
    this.originDestinationSupplierModal.originCity = null;
    await this.getOriginProvinces();
  }
  async onOriginProvinceChanged() {
    this.originDestinationSupplierModal.originCity = null;
    if (this.originDestinationSupplierModal.originProvince) {
      await this.getOriginCities();
    }
  }

  getCountryLoading: boolean = false;
  getCityLoading: boolean = false;
  getProvinceLoading: boolean = false;
  selectedOriginCity: any = null;
  selectedOriginProvince: any = null;

  async getOriginCountry() {
    this.getCountryLoading = true;
    try {
      const opts: any = {
        params: {
          "page[num]": 1,
          "page[limit]": 253
        }
      };
      await CountryService.getCountries(opts).then(response => {
        this.countryItems = [];
        if (response.data.success) {
          forEach(response.data.data, (countryItem: any) => {
            const country = {
              text: countryItem.attributes.name,
              value: countryItem.attributes.name
            };
            this.countryItems.push(country);
          });
        }
      });
      this.getCountryLoading = false;
    } catch (error) {
      this.catchHandler(error);
    } finally {
    }
  }

  async getOriginCities() {
    this.getCityLoading = true;
    try {
      const opts: any = {
        params: {
          "filter[countries.name][like]": this.originDestinationSupplierModal
            .origin,
          "page[limit]": 500
        }
      };

      if (this.originDestinationSupplierModal.originProvince != null) {
        opts.params = {
          ...opts.params,
          "filter[provinces.id][is]": this.originDestinationSupplierModal
            .originProvince.id
        };
      }
      // else {
      //  var textFilter = 'filter[provinces.id][is]'
      //  delete opts.params[textFilter]
      // }

      await CityService.getCities(opts).then(response => {
        this.cityItems = [];
        if (response.data.success) {
          forEach(response.data.data, (cityItem: any) => {
            const city = {
              text: cityItem.attributes.name,
              value: cityItem.attributes
            };
            this.cityItems.push(city);
          });
        }
        //  // console.log('response', response)
      });
      this.getCityLoading = false;
    } catch (error) {
      this.catchHandler(error);
    } finally {
    }
  }

  async getOriginProvinces() {
    this.getProvinceLoading = true;
    try {
      const opts: any = {
        params: {
          "filter[countries.name][like]": this.originDestinationSupplierModal
            .origin,
          "page[limit]": 500
        }
      };

      await ProvinceService.getProvinces(opts).then(response => {
        this.provinceItems = [];
        if (response.data.success) {
          forEach(response.data.data, (provinceItem: any) => {
            const province = {
              text: provinceItem.attributes.name,
              value: provinceItem.attributes
            };
            this.provinceItems.push(province);
          });
        }
        //  // console.log('response', response)
      });
      // console.log('this.provinceItems', this.provinceItems)
      this.getProvinceLoading = false;
    } catch (error) {
      this.catchHandler(error);
    } finally {
    }
  }

  getSelectedCities(id) {
    // console.log('masuk')
    // console.log('this.originDestinationSupplierModal.originCity', this.originDestinationSupplierModal.originCity)
    if (id != null) {
      // console.log('nono')
      const opts: any = {
        params: {
          "filter[id][is]": id,
          include: "provinces"
        }
      };

      const dataCity = CityService.getCities(opts);
      dataCity.then(response => {
        // console.log('response', response)
        if (response.data.success) {
          forEach(response.data.data, (cityItem: any) => {
            // const city = {
            //  text: cityItem.attributes.name,
            //  value: cityItem.attributes
            // }
            // this.cityItems.push(city)
            this.originDestinationSupplierModal.originCity =
              cityItem.attributes == undefined ? null : cityItem.attributes;
            this.selectedOriginCity = this.originDestinationSupplierModal.originCity;
            this.originDestinationSupplierModal.originProvince =
              response.data.included.provinces[cityItem.attributes.province_id]
                .attributes == undefined
                ? null
                : response.data.included.provinces[
                    cityItem.attributes.province_id
                  ].attributes;
            this.selectedOriginProvince = this.originDestinationSupplierModal.originProvince;
          });
        }
      });
      // console.log('this.cityItems', this.cityItems)
    } else {
    }
  }

  onPackagingListModalOpen(param) {
    this.getFirstMileCouriers(param);
    this.getLastMileCouriers();
    if (param !== null) {
      this.packagingListModal.open = true;
    } else {
      if (this.sisa === 0) {
        this.showSnackbar({
          text: "All Order Items have been packed!",
          color: "red",
          timeout: 3000
        });
      } else {
        this.packagingListModal.open = true;
      }
    }
  }

  onPackagingListModalClose() {
    this.inputPackagingListId = null;
    this.inputPackagingListName = null;
    this.inputPackagingListCourierName = null;
    this.inputPackagingListCourierNameSlug = null;
    this.inputPackagingListCourierNew = null;
    this.inputPackagingListCourierCode = null;
    this.inputPackagingListTrackingCode = null;
    this.inputPackagingListLastMileCourier = null;
    this.inputPackagingListLastMileCourierSlug = null;
    this.packagingListModal.open = false;
    this.inputCourierItem = false;
    this.$validator.errors.clear();
    this.$validator.reset();
  }

  onNewAddCourier() {
    if (this.inputPackagingListCourierNameSlug == "oth") {
      this.inputCourierItem = true;
    } else {
      this.inputCourierItem = false;
    }

    if (this.inputPackagingListCourierNameSlug == "acn") {
      this.inputPackagingListTrackingCode =
        "ACN-TR-" + Math.floor(Math.random() * 10000000 + 1);
    } else {
      this.inputPackagingListTrackingCode = null;
    }
  }

  addItem() {
    this.orderItems.push({
      id: "",
      productQuantity: "",
      productUnit: "",
      products: this.products
    });
  }

  removeItem(itemIndex) {
    this.orderItems.splice(itemIndex, 1);
  }

  onChangeProducts(index) {
    if (this.orderItems[index].id === undefined) {
      this.orderItems[index].productQuantity = "";
    } else {
      forEach(this.tableItems, (tableItem: any) => {
        if (tableItem.id === this.orderItems[index].id) {
          this.orderItems[index].productQuantity = tableItem.quantity;
        }
      });
    }
  }

  onPackagingListDeleted(index) {
    this.packagingListDeleteModal.open = true;
    this.idDelete = index;
  }

  onPackagingListDeletedClose() {
    this.packagingListDeleteModal.open = false;
    this.idDelete = null;
  }

  async onPackagingListDeletedSave() {
    try {
      this.showLoading({ text: "Deleting..." });
      const response = await PackageService.deletePackageList(this.idDelete);
      if (response.data.success) {
        this.getPackageList();

        this.showSnackbar({
          text: "Deleted Successfully!",
          color: "teal",
          timeout: 1500
        });
      } else {
        this.showSnackbar({
          text: startCase(response.data.message),
          color: "red",
          timeout: 5000
        });
      }

      this.idDelete = null;
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }

    this.packagingListDeleteModal.open = false;
  }

  async onPackagingListModalSave() {
    try {
      this.showLoading({ text: "Saving..." });
      if (this.inputPackagingListId === null) {
        const valid = await this.$validator.validateAll("addPackageList");
        if (valid) {
          var payload = {
            name: this.inputPackagingListName,
            order_id: Number(this.$route.params.id),
            courier_name: this.inputPackagingListCourierName,
            courier_name_slug: this.inputPackagingListCourierNameSlug,
            tracking_code: this.inputPackagingListTrackingCode,
            tracking_code_courier: this.inputPackagingListCourierCode,
            last_mile_courier:
              this.searchLastMile == null
                ? this.searchLastMile
                : this.inputPackagingListLastMileCourierSlug
          };

          if (this.inputCourierItem) {
            payload.courier_name_slug = this.inputPackagingListCourierNew;
          }

          // console.log('payload', payload)
          const response = await PackageService.createNewPackageList(payload);
          if (response.data.success) {
            this.getPackageList();
            this.showSnackbar({
              text: "Saved Successfully!",
              color: "teal",
              timeout: 1500
            });
          } else {
            this.showSnackbar({
              text: startCase(response.data.message),
              color: "red",
              timeout: 5000
            });
          }
          this.onPackagingListModalClose();
        } else {
          this.showSnackbar({
            text: "Please check all fields requirements",
            color: "red",
            timeout: 2000
          });
        }
      } else {
        const valid = await this.$validator.validateAll("addPackageList");
        if (valid) {
          if (this.inputPackagingListCourierNameSlug != "acn") {
            this.inputPackagingListLastMileCourierSlug = null;
            this.inputPackagingListCourierCode = null;
          }
          const payload = {
            name: this.inputPackagingListName,
            order_id: Number(this.$route.params.id),
            courier_name: this.inputPackagingListCourierName,
            courier_name_slug: this.inputPackagingListCourierNameSlug,
            tracking_code: this.inputPackagingListTrackingCode,
            tracking_code_courier: this.inputPackagingListCourierCode,
            last_mile_courier:
              this.searchLastMile == null
                ? this.searchLastMile
                : this.inputPackagingListLastMileCourierSlug
          };

          if (this.inputCourierItem) {
            payload.courier_name = null;
            payload.courier_name_slug = this.inputPackagingListCourierNew;
          }

          // console.log('payload', payload)
          const response = await PackageService.updatePackageList(
            this.inputPackagingListId,
            payload
          );
          if (response.success) {
            this.getPackageList();
            this.showSnackbar({
              text: "Update Successfully!",
              color: "teal",
              timeout: 1500
            });
          } else {
            this.showSnackbar({
              text: startCase(response.data.message),
              color: "red",
              timeout: 5000
            });
          }
          this.onPackagingListModalClose();
        } else {
          this.showSnackbar({
            text: "Please check all fields requirements",
            color: "red",
            timeout: 2000
          });
        }
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }
  onODSModalOpen() {
    this.getSupplier();
    this.getOriginCountry();
    this.getOriginCities();
    this.getOriginProvinces();
    this.originDestinationSupplierModal.open = true;

    // console.log("onODSModalOpen this.currentOrderDetails", this.currentOrderDetails)
  }
  onODSModalClosed() {
    // this.getOrderDetails()
    this.originDestinationSupplierModal.origin = this.currentOrderDetails.origin;
    this.originDestinationSupplierModal.destination = this.currentOrderDetails.destination;
    this.originDestinationSupplierModal.supplierName = this.currentOrderDetails.supplier_name;
    this.originDestinationSupplierModal.originProvince = this.selectedOriginProvince;
    this.originDestinationSupplierModal.originCity = this.selectedOriginCity;
    this.originDestinationSupplierModal.open = false;
    // console.log('tes', this.originDestinationSupplierModal)
  }

  async onODSModalSave() {
    // supplier
    // supplier_name

    // console.log("save", this.originDestinationSupplierModal.supplierName)
    // this.supplierNameAdditionalInfo = this.originDestinationSupplierModal.supplierName
    // this.getSupplier()
    // console.log("this.supplierIdAdditionalInfo",this.supplierIdAdditionalInfo)

    var vs = await this.getSupplierForAddInfo(
      this.originDestinationSupplierModal.supplierName
    );
    // console.log("vs", vs)
    // this.setSupplierForAddInfo()

    try {
      const valid = await this.$validator.validateAll("editODS");
      if (valid) {
        this.showLoading({ text: "Saving..." });

        const payload = {
          origin_city:
            this.originDestinationSupplierModal.originCity != null
              ? this.originDestinationSupplierModal.originCity.id
              : null,
          origin: this.originDestinationSupplierModal.origin,
          destination: this.originDestinationSupplierModal.destination,
          supplier: vs.id,
          supplier_name: this.originDestinationSupplierModal.supplierName
        };
        // console.log("payload", payload)

        // updateOrderEdit
        var setParams = {};
        var returnResp = await OrdersService.updateOrderEdit(
          payload,
          setParams,
          this.$route.params.id
        );

        this.showSnackbar({
          text: "Saved Successfully!",
          color: "teal",
          timeout: 1500
        });

        this.originDestinationSupplierModal.open = false;
        this.getOrderDetails();
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }
  // Billing Modal Edit Method
  onBillingModalOpen() {
    this.newAddress.newFirstName = this.currentOrderDetails.order_infos.billing.first_name;
    this.newAddress.newLastName = this.currentOrderDetails.order_infos.billing.last_name;
    this.newAddress.newCountry = this.currentOrderDetails.order_infos.billing.country;
    this.newAddress.newProvince = this.currentOrderDetails.order_infos.billing.state;
    this.cities.selected = {
      text: this.currentOrderDetails.order_infos.billing.city,
      value: this.currentOrderDetails.order_infos.billing.city_id
    };
    this.newAddress.newCity = {
      text: this.currentOrderDetails.order_infos.billing.city,
      value: this.currentOrderDetails.order_infos.billing.city_id
    };
    this.newAddress.newSubdistrict = this.currentOrderDetails.order_infos.billing.address_2;
    this.newAddress.newStreetAddress = this.currentOrderDetails.order_infos.billing.address_1;
    this.newAddress.newPostCode = this.currentOrderDetails.order_infos.billing.postcode;
    this.newAddress.newPhone = this.currentOrderDetails.order_infos.billing.phone;
    this.newAddress.newEmail = this.currentOrderDetails.order_infos.billing.email;
    this.newAddress.newCompany = this.currentOrderDetails.order_infos.billing.company;
    this.billingModal.open = true;
    this.getAllContact();
  }
  onBillingModalClosed() {
    this.newAddress.newFirstName = null;
    this.newAddress.newLastName = null;
    this.newAddress.newCountry = null;
    this.newAddress.newProvince = null;
    this.newAddress.newCity.text = null;
    this.newAddress.newCity.value = null;
    this.newAddress.newSubdistrict = null;
    this.newAddress.newStreetAddress = null;
    this.newAddress.newPostCode = null;
    this.newAddress.newPhone = null;
    this.newAddress.newCompany = null;
    this.newAddress.useContact = false;
    this.newAddress.contact.selected = null;
    this.newAddress.contact.keyword = null;
    this.billingModal.open = false;
    this.$validator.errors.clear();
    this.$validator.reset();
  }
  async onEditBillingSave() {
    try {
      const valid = await this.$validator.validateAll("editBilling");
      if (valid) {
        this.showLoading({ text: "Saving..." });

        const payload = {
          // parent_id: this.currentOrderDetails.id,
          order_infos: {
            billing: {
              address_1: this.newAddress.newStreetAddress,
              address_2: this.newAddress.newSubdistrict,
              city: this.newAddress.newCity.text,
              // city_id: this.newAddress.newCity.value,
              city_id: 0,
              company: this.newAddress.newCompany,
              country: this.newAddress.newCountry,
              email: this.newAddress.newEmail,
              first_name: this.newAddress.newFirstName,
              last_name: this.newAddress.newLastName,
              phone: this.newAddress.newPhone,
              postcode: this.newAddress.newPostCode,
              state: this.newAddress.newProvince
            },
            shipping: {
              address_1: this.currentOrderDetails.order_infos.shipping
                .address_1,
              address_2: this.currentOrderDetails.order_infos.shipping
                .address_2,
              city: this.currentOrderDetails.order_infos.shipping.city,
              city_id: this.currentOrderDetails.order_infos.shipping.city_id,
              company: this.currentOrderDetails.order_infos.shipping.company,
              country: this.currentOrderDetails.order_infos.shipping.country,
              first_name: this.currentOrderDetails.order_infos.shipping
                .first_name,
              last_name: this.currentOrderDetails.order_infos.shipping
                .last_name,
              phone: this.currentOrderDetails.order_infos.shipping.phone,
              postcode: this.currentOrderDetails.order_infos.shipping.postcode,
              state: this.currentOrderDetails.order_infos.shipping.state,
              total_cbm: this.currentOrderDetails.order_infos.shipping
                .total_cbm,
              total_weight: this.currentOrderDetails.order_infos.shipping
                .total_weight
            },
            order_number: this.currentOrderDetails.order_infos.order_number,
            platform: this.currentOrderDetails.order_infos.platform,
            store_name: this.currentOrderDetails.order_infos.store_name
          }
        };

        // updateOrderEdit
        var setParams = {};
        var returnResp = await OrdersService.updateOrderEdit(
          payload,
          setParams,
          this.$route.params.id
        );

        this.showSnackbar({
          text: "Saved Successfully!",
          color: "teal",
          timeout: 1500
        });

        this.onBillingModalClosed();
        this.getOrderDetails();
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  // Shipping Modal Edit Method
  onShippingModalOpen() {
    this.newAddress.newFirstName = this.currentOrderDetails.order_infos.shipping.first_name;
    this.newAddress.newLastName = this.currentOrderDetails.order_infos.shipping.last_name;
    this.newAddress.newCountry = this.currentOrderDetails.order_infos.shipping.country;
    this.newAddress.newProvince = this.currentOrderDetails.order_infos.shipping.state;
    this.cities.selected = {
      text: this.currentOrderDetails.order_infos.shipping.city,
      value: this.currentOrderDetails.order_infos.shipping.city_id
    };
    this.newAddress.newCity = {
      text: this.currentOrderDetails.order_infos.shipping.city,
      value: this.currentOrderDetails.order_infos.shipping.city_id
    };
    this.newAddress.newSubdistrict = this.currentOrderDetails.order_infos.shipping.address_2;
    this.newAddress.newStreetAddress = this.currentOrderDetails.order_infos.shipping.address_1;
    this.newAddress.newPostCode = this.currentOrderDetails.order_infos.shipping.postcode;
    this.newAddress.newPhone = this.currentOrderDetails.order_infos.shipping.phone;
    this.newAddress.newCompany = this.currentOrderDetails.order_infos.shipping.company;
    this.shippingModal.open = true;
    this.getAllContact();
  }
  onShippingModalClosed() {
    this.newAddress.newFirstName = null;
    this.newAddress.newLastName = null;
    this.newAddress.newCountry = null;
    this.newAddress.newProvince = null;
    this.newAddress.newCity.text = null;
    this.newAddress.newCity.value = null;
    this.newAddress.newSubdistrict = null;
    this.newAddress.newStreetAddress = null;
    this.newAddress.newPostCode = null;
    this.newAddress.newPhone = null;
    this.newAddress.newCompany = null;
    this.newAddress.useContact = false;
    this.newAddress.contact.selected = null;
    this.newAddress.contact.keyword = null;
    this.shippingModal.open = false;
    this.$validator.errors.clear();
    this.$validator.reset();
  }
  async onEditShippingSave() {
    try {
      const valid = await this.$validator.validateAll("editShipping");
      if (valid) {
        this.showLoading({ text: "Saving..." });

        const payload = {
          // parent_id: this.currentOrderDetails.id,
          order_infos: {
            billing: {
              address_1: this.currentOrderDetails.order_infos.billing.address_1,
              address_2: this.currentOrderDetails.order_infos.billing.address_2,
              city: this.currentOrderDetails.order_infos.billing.city,
              city_id: this.currentOrderDetails.order_infos.billing.city_id,
              company: this.currentOrderDetails.order_infos.billing.company,
              country: this.currentOrderDetails.order_infos.billing.country,
              email: this.currentOrderDetails.order_infos.billing.email,
              first_name: this.currentOrderDetails.order_infos.billing
                .first_name,
              last_name: this.currentOrderDetails.order_infos.billing.last_name,
              phone: this.currentOrderDetails.order_infos.billing.phone,
              postcode: this.currentOrderDetails.order_infos.billing.postcode,
              state: this.currentOrderDetails.order_infos.billing.state
            },
            shipping: {
              address_1: this.newAddress.newStreetAddress,
              address_2: this.newAddress.newSubdistrict,
              city: this.newAddress.newCity.text,
              // city_id: this.newAddress.newCity.value,
              city_id: 0,
              company: this.newAddress.newCompany,
              country: this.newAddress.newCountry,
              first_name: this.newAddress.newFirstName,
              last_name: this.newAddress.newLastName,
              phone: this.newAddress.newPhone,
              postcode: this.newAddress.newPostCode,
              state: this.newAddress.newProvince,
              total_cbm: this.currentOrderDetails.order_infos.shipping
                .total_cbm,
              total_weight: this.currentOrderDetails.order_infos.shipping
                .total_weight
            },
            order_number: this.currentOrderDetails.order_infos.order_number,
            platform: this.currentOrderDetails.order_infos.platform,
            store_name: this.currentOrderDetails.order_infos.store_name
          }
        };

        // updateOrderEdit
        var setParams = {};
        var returnResp = await OrdersService.updateOrderEdit(
          payload,
          setParams,
          this.$route.params.id
        );
        // const response = await OrdersService.updateOrder(payload)
        this.showSnackbar({
          text: "Saved Successfully!",
          color: "teal",
          timeout: 1500
        });

        this.onShippingModalClosed();
        this.getOrderDetails();
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  // Agent
  modalAddAgent: boolean = false;
  async onAddAgentModalOpen() {
    this.pending_commission = this.currentOrderDetails.pending_commision;
    this.modalAddAgent = true;
  }

  onAddAgentModalClosed() {
    this.modalAddAgent = false;
  }

  async getAgents() {
    try {
      // if(typeof this.currentOrderDetails.agent_name !== 'undefined') {
      //  this.agents.selected = this.currentOrderDetails.agent_name
      // }

      this.agents.loading = true;

      var filter = "filter[name][like]";
      const opts: any = {
        params: {
          // include: 'contacts' + ',' + 'cart',
          "page[num]": 1,
          "page[limit]": 25,
          sort: "-created_at",
          "filter[name][like]": "",
          "filter[is_agent][is]": 1
        }
      };

      if (!isEmpty(this.agents.keyword)) {
        opts.params[filter] = this.agents.keyword;
      } else {
        delete opts.params[filter];
      }

      const response = await Users.getUsers(opts);
      const responseData = response.data.data;
      this.agents.data = [];

      for (const agentData of responseData) {
        const agents = {
          ...agentData.attributes
        };
        this.agents.data.push(agents);
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.agents.loading = false;
      this.closeLoading();
    }
  }

  async onAddAgentSave() {
    try {
      this.showLoading({ text: "Saving..." });
      const payload = {
        ordered_by_user_id: this.agents.selected.id,
        pending_commision: this.pending_commission
      };

      // Add or Update Agent
      var setParams = {};
      var returnResp = await OrdersService.updateOrderEdit(
        payload,
        setParams,
        this.$route.params.id
      );

      this.showSnackbar({
        text: "Saved Successfully!",
        color: "teal",
        timeout: 1500
      });

      this.onAddAgentModalClosed();
      this.getOrderDetails();
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  // sinis
  onCustomChargeModalOpen() {
    // console.log("this.currentOrderDetails.total_custom_charges",this.currentOrderDetails.total_custom_charges)
    // from calculation baseline
    // theValueBm

    // from table order
    // newValueBm
    this.modalCustomCharges.open = true;

    if (this.currentOrderDetails.total_custom_charges !== null) {
      // jika hasil ini null maka set aja jadi 0

      // this.currentOrderDetails.total_duty NULL
      if (this.currentOrderDetails.total_duty === null) {
        this.modalCustomCharges.newValueBm = 0;
      } else {
        this.modalCustomCharges.newValueBm = this.currentOrderDetails.total_duty.toFixed(
          2
        );
      }

      // this.currentOrderDetails.total_wht NULL
      if (this.currentOrderDetails.total_wht === null) {
        this.modalCustomCharges.newValueWHT = 0;
      } else {
        this.modalCustomCharges.newValueWHT = this.currentOrderDetails.total_wht.toFixed(
          2
        );
      }

      // this.currentOrderDetails.total_vat NULL
      if (this.currentOrderDetails.total_vat === null) {
        this.modalCustomCharges.newValueVat = 0;
      } else {
        this.modalCustomCharges.newValueVat = this.currentOrderDetails.total_vat.toFixed(
          2
        );
      }

      this.modalCustomCharges.theValueBm = this.responseCalculation.total_duty.toFixed(
        2
      );
      this.modalCustomCharges.theValueWHT = this.responseCalculation.total_wht.toFixed(
        2
      );
      this.modalCustomCharges.theValueVat = this.responseCalculation.total_vat.toFixed(
        2
      );
    } else {
      this.modalCustomCharges.newValueBm = this.responseCalculation.total_duty.toFixed(
        2
      );
      this.modalCustomCharges.newValueWHT = this.responseCalculation.total_wht.toFixed(
        2
      );
      this.modalCustomCharges.newValueVat = this.responseCalculation.total_vat.toFixed(
        2
      );

      this.modalCustomCharges.theValueBm = this.responseCalculation.total_duty.toFixed(
        2
      );
      this.modalCustomCharges.theValueWHT = this.responseCalculation.total_wht.toFixed(
        2
      );
      this.modalCustomCharges.theValueVat = this.responseCalculation.total_vat.toFixed(
        2
      );
    }

    // console.log('this.currentOrderDetails.total_custom_charges',this.currentOrderDetails.total_custom_charges)
    // console.log("this.responseCalculationRevised.total_custom_charge",this.responseCalculationRevised.total_custom_charge)
    // console.log("this.responseCalculation.total_custom_charge",this.responseCalculation.total_custom_charge)

    // template(v-if="currentOrderDetails.total_custom_charges !== null")
    //   responseCalculationRevised.total_custom_charge
    // template(v-else)
    //   {{ formatMoney(responseCalculation.total_custom_charge, currentOrderDetails.currency) }}
  }
  onCustomChargeModalClosed() {
    // this.modalCustomCharges.newLogisticCost = null
    // this.modalCustomCharges.newLogisticPerUnit = null
    // this.modalCustomCharges.customCharges = this.custom_charges

    this.modalCustomCharges.open = false;
    this.$validator.errors.clear();
    this.$validator.reset();
  }
  async onEditCustomChargeSave() {
    const valid = await this.$validator.validateAll(
      "editValidationCustomCharges"
    );
    if (valid) {
      this.showLoading({ text: "Saving..." });
      var custom_logistic_charge = [];
      var shipmentMethod = this.currentOrderDetails.shipment_method;
      // console.log('this.custom_charges',this.custom_charges)
      forEach(this.custom_charges, charge => {
        var set_charge = {
          type: "custom-optional",
          group: charge.name,
          name: charge.name,
          price: charge.price,
          // vat dihilangkan dulu
          // "vat": charge.vat
          vat: 0
        };
        custom_logistic_charge.push(set_charge);
      });

      // // set shipment from if from record db is null
      if (this.currentOrderDetails.shipment_from !== null) {
        this.currentOrderDetails.shipment_from = Number(
          this.currentOrderDetails.shipment_from
        );
      } else {
        this.currentOrderDetails.shipment_from = 44;
      }
      // set shipment to if from record db is null
      if (this.currentOrderDetails.shipment_to !== null) {
        this.currentOrderDetails.shipment_to = Number(
          this.currentOrderDetails.shipment_to
        );
      } else {
        this.currentOrderDetails.shipment_to = 99;
      }

      // set currency_order
      var setCountryById = await CountryService.getCountryById(
        this.currentOrderDetails.shipment_to
      );
      var setCurrencyOrder = setCountryById.data.data.attributes.currency;

      // set currency_origin
      var setCountryById = await CountryService.getCountryById(
        this.currentOrderDetails.shipment_from
      );
      var setCurrencyOrigin = setCountryById.data.data.attributes.currency;

      // set for calculation
      var payload_edit_custom_charges: any = {
        type: "order",
        ship_from: this.currentOrderDetails.shipment_from,
        ship_to: this.currentOrderDetails.shipment_to,
        shipping_method: shipmentMethod,
        logistic_per_unit: this.currentOrderDetails.logistic_per_unit,
        currency_order: setCurrencyOrder,
        currency_origin: setCurrencyOrigin,
        weight_measurement: "kg",
        order_type: this.currentOrderDetails.order_type,
        products: [],
        // dont use this when just edit item products,
        // only use this, when edit logistic details
        revision: {
          // "total_weight": this.currentOrderDetails.total_weight,
          // "total_cbm": this.currentOrderDetails.total_cbm,
          custom_charges: [
            {
              key: "total_duty",
              value: parseInt(this.modalCustomCharges.newValueBm)
            },
            {
              key: "total_wht",
              value: parseInt(this.modalCustomCharges.newValueWHT)
            },
            {
              key: "total_vat",
              value: parseInt(this.modalCustomCharges.newValueVat)
            }
          ]
        },
        custom_logistic_charges: custom_logistic_charge
      };

      // set revision total weight if revised_total_weight is not null
      if (this.currentOrderDetails.revised_total_weight !== null) {
        payload_edit_custom_charges.revision.total_weight = this.currentOrderDetails.revised_total_weight;
      } else {
        payload_edit_custom_charges.revision.total_weight = this.currentOrderDetails.total_weight;
      }

      if (this.currentOrderDetails.revised_total_cbm !== null) {
        payload_edit_custom_charges.revision.total_cbm = this.currentOrderDetails.revised_total_cbm;
      } else {
        payload_edit_custom_charges.revision.total_cbm = this.currentOrderDetails.total_cbm;
      }

      forEach(this.currentOrderDetails.details, item => {
        // item.attributes.item_rule.attributes.taxes
        if (isEmpty(item.attributes.item_rule.attributes.taxes)) {
          item.attributes.item_rule.attributes.taxes = [
            { label: "BM", value: 0 },
            { label: "PPN", value: 0 },
            { label: "PPH", value: 0 }
          ];
        }

        var arrEditProductItem: any = {};
        if (item.attributes.variants[0].hasOwnProperty("origin")) {
          var setQuantity = 0;
          if (item.attributes.revised_quantity !== null) {
            setQuantity = item.attributes.revised_quantity;
          } else {
            setQuantity = item.attributes.quantity;
          }

          arrEditProductItem = {
            unit_name: item.attributes.unit_name,
            id: item.attributes.product_id,
            variants: []
          };
          var objVarFirsParent = {
            price_edit: item.attributes.cost,
            price_custom_edit:
              Number(item.attributes.original_price) *
              Number(this.user_conversion),
            quantity: setQuantity,
            attributes: item.attributes.variants,
            // unit_name: item.attributes.unit_name,
            // diisi dari this.editProduct.selectedProduct
            settings: [
              {
                name: item.attributes.item_rule.attributes.taxes[0].label,
                value: item.attributes.item_rule.attributes.taxes[0].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[2].label,
                value: item.attributes.item_rule.attributes.taxes[2].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[1].label,
                value: item.attributes.item_rule.attributes.taxes[1].value
              },
              {
                name: "setting_weight",
                value: item.attributes.item_rule.attributes.weight
              },
              {
                name: "setting_volume_cbm",
                value: item.attributes.item_rule.attributes.qty_cbm
              }
            ]
          };
          arrEditProductItem.variants.push(objVarFirsParent);
        } else {
          // yogie
          var setQuantity = 0;
          if (item.attributes.revised_quantity !== null) {
            setQuantity = item.attributes.revised_quantity;
          } else {
            setQuantity = item.attributes.quantity;
          }

          //yogiesetiar
          arrEditProductItem = {
            unit_name: item.attributes.unit_name,
            price_edit: item.attributes.cost,
            price_custom_edit:
              Number(item.attributes.original_price) *
              Number(this.user_conversion),
            id: item.attributes.product_id,
            type: "single",
            quantity: setQuantity,
            settings: [
              {
                name: item.attributes.item_rule.attributes.taxes[0].label,
                value: item.attributes.item_rule.attributes.taxes[0].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[2].label,
                value: item.attributes.item_rule.attributes.taxes[2].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[1].label,
                value: item.attributes.item_rule.attributes.taxes[1].value
              },
              {
                name: "setting_weight",
                value: item.attributes.item_rule.attributes.weight
              },
              {
                name: "setting_volume_cbm",
                value: item.attributes.item_rule.attributes.qty_cbm
              }
            ]
          };
        }
        payload_edit_custom_charges.products.push(arrEditProductItem);
      });

      // ini ganti
      // console.log("payload_edit_custom_charges", payload_edit_custom_charges);
      var responseCustomChargeCalculation = await Calculate.calculateOrder(
        payload_edit_custom_charges
      );

      // console.log('responseCustomChargeCalculation',responseCustomChargeCalculation)
      // sinis
      const new_payload: any = {};

      // new_payload.cost = this.currentOrderDetails.cost
      new_payload.cost =
        responseCustomChargeCalculation.data.data.total_goods_value;
      new_payload.parent_id = this.currentOrderDetails.id;
      // new_payload.logistic_cost = this.currentOrderDetails.logistic_cost
      new_payload.logistic_cost =
        responseCustomChargeCalculation.data.data.total_logistic_all_in;
      new_payload.total_weight =
        responseCustomChargeCalculation.data.data.total_weight;
      new_payload.total_cbm =
        responseCustomChargeCalculation.data.data.total_cbm;
      new_payload.total_duty =
        responseCustomChargeCalculation.data.data.total_duty;
      new_payload.total_wht =
        responseCustomChargeCalculation.data.data.total_wht;
      new_payload.total_vat =
        responseCustomChargeCalculation.data.data.total_vat;
      new_payload.total_custom_charges =
        responseCustomChargeCalculation.data.data.total_custom_charge;
      new_payload.total_goods_value =
        responseCustomChargeCalculation.data.data.total_goods_value;
      // new_payload.total_custom_logistic_charges = responseCustomChargeCalculation.data.data.total_custom_logistic_charges
      new_payload.total_logistic_charges =
        responseCustomChargeCalculation.data.data.total_custom_logistic_charges;
      new_payload.grand_total =
        responseCustomChargeCalculation.data.data.grand_total;
      new_payload.items = [];
      new_payload.custom_charges = {
        amount: 0,
        vat_percentage: 0,
        details: []
      };
      new_payload.custom_charges.details = this.custom_charges;

      var amount_charge_detail = 0;
      forEach(this.custom_charges, item_charges => {
        amount_charge_detail += item_charges.grand_total;
      });
      new_payload.custom_charges.amount = amount_charge_detail;
      // custom_charges: {
      //  amount: 0,
      //  vat_percentage: 0,
      //  details: []
      // }

      var index = 0;
      forEach(this.currentOrderDetails.details, detail => {
        // let ratio_current_weight_per_item = responseCustomChargeCalculation.data.data.products[index].ratio_weight

        // if (isNaN(ratio_current_weight_per_item)) {
        //  ratio_current_weight_per_item = 0
        // }

        //has to check responseCustomChargeCalculation has variant or not
        if (
          responseCustomChargeCalculation.data.data.products[
            index
          ].hasOwnProperty("variants")
        ) {
          var price_per_item =
            responseCustomChargeCalculation.data.data.products[index]
              .variants[0].price_per_item;
        } else {
          var price_per_item =
            responseCustomChargeCalculation.data.data.products[index]
              .price_per_item;
        }
        const objItem = {
          weight: detail.attributes.item_rule.attributes.weight,
          product_id: detail.attributes.product_id,
          detail_id: detail.attributes.id,
          product_name:
            responseCustomChargeCalculation.data.data.products[index].name,
          image_url: detail.attributes.image_url,
          original_name: detail.attributes.original_name,
          variants: detail.attributes.variants,
          currency: detail.attributes.currency,
          quantity:
            responseCustomChargeCalculation.data.data.products[index]
              .total_quantity,
          cost: price_per_item,
          status_id: detail.attributes.item_status[0].attributes.id,
          sku_status: detail.attributes.item_status[0].attributes,
          rule: detail.attributes.item_rule.attributes
        };
        new_payload.items.push(objItem);
        index++;
      });

      const setNewPayload = {
        // old
        // total_custom_charges: Number(this.modalCustomCharges.newValueBm)+Number(this.modalCustomCharges.newValueVat)+
        //  Number(this.modalCustomCharges.newValueWHT),
        // total_duty: Number(this.modalCustomCharges.newValueBm),
        // total_vat: Number(this.modalCustomCharges.newValueVat),
        // total_wht: Number(this.modalCustomCharges.newValueWHT),
        total_custom_charges:
          responseCustomChargeCalculation.data.data.total_custom_charge,
        total_duty: responseCustomChargeCalculation.data.data.total_duty,
        total_vat: responseCustomChargeCalculation.data.data.total_vat,
        total_wht: responseCustomChargeCalculation.data.data.total_wht,
        logistic_cost:
          responseCustomChargeCalculation.data.data.total_logistic_all_in,
        total_goods_value:
          responseCustomChargeCalculation.data.data.total_goods_value,
        total_logistic_charges:
          responseCustomChargeCalculation.data.data.total_logistic_charge,
        grand_total: responseCustomChargeCalculation.data.data.grand_total
      };
      // console.log('new_payload',new_payload)
      // console.log("setNewPayload", setNewPayload);

      // yogie
      //==========================
      var setParams = {};
      var returnResp = await OrdersService.updateOrderEdit(
        setNewPayload,
        setParams,
        this.$route.params.id
      );

      this.showSnackbar({
        text: "Saved Successfully!",
        color: "teal",
        timeout: 1500
      });
      this.closeLoading();
      this.onCustomChargeModalClosed();
      this.getOrderDetails();
    } else {
      this.showSnackbar({
        text: "Please check all fields requirements",
        color: "red",
        timeout: 2000
      });
    }
  }

  // sinis
  // Logistics Modal Edit Methods
  onLogisticModalOpen() {
    this.logisticModal.newLogisticCost = this.currentOrderDetails.total_logistic_charges;

    this.logisticModal.newLogisticPerUnit = this.currentOrderDetails.logistic_per_unit;

    this.logisticModal.newTotalLogisticCost = this.currentOrderDetails.total_logistic_charges;
    this.logisticModal.logistic_charge_original = this.currentOrderDetails.total_logistic_charges;
    this.logisticModal.currentShipmentMethod = this.currentOrderDetails.shipment_method;
    // this.logisticModal.currentOrderType = this.currentOrderDetails.order_type

    // set custom charge detail from response every time open edit loagistic modal
    var id_customCharge = this.currentOrder.data.relationships.customCharge.id;
    var set_custom_charge = this.currentOrder.included.customCharge[
      id_customCharge
    ].attributes.custom_charge_detail;

    this.logisticModal.customCharges = [];
    var originalCustCharge = [];
    forEach(set_custom_charge, charge => {
      var write_custom_charge: any = {
        charge_detail_id: charge.attributes.id ? charge.attributes.id : null,
        grand_total: charge.attributes.grand_total,
        group: charge.attributes.group,
        is_vat: charge.attributes.is_vat,
        name: charge.attributes.name,
        sub_total: charge.attributes.sub_total,
        vat: charge.attributes.vat,
        vat_value: charge.attributes.vat_value
      };
      // this.logisticModal.customCharges.push(write_custom_charge)
      originalCustCharge.push(write_custom_charge);
    });

    var sortLogisticModalCustomCharges = sortBy(
      this.responseCalculationRevised.custom_logistic_charges,
      [
        function(o) {
          return o.name;
        }
      ]
    );
    // jumlah ikut dr db
    // nilai dalam dari calculation
    // console.log("sortLogisticModalCustomCharges", sortLogisticModalCustomCharges)

    var logisticCustomCharges = [];

    if (
      this.currentOrderDetails.shipment_method === "air" ||
      this.currentOrderDetails.shipment_method === "air_dtd"
    ) {
      if (this.currentOrderDetails.revised_total_weight !== null) {
        logisticCustomCharges = this.responseCalculationRevised
          .custom_logistic_charges;
      } else {
        logisticCustomCharges = this.responseCalculation
          .custom_logistic_charges;
      }
    } else {
      logisticCustomCharges = this.payloadCalRev.custom_logistic_charges;
    }

    if (
      this.currentOrderDetails.shipment_method === "ocean" ||
      this.currentOrderDetails.shipment_method === "ocean_dtd"
    ) {
      if (this.currentOrderDetails.revised_total_cbm !== null) {
        logisticCustomCharges = this.responseCalculationRevised
          .custom_logistic_charges;
      } else {
        logisticCustomCharges = this.responseCalculation
          .custom_logistic_charges;
      }
    } else {
      logisticCustomCharges = this.payloadCalRev.custom_logistic_charges;
    }

    var sortOriginalCustCharge = sortBy(originalCustCharge, [
      function(o) {
        return o.group;
      }
    ]);
    // console.log("sortOriginalCustCharge", sortOriginalCustCharge)

    var newCharge = [];
    var sortLogisticCustomCharges = sortBy(logisticCustomCharges, [
      function(o) {
        return o.name;
      }
    ]);
    forEach(sortOriginalCustCharge, item_charge => {
      var aaa = sortLogisticCustomCharges.filter(
        x => x.name === item_charge.name
      ).length;
      if (aaa > 0) {
        var temp = sortLogisticCustomCharges.filter(
          z => z.name === item_charge.name
        );
        console.log("temp", temp);
        for (let i = 0; i < temp.length; i++) {
          var item = {
            ...item_charge,
            sub_total: Number(temp[i].total).toFixed(2),
            grand_total: Number(temp[i].total).toFixed(2),
            total: Number(temp[i].total).toFixed(2)
          };
          if (temp[i].total === undefined) {
            var item = {
              ...item_charge,
              sub_total: Number(temp[i].price).toFixed(2),
              grand_total: Number(temp[i].price).toFixed(2),
              total: Number(temp[i].price).toFixed(2)
            };
          }
          newCharge.push(item);
        }
      }
    });

    this.customChargesAfterSelection = newCharge;
    this.logisticModal.customCharges = newCharge;
    this.logisticModal.open = true;
  }

  onLogisticModalClosed() {
    // this.logisticModal.newLogisticCost = null
    this.logisticModal.newLogisticPerUnit = null;
    this.totalRequiredLogistic = 0;
    this.totalCustomLogistic = 0;
    this.totalRequiredLogisticRevised = 0;
    this.totalCustomLogisticRevised = 0;

    var id_customCharge = this.currentOrder.data.relationships.customCharge.id;
    // console.log('id_customCharge',id_customCharge)

    // must set from calculation
    // this.logisticModal.customCharges = this.currentOrder.included.customCharge[id_customCharge].attributes.custom_charge_detail
    if (
      this.currentOrderDetails.shipment_method === "air" ||
      this.currentOrderDetails.shipment_method === "air_dtd"
    ) {
      if (this.currentOrderDetails.revised_total_weight !== null) {
        this.logisticModal.customCharges = this.responseCalculationRevised.custom_logistic_charges;
      } else {
        this.logisticModal.customCharges = this.responseCalculation.custom_logistic_charges;
      }
    } else {
      if (this.currentOrderDetails.revised_total_cbm !== null) {
        this.logisticModal.customCharges = this.responseCalculationRevised.custom_logistic_charges;
      } else {
        this.logisticModal.customCharges = this.responseCalculation.custom_logistic_charges;
      }
    }

    this.logisticModal.open = false;
    this.$validator.errors.clear();
    this.$validator.reset();
  }
  onAddNewCustomCharge() {
    if (this.logisticModal.customCharges === null) {
      this.logisticModal.customCharges = [];
    }
    this.logisticModal.customCharges.push({
      group: "",
      name: "",
      sub_total: 0,
      vat: 0,
      vat_value: 0,
      grand_total: 0,
      is_vat: 0
    });
  }
  //yogie set
  async onCustomChargeItemDeleted(index) {
    // yogie set
    var charge_detail_id = this.logisticModal.customCharges[index]
      .charge_detail_id;
    // console.log('charge_detail_id',typeof(charge_detail_id) === 'undefined')
    if (typeof charge_detail_id === "undefined") {
      this.showSnackbar({
        text: "It Cant Be Removed!",
        color: "red",
        timeout: 1500
      });
    } else {
      var resp = await OrdersService.deleteCustomizeChargeById(
        charge_detail_id
      );
      this.logisticModal.customCharges.splice(index, 1);
    }
  }
  async onEditLogisticSave() {
    const valid = await this.$validator.validateAll("editLogistics");
    if (valid) {
      this.showLoading({ text: "Saving..." });
      var custom_logistic_charge = [];
      var shipmentMethod = this.currentOrderDetails.shipment_method;
      var set_insurance_value = 0;
      var set_freight_value = 0;

      forEach(this.logisticModal.customCharges, charge => {
        if (charge.hasOwnProperty("charge_detail_id")) {
          var set_charge = {
            type: "custom-optional",
            group: charge.name,
            name: charge.name,
            price: Number(charge.total),
            vat: 0
          };
          custom_logistic_charge.push(set_charge);
        }
      });

      // // set shipment from if from record db is null
      if (this.currentOrderDetails.shipment_from !== null) {
        this.currentOrderDetails.shipment_from = Number(
          this.currentOrderDetails.shipment_from
        );
      } else {
        this.currentOrderDetails.shipment_from = 44;
      }
      // set shipment to if from record db is null
      if (this.currentOrderDetails.shipment_to !== null) {
        this.currentOrderDetails.shipment_to = Number(
          this.currentOrderDetails.shipment_to
        );
      } else {
        this.currentOrderDetails.shipment_to = 99;
      }

      // set currency_order
      var setCountryById = await CountryService.getCountryById(
        this.currentOrderDetails.shipment_to
      );
      var setCurrencyOrder = setCountryById.data.data.attributes.currency;

      // set currency_origin
      var setCountryById = await CountryService.getCountryById(
        this.currentOrderDetails.shipment_from
      );
      var setCurrencyOrigin = setCountryById.data.data.attributes.currency;

      // set for calculation
      var payload_edit_logistic: any = {
        type: "order",
        ship_from: this.currentOrderDetails.shipment_from,
        ship_to: this.currentOrderDetails.shipment_to,
        shipping_method: this.logisticModal.currentShipmentMethod,
        logistic_per_unit: Number(this.logisticModal.newLogisticPerUnit),
        currency_order: setCurrencyOrder,
        currency_origin: setCurrencyOrigin,
        weight_measurement: "kg",
        products: [],
        revision: {},
        custom_logistic_charges: custom_logistic_charge
      };

      forEach(this.currentOrderDetails.details, item => {
        // item.attributes.item_rule.attributes.taxes
        if (isEmpty(item.attributes.item_rule.attributes.taxes)) {
          item.attributes.item_rule.attributes.taxes = [
            { label: "BM", value: 0 },
            { label: "PPN", value: 0 },
            { label: "PPH", value: 0 }
          ];
        }

        var arrEditProductItem: any = {};
        if (item.attributes.variants[0].hasOwnProperty("origin")) {
          arrEditProductItem = {
            unit_name: item.attributes.unit_name,
            id: item.attributes.product_id,
            variants: []
          };
          var objVarFirsParent = {
            price_edit: item.attributes.cost,
            price_custom_edit:
              Number(item.attributes.original_price) *
              Number(this.user_conversion),
            quantity: item.attributes.quantity,
            attributes: item.attributes.variants,
            settings: [
              {
                name: item.attributes.item_rule.attributes.taxes[0].label,
                value: item.attributes.item_rule.attributes.taxes[0].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[2].label,
                value: item.attributes.item_rule.attributes.taxes[2].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[1].label,
                value: item.attributes.item_rule.attributes.taxes[1].value
              },
              {
                name: "setting_weight",
                value: item.attributes.item_rule.attributes.weight
              },
              {
                name: "setting_volume_cbm",
                value: item.attributes.item_rule.attributes.qty_cbm
              }
            ]
          };
          arrEditProductItem.variants.push(objVarFirsParent);
        } else {
          arrEditProductItem = {
            price_edit: item.attributes.cost,
            price_custom_edit:
              Number(item.attributes.original_price) *
              Number(this.user_conversion),
            id: item.attributes.product_id,
            type: "single",
            quantity: item.attributes.quantity,
            unit_name: item.attributes.unit_name,
            settings: [
              {
                name: item.attributes.item_rule.attributes.taxes[0].label,
                value: item.attributes.item_rule.attributes.taxes[0].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[2].label,
                value: item.attributes.item_rule.attributes.taxes[2].value
              },
              {
                name: item.attributes.item_rule.attributes.taxes[1].label,
                value: item.attributes.item_rule.attributes.taxes[1].value
              },
              {
                name: "setting_weight",
                value: item.attributes.item_rule.attributes.weight
              },
              {
                name: "setting_volume_cbm",
                value: item.attributes.item_rule.attributes.qty_cbm
              }
            ]
          };
        }

        payload_edit_logistic.products.push(arrEditProductItem);
      });
      var responseLogisticCalculation = await Calculate.calculateOrder(
        payload_edit_logistic
      );
      const new_payload: any = {};

      new_payload.logistic_cost =
        responseLogisticCalculation.data.data.total_logistic_all_in;
      new_payload.total_logistic_charges =
        responseLogisticCalculation.data.data.total_logistic_charge;
      new_payload.total_goods_value =
        responseLogisticCalculation.data.data.total_goods_value;
      new_payload.grand_total =
        responseLogisticCalculation.data.data.grand_total;
      // new_payload.items = []
      new_payload.custom_charges = {
        amount: 0,
        // vat_percentage: 0,
        details: []
      };
      // yogie set2
      var set_detail_custom_logistic_charge = [];
      forEach(this.logisticModal.customCharges, charge => {
        var set_charge = {};
        if (charge.hasOwnProperty("charge_detail_id")) {
          set_charge = {
            charge_detail_id: charge.charge_detail_id,
            grand_total: Number(charge.total),
            group: charge.name,
            // is_vat: Number(charge.vat),
            name: charge.name,
            sub_total: Number(charge.total),
            vat: Number(charge.vat),
            vat_value: Number(charge.vat)
          };
        } else {
          set_charge = {
            grand_total: Number(charge.total),
            group: charge.name,
            // is_vat: Number(charge.vat),
            name: charge.name,
            sub_total: Number(charge.total),
            vat: Number(charge.vat),
            vat_value: Number(charge.vat)
          };
        }
        set_detail_custom_logistic_charge.push(set_charge);
      });

      var removeWeightCharge = set_detail_custom_logistic_charge.filter(
        x => x.name !== "Estimate Weight Charge (KG)"
      );
      var removeCbmCharge = removeWeightCharge.filter(
        x => x.name !== "Estimate Volume Charge (CBM)"
      );
      var removeHandlingCharge = removeCbmCharge.filter(
        x => x.name !== "handling charge"
      );

      set_detail_custom_logistic_charge = removeHandlingCharge;

      new_payload.custom_charges.details = set_detail_custom_logistic_charge;
      forEach(new_payload.custom_charges.details, item_charge => {
        if (item_charge.hasOwnProperty("charge_detail_id")) {
          item_charge.charge_detail_id = item_charge.charge_detail_id;
        } else {
          item_charge.charge_detail_id = null;
        }
      });

      var amount_charge_detail = 0;
      forEach(this.logisticModal.customCharges, item_charges => {
        amount_charge_detail += Number(item_charges.total);
      });
      new_payload.custom_charges.amount = amount_charge_detail;
      new_payload.shipment_method = this.logisticModal.currentShipmentMethod;
      new_payload.logistic_per_unit = Number(
        this.logisticModal.newLogisticPerUnit
      );
      var setParams = {};
      var returnResp = await OrdersService.updateOrderEdit(
        new_payload,
        setParams,
        this.$route.params.id
      );
      this.showSnackbar({
        text: "Saved Successfully!",
        color: "teal",
        timeout: 1500
      });
      this.onLogisticModalClosed();
      this.getOrderDetails();
      this.closeLoading();
    } else {
      this.showSnackbar({
        text: "Please check all fields requirements",
        color: "red",
        timeout: 2000
      });
    }
  }

  // Edit Weight or CBM Methods
  onChangeWeightModalOpen(currentlyEditing) {
    // yogie set
    if (currentlyEditing === "cbm") {
      if (this.currentOrderDetails.revised_total_cbm !== null) {
        this.editWeightOrCBM.newAmount = this.currentOrderDetails.revised_total_cbm;
      } else {
        this.editWeightOrCBM.newAmount = this.currentOrderDetails.total_cbm;
      }
      // if (this.currentOrderDetails.revised_cbm > 0) {
      //  this.editWeightOrCBM.newAmount = this.currentOrderDetails.revised_cbm
      // } else {
      //  this.editWeightOrCBM.newAmount = this.currentOrderDetails.total_cbm
      // }
    } else {
      if (this.currentOrderDetails.revised_total_weight !== null) {
        this.editWeightOrCBM.newAmount = this.currentOrderDetails.revised_total_weight.toFixed(
          5
        );
      } else {
        this.editWeightOrCBM.newAmount = this.currentOrderDetails.total_weight.toFixed(
          5
        );
      }
      // if (this.currentOrderDetails.revised_weight > 0) {
      //  this.editWeightOrCBM.newAmount = this.currentOrderDetails.revised_weight.toFixed(5)
      // } else {
      //  this.editWeightOrCBM.newAmount = this.currentOrderDetails.total_weight.toFixed(5)
      //  // console.log('this.editWeightOrCBM.newAmount ', this.editWeightOrCBM.newAmount)
      // }
    }

    this.editWeightOrCBM.currentlyEditing = currentlyEditing;
    this.editWeightOrCBM.open = true;
  }

  onChangeWeightModalClosed() {
    this.editWeightOrCBM.newAmount = null;
    this.editWeightOrCBM.currentlyEditing = null;
    this.editWeightOrCBM.open = false;
    this.$validator.errors.clear();
    this.$validator.reset();
  }

  async revisedCbm(newValCbm) {
    // console.log('newValCbm',newValCbm)
    // // console.log('this',this)
    // var total_cbm_per_varian = 0
    // var total_logistic_cost_pervarian = 0

    // for (const detail of this.currentOrderDetails.details) {

    //  if (detail.attributes.item_rule === null) {
    //    detail.attributes.item_rule = {
    //      type: "",
    //      id: "",
    //      attributes: {
    //        id: 0,
    //        order_item_id: 0,
    //        qty_cbm: 0,
    //        weight: 0,
    //        weight_unit: "",
    //        total_weight: 0,
    //        taxes: [
    //          {
    //            label: "BM",
    //            value: 0
    //          },
    //          {
    //            label: "PPN",
    //            value: 0
    //          },
    //          {
    //            label: "PPH",
    //            value: 0
    //          }
    //        ],
    //        total_tax: 0,
    //        hscodes: ""
    //      }
    //    }
    //  }
    // }

    var custom_logistic_charge = [];
    forEach(this.custom_charges, charge => {
      // console.log('charge',charge)
      var set_charge = {
        type: "custom-optional",
        group: charge.name,
        name: charge.name,
        price: charge.price,
        // vat dihilangkan dulu
        // "vat": charge.vat
        vat: 0
      };
      custom_logistic_charge.push(set_charge);
    });

    // // set shipment from if from record db is null
    if (this.currentOrderDetails.shipment_from !== null) {
      this.currentOrderDetails.shipment_from = Number(
        this.currentOrderDetails.shipment_from
      );
    } else {
      this.currentOrderDetails.shipment_from = 44;
    }
    // set shipment to if from record db is null
    if (this.currentOrderDetails.shipment_to !== null) {
      this.currentOrderDetails.shipment_to = Number(
        this.currentOrderDetails.shipment_to
      );
    } else {
      this.currentOrderDetails.shipment_to = 99;
    }

    // set currency_order
    var setCountryById = await CountryService.getCountryById(
      this.currentOrderDetails.shipment_to
    );
    var setCurrencyOrder = setCountryById.data.data.attributes.currency;

    // set currency_origin
    var setCountryById = await CountryService.getCountryById(
      this.currentOrderDetails.shipment_from
    );
    var setCurrencyOrigin = setCountryById.data.data.attributes.currency;

    // // set for calculation
    var payload_edit_weight: any = {
      type: "order",
      ship_from: this.currentOrderDetails.shipment_from,
      ship_to: this.currentOrderDetails.shipment_to,
      shipping_method: this.currentOrderDetails.shipment_method,
      logistic_per_unit: this.currentOrderDetails.logistic_per_unit,
      currency_order: setCurrencyOrder,
      currency_origin: setCurrencyOrigin,
      weight_measurement: "kg",
      order_type: this.currentOrderDetails.order_type,
      products: [],
      revision: {
        // "total_weight": this.currentOrderDetails.total_weight,
        total_cbm: Number(newValCbm)
        // "custom_charges": [
        //  {
        //    "key": "total_duty",
        //    "value": this.currentOrderDetails.total_duty
        //  },
        //  {
        //    "key": "total_wht",
        //    "value": this.currentOrderDetails.total_wht
        //  },
        //  {
        //    "key": "total_vat",
        //    "value": this.currentOrderDetails.total_vat
        //  }
        // ]
      },
      custom_logistic_charges: custom_logistic_charge
    };

    var arrEditWeight = [];
    forEach(this.currentOrderDetails.details, item => {
      // item.attributes.item_rule.attributes.taxes
      if (isEmpty(item.attributes.item_rule.attributes.taxes)) {
        item.attributes.item_rule.attributes.taxes = [
          { label: "BM", value: 0 },
          { label: "PPN", value: 0 },
          { label: "PPH", value: 0 }
        ];
      }

      var arrEditWeightItem: any = {};
      if (item.attributes.variants[0].hasOwnProperty("origin")) {
        arrEditWeightItem = {
          unit_name: item.attributes.unit_name,
          id: item.attributes.product_id,
          variants: []
        };
        var objVarFirsParent = {
          price_edit: item.attributes.cost,
          price_custom_edit:
            Number(item.attributes.original_price) *
            Number(this.user_conversion),
          quantity: item.attributes.quantity,
          attributes: item.attributes.variants,

          settings: [
            {
              name: item.attributes.item_rule.attributes.taxes[0].label,
              value: item.attributes.item_rule.attributes.taxes[0].value
            },
            {
              name: item.attributes.item_rule.attributes.taxes[2].label,
              value: item.attributes.item_rule.attributes.taxes[2].value
            },
            {
              name: item.attributes.item_rule.attributes.taxes[1].label,
              value: item.attributes.item_rule.attributes.taxes[1].value
            },
            {
              name: "setting_weight",
              value: item.attributes.item_rule.attributes.weight
            },
            {
              name: "setting_volume_cbm",
              value: item.attributes.item_rule.attributes.qty_cbm
            }
          ]
        };
        arrEditWeightItem.variants.push(objVarFirsParent);
      } else {
        arrEditWeightItem = {
          price_edit: item.attributes.cost,
          price_custom_edit:
            Number(item.attributes.original_price) *
            Number(this.user_conversion),
          id: item.attributes.product_id,
          type: "single",
          quantity: item.attributes.quantity,
          unit_name: item.attributes.unit_name,
          settings: [
            {
              name: item.attributes.item_rule.attributes.taxes[0].label,
              value: item.attributes.item_rule.attributes.taxes[0].value
            },
            {
              name: item.attributes.item_rule.attributes.taxes[2].label,
              value: item.attributes.item_rule.attributes.taxes[2].value
            },
            {
              name: item.attributes.item_rule.attributes.taxes[1].label,
              value: item.attributes.item_rule.attributes.taxes[1].value
            },
            {
              name: "setting_weight",
              value: item.attributes.item_rule.attributes.weight
            },
            {
              name: "setting_volume_cbm",
              value: item.attributes.item_rule.attributes.qty_cbm
            }
          ]
        };
      }
      payload_edit_weight.products.push(arrEditWeightItem);
    });
    // console.log('payload_edit_weight',payload_edit_weight)
    // ini ganti
    const responseCalculateEditWeight = await Calculate.calculateOrder(
      payload_edit_weight
    );
    // console.log('responseCalculateEditWeight',responseCalculateEditWeight)
    // const new_payload: any = {}

    if (isEmpty(newValCbm)) {
      var revTotCbm: any = {
        revised_total_cbm: newValCbm
      };
    }
    if (newValCbm === 0) {
      var revTotCbm: any = {
        revised_total_cbm: Number(newValCbm)
      };
    }
    if (newValCbm > 0) {
      var revTotCbm: any = {
        revised_total_cbm: Number(newValCbm)
      };
    }

    revTotCbm.logistic_cost =
      responseCalculateEditWeight.data.data.total_logistic_all_in;
    revTotCbm.total_goods_value =
      responseCalculateEditWeight.data.data.total_goods_value;
    revTotCbm.total_logistic_charges =
      responseCalculateEditWeight.data.data.total_logistic_charge;
    revTotCbm.grand_total = responseCalculateEditWeight.data.data.grand_total;
    return revTotCbm;
  }

  // yogie set2
  async revisedKg(newValKg) {
    // var total_weight_per_varian = 0
    // var total_logistic_cost_pervarian = 0

    // for (const detail of this.currentOrderDetails.details) {
    //  if (detail.attributes.item_rule === null) {
    //    detail.attributes.item_rule = {
    //      type: "",
    //      id: "",
    //      attributes: {
    //        id: 0,
    //        order_item_id: 0,
    //        qty_cbm: 0,
    //        weight: 0,
    //        weight_unit: "",
    //        total_weight: 0,
    //        taxes: [
    //          {
    //            label: "BM",
    //            value: 0
    //          },
    //          {
    //            label: "PPN",
    //            value: 0
    //          },
    //          {
    //            label: "PPH",
    //            value: 0
    //          }
    //        ],
    //        total_tax: 0,
    //        hscodes: ""
    //      }
    //    }
    //  }
    // }
    // console.log('this.custom_charges',this.custom_charges)
    // //sini
    var custom_logistic_charge = [];
    forEach(this.custom_charges, charge => {
      var set_charge = {
        type: "custom-optional",
        group: charge.name,
        name: charge.name,
        price: charge.price,
        // vat dihilangkan dulu
        // "vat": charge.vat
        vat: 0
      };
      custom_logistic_charge.push(set_charge);
    });

    // // set shipment from if from record db is null
    if (this.currentOrderDetails.shipment_from !== null) {
      this.currentOrderDetails.shipment_from = Number(
        this.currentOrderDetails.shipment_from
      );
    } else {
      this.currentOrderDetails.shipment_from = 44;
    }
    // set shipment to if from record db is null
    if (this.currentOrderDetails.shipment_to !== null) {
      this.currentOrderDetails.shipment_to = Number(
        this.currentOrderDetails.shipment_to
      );
    } else {
      this.currentOrderDetails.shipment_to = 99;
    }

    // set currency_order
    var setCountryById = await CountryService.getCountryById(
      this.currentOrderDetails.shipment_to
    );
    var setCurrencyOrder = setCountryById.data.data.attributes.currency;

    // set currency_origin
    var setCountryById = await CountryService.getCountryById(
      this.currentOrderDetails.shipment_from
    );
    var setCurrencyOrigin = setCountryById.data.data.attributes.currency;

    // // set for calculation
    var payload_edit_weight: any = {
      type: "order",
      ship_from: this.currentOrderDetails.shipment_from,
      ship_to: this.currentOrderDetails.shipment_to,
      shipping_method: this.currentOrderDetails.shipment_method,
      logistic_per_unit: this.currentOrderDetails.logistic_per_unit,
      currency_order: setCurrencyOrder,
      currency_origin: setCurrencyOrigin,
      weight_measurement: "kg",
      order_type: this.currentOrderDetails.order_type,
      products: [],
      revision: {
        total_weight: Number(newValKg)
        // "total_cbm": this.currentOrderDetails.total_cbm,
        // "custom_charges": [
        //  {
        //    "key": "total_duty",
        //    "value": this.currentOrderDetails.total_duty
        //  },
        //  {
        //    "key": "total_wht",
        //    "value": this.currentOrderDetails.total_wht
        //  },
        //  {
        //    "key": "total_vat",
        //    "value": this.currentOrderDetails.total_vat
        //  }
        // ]
      },
      custom_logistic_charges: custom_logistic_charge
    };

    var arrEditWeight = [];
    forEach(this.currentOrderDetails.details, item => {
      // console.log('meti>>>>>',item)

      // item.attributes.item_rule.attributes.taxes
      if (isEmpty(item.attributes.item_rule.attributes.taxes)) {
        item.attributes.item_rule.attributes.taxes = [
          { label: "BM", value: 0 },
          { label: "PPN", value: 0 },
          { label: "PPH", value: 0 }
        ];
      }

      var arrEditWeightItem: any = {};
      if (item.attributes.variants[0].hasOwnProperty("origin")) {
        // if(item.attributes.variants[0].variation_id === null) {
        arrEditWeightItem = {
          unit_name: item.attributes.unit_name,
          id: item.attributes.product_id,
          variants: []
        };
        var objVarFirsParent = {
          price_edit: item.attributes.cost,
          price_custom_edit:
            Number(item.attributes.original_price) *
            Number(this.user_conversion),
          quantity: item.attributes.quantity,
          attributes: item.attributes.variants,

          settings: [
            {
              name: item.attributes.item_rule.attributes.taxes[0].label,
              value: item.attributes.item_rule.attributes.taxes[0].value
            },
            {
              name: item.attributes.item_rule.attributes.taxes[2].label,
              value: item.attributes.item_rule.attributes.taxes[2].value
            },
            {
              name: item.attributes.item_rule.attributes.taxes[1].label,
              value: item.attributes.item_rule.attributes.taxes[1].value
            },
            {
              name: "setting_weight",
              value: item.attributes.item_rule.attributes.weight
            },
            {
              name: "setting_volume_cbm",
              value: item.attributes.item_rule.attributes.qty_cbm
            }
          ]
        };
        arrEditWeightItem.variants.push(objVarFirsParent);
      } else {
        arrEditWeightItem = {
          price_edit: item.attributes.cost,
          price_custom_edit:
            Number(item.attributes.original_price) *
            Number(this.user_conversion),
          id: item.attributes.product_id,
          type: "single",
          quantity: item.attributes.quantity,
          unit_name: item.attributes.unit_name,
          settings: [
            {
              name: item.attributes.item_rule.attributes.taxes[0].label,
              value: item.attributes.item_rule.attributes.taxes[0].value
            },
            {
              name: item.attributes.item_rule.attributes.taxes[2].label,
              value: item.attributes.item_rule.attributes.taxes[2].value
            },
            {
              name: item.attributes.item_rule.attributes.taxes[1].label,
              value: item.attributes.item_rule.attributes.taxes[1].value
            },
            {
              name: "setting_weight",
              value: item.attributes.item_rule.attributes.weight
            },
            {
              name: "setting_volume_cbm",
              value: item.attributes.item_rule.attributes.qty_cbm
            }
          ]
        };
      }
      payload_edit_weight.products.push(arrEditWeightItem);
    });
    // // console.log('payload_edit_weight',JSON.stringify(payload_edit_weight))
    // console.log('payload_edit_weight_calculation',payload_edit_weight)
    // ini ganti
    const responseCalculateEditWeight = await Calculate.calculateOrder(
      payload_edit_weight
    );
    // console.log('responseCalculateEditWeight',responseCalculateEditWeight.data.data)

    if (isEmpty(newValKg)) {
      var revTotWeight: any = {
        revised_total_weight: newValKg
      };
    }
    if (newValKg === 0) {
      var revTotWeight: any = {
        revised_total_weight: Number(newValKg)
      };
    }
    if (newValKg > 0) {
      var revTotWeight: any = {
        revised_total_weight: Number(newValKg)
      };
    }

    revTotWeight.logistic_cost =
      responseCalculateEditWeight.data.data.total_logistic_all_in;
    revTotWeight.total_goods_value =
      responseCalculateEditWeight.data.data.total_goods_value;
    revTotWeight.total_logistic_charges =
      responseCalculateEditWeight.data.data.total_logistic_charge;
    revTotWeight.grand_total =
      responseCalculateEditWeight.data.data.grand_total;

    return revTotWeight;

    // return false
  }

  //sini
  //save change total cbm or total kg
  async onChangeWeightModalSave() {
    try {
      const valid = await this.$validator.validateAll("editWeights");
      if (valid) {
        this.showLoading({ text: "Saving..." });
        var response = {
          data: {
            data: {
              id: ""
            }
          }
        };

        if (this.editWeightOrCBM.currentlyEditing === "cbm") {
          const returnPayload = await this.revisedCbm(
            this.editWeightOrCBM.newAmount
          );
          var payload = returnPayload;
        } else {
          const returnPayload = await this.revisedKg(
            this.editWeightOrCBM.newAmount
          );
          var payload = returnPayload;
        }

        var setParams = {};
        response = await OrdersService.updateOrderEdit(
          payload,
          setParams,
          this.$route.params.id
        );

        this.showSnackbar({
          text: "Saved Successfully!",
          color: "teal",
          timeout: 1500
        });

        this.onChangeWeightModalClosed();
        this.getOrderDetails();
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  onChangeProductModalClosed() {
    this.editProduct.selectedProduct = null;
    this.editProduct.open = false;
    this.$validator.errors.clear();
    this.$validator.reset();
  }

  // sini
  // Detail Edit Methods
  async onChangeProductModalOpen(props) {
    console.log("onChangeProductModalOpen", props);
    // get editProduct.selectedProduct
    // console.log(props)

    // this.getOriginCities()

    // untuk supplier
    // props.item.supplier_id
    // props.item.supplier_name
    this.searchEditItemSupplier = props.item.supplier_name;
    await this.editItemGetSupplier();

    this.searchEditItemCity = props.item.city_name;
    this.searchEditItemCityById = props.item.city_id;
    await this.editItemGetCity();

    // untuk cities
    // props.item.city_id

    this.editProduct.selectedProduct = cloneDeep(props.item);
    this.editProduct.selectedProduct.index = props.index;
    // console.log('this.editProduct.selectedProduct',this.editProduct.selectedProduct)
    // set volume
    // var volume = Number(1) / Number(this.editProduct.selectedProduct.item_rule.attributes.qty_cbm)

    this.editProduct.selectedProduct.supplier_id = Number(
      props.item.supplier_id
    );
    this.editProduct.selectedProduct.city_id = Number(props.item.city_id);

    if (this.editProduct.selectedProduct.item_rule.attributes.qty_cbm === 0) {
      this.editProduct.selectedProduct.item_rule.attributes.volume = 0;
    } else {
      // yogie set
      this.editProduct.selectedProduct.item_rule.attributes.volume = Number(
        Number(1) /
          Number(this.editProduct.selectedProduct.item_rule.attributes.qty_cbm)
      ).toFixed(4);
    }
    // if(this.editProduct.selectedProduct.revised_quantity === null) {
    //  this.editProduct.selectedProduct.revised_quantity = 0
    // }
    // this.editProduct.selectedProduct.index = props.index

    // console.log('onChangeProductModalOpen => this.editProduct.selectedProduct.item_status', this.editProduct.selectedProduct.item_status)

    // set status per item
    for (const stat of this.editProduct.selectedProduct.item_status) {
      var item_status = stat.attributes.name;
      this.editProduct.selectedProduct.new_status_per_item = {
        text: stat.attributes.name,
        id: stat.attributes.id
      };
    }

    // Mengubah default value = 0, untuk Landed cost jika null. Jira --> AS-3197
    console.log(
      "Landed Cost value :",
      this.editProduct.selectedProduct.landed_cost
    );

    if (this.editProduct.selectedProduct.landed_cost == null) {
      this.editProduct.selectedProduct.landed_cost = 0;
    }

    // Open Modals
    this.editProduct.open = true;
  }

  // payload conversion_rate dan user_conversion dikirimkan dalam calculation
  // diambil dari get Currency Rate bedasarkan goods currency vs order currency
  //save product
  // sini
  async onChangeProductModalSave() {
    try {
      const valid = await this.$validator.validateAll("editProduct");
      if (valid) {
        const shipmentMethod = this.currentOrderDetails.shipment_method;
        var edited_item_id = this.editProduct.selectedProduct.id;
        // console.log(edited_item_id)
        // var currentOrderOthers = CalculateFromExcelService.getOthersDetail(this.currentOrderDetails, edited_item_id)
        // // console.log('currentOrderOthers', currentOrderOthers)

        // console.log('this.currentOrderDetails.details',this.currentOrderDetails.details)
        // console.log('ini =============',this.editProduct.selectedProduct.cost_per_product)
        // console.log('this.editProduct =============',this.editProduct)

        var others = [];
        forEach(this.currentOrderDetails.details, detail => {
          if (parseInt(detail.id) !== parseInt(edited_item_id)) {
            others.push(detail);
          }
        });
        console.log(
          "this.editProduct.selectedProduct",
          this.editProduct.selectedProduct
        );
        var newEdit: any = {
          attributes: this.editProduct.selectedProduct,
          id: this.editProduct.selectedProduct.id,
          type: "order_items_edited"
        };
        console.log("newEdit", newEdit);
        //change value volume to qty_cbm
        newEdit.attributes.item_rule.attributes.qty_cbm =
          Number(1) / Number(newEdit.attributes.item_rule.attributes.volume);

        var newOrder = others;

        // console.log('newOrder 1 ***********',newOrder)

        newOrder.push(newEdit);

        // console.log('newOrder 2 ***********',newOrder)

        //sinis
        var custom_logistic_charge = [];
        forEach(this.custom_charges, charge => {
          var set_charge = {
            type: "custom-optional",
            group: charge.name,
            name: charge.name,
            price: charge.price,
            // vat dihilangkan dulu
            // "vat": charge.vat
            vat: 0
          };
          custom_logistic_charge.push(set_charge);
        });

        // // set shipment from if from record db is null
        if (this.currentOrderDetails.shipment_from !== null) {
          this.currentOrderDetails.shipment_from = Number(
            this.currentOrderDetails.shipment_from
          );
        } else {
          this.currentOrderDetails.shipment_from = 44;
        }
        // set shipment to if from record db is null
        if (this.currentOrderDetails.shipment_to !== null) {
          this.currentOrderDetails.shipment_to = Number(
            this.currentOrderDetails.shipment_to
          );
        } else {
          this.currentOrderDetails.shipment_to = 99;
        }

        // set currency_order
        var setCountryById = await CountryService.getCountryById(
          this.currentOrderDetails.shipment_to
        );
        var setCurrencyOrder = setCountryById.data.data.attributes.currency;

        // set currency_origin
        var setCountryById = await CountryService.getCountryById(
          this.currentOrderDetails.shipment_from
        );
        var setCurrencyOrigin = setCountryById.data.data.attributes.currency;

        // set for calculation
        var payload_edit_product: any = {
          type: "order",
          ship_from: this.currentOrderDetails.shipment_from,
          ship_to: this.currentOrderDetails.shipment_to,
          shipping_method: shipmentMethod,
          logistic_per_unit: this.currentOrderDetails.logistic_per_unit,
          currency_order: setCurrencyOrder,
          currency_origin: setCurrencyOrigin,
          weight_measurement: "kg",
          order_type: this.currentOrderDetails.order_type,
          products: [],
          // dont use this when just edit item products,
          // only use this, when edit logistic details
          // "revision": {
          //     "total_weight": this.currentOrderDetails.total_weight,
          //     "total_cbm": this.currentOrderDetails.total_cbm,
          //     "custom_charges": [
          //         {
          //             "key": "total_duty",
          //             "value": this.currentOrderDetails.total_duty
          //         },
          //         {
          //             "key": "total_wht",
          //             "value": this.currentOrderDetails.total_wht
          //         },
          //         {
          //             "key": "total_vat",
          //             "value": this.currentOrderDetails.total_vat
          //         }
          //     ]
          // },
          custom_logistic_charges: custom_logistic_charge,
          revision: {}
        };

        // // check if there is revised total weight or revised total cbm
        // if(this.currentOrderDetails.revised_total_weight !== null) {
        //  payload_edit_product.revision.total_weight = this.responseCalculationRevised.total_weight
        // } else {
        //  payload_edit_product.revision.total_weight = this.responseCalculation.total_weight
        // }
        // if(this.currentOrderDetails.revised_total_cbm !== null) {
        //  payload_edit_product.revision.total_cbm = this.responseCalculationRevised.total_cbm
        // } else {
        //  payload_edit_product.revision.total_cbm = this.responseCalculation.total_cbm
        // }

        forEach(newOrder, item => {
          let newQty = item.attributes.quantity;

          if (item.attributes.revised_quantity !== null) {
            newQty = item.attributes.revised_quantity;
          }

          // console.log("item", item)

          // item.attributes.item_rule.attributes.taxes
          // console.log("forEach item", item)
          // let qty_used =
          // if (item.attributes.revised_quantity === null) {

          // }

          if (isEmpty(item.attributes.item_rule.attributes.taxes)) {
            item.attributes.item_rule.attributes.taxes = [
              { label: "BM", value: 0 },
              { label: "PPN", value: 0 },
              { label: "PPH", value: 0 }
            ];
          }

          var arrEditProductItem: any = {};

          if (item.attributes.variants[0].hasOwnProperty("origin")) {
            arrEditProductItem = {
              unit_name: item.attributes.unit_name,
              id: item.attributes.product_id,
              variants: []
            };

            var theNewCost = 0;
            if (item.attributes.hasOwnProperty("cost_per_product")) {
              // code...
              console.log("sini IF");
              theNewCost = item.attributes.cost_per_product;
            } else {
              console.log("sini ELSE");
              theNewCost = item.attributes.cost;
            }
            console.log("theNewCost", theNewCost);

            var objVarFirsParent = {
              // old
              // price_edit: item.attributes.cost,
              price_edit: theNewCost,
              price_custom_edit:
                Number(item.attributes.original_price) *
                Number(this.user_conversion),
              // quantity: item.attributes.quantity,
              quantity: newQty,
              attributes: item.attributes.variants,
              // diisi dari this.editProduct.selectedProduct
              // this.editProduct.selectedProduct
              settings: [
                {
                  name: find(
                    item.attributes.item_rule.attributes.taxes,
                    item => item.label.toLowerCase() === "bm"
                  ).label,
                  value: this.editProduct.selectedProduct.taxes.bm.edited
                },
                {
                  name: find(
                    item.attributes.item_rule.attributes.taxes,
                    item => item.label.toLowerCase() === "ppn"
                  ).label,
                  value: this.editProduct.selectedProduct.taxes.ppn.edited
                },
                {
                  name: find(
                    item.attributes.item_rule.attributes.taxes,
                    item => item.label.toLowerCase() === "pph"
                  ).label,
                  value: this.editProduct.selectedProduct.taxes.pph.edited
                },
                {
                  name: "setting_weight",
                  value: item.attributes.item_rule.attributes.weight
                },
                {
                  name: "setting_volume_cbm",
                  value: item.attributes.item_rule.attributes.qty_cbm
                  // ganti dengan 1/ volume
                }
              ]
            };
            // if(item.attributes.revised_quantity !== null) {
            //  objVarFirsParent.quantity = item.attributes.revised_quantity
            // }
            arrEditProductItem.variants.push(objVarFirsParent);
          } else {
            var theNewCost = 0;
            if (item.attributes.hasOwnProperty("cost_per_product")) {
              // code...
              console.log("sini IF item.attributes", item.attributes);
              theNewCost = item.attributes.cost_per_product;
            } else {
              console.log("sini ELSE");
              theNewCost = item.attributes.cost;
            }
            console.log(
              "after if this.editProduct.selectedProduct.taxes",
              this.editProduct.selectedProduct.taxes
            );
            arrEditProductItem = {
              // old
              // price_edit: item.attributes.cost,
              price_edit: theNewCost,
              price_custom_edit:
                Number(item.attributes.original_price) *
                Number(this.user_conversion),
              unit_name: item.attributes.unit_name,
              id: item.attributes.product_id,
              type: "single",
              // quantity: item.attributes.quantity,
              quantity: newQty,
              settings: [
                //  old
                // {
                //   name: item.attributes.item_rule.attributes.taxes[0].label,
                //   value: item.attributes.item_rule.attributes.taxes[0].value
                // },
                {
                  name: find(
                    item.attributes.item_rule.attributes.taxes,
                    item => item.label.toLowerCase() === "bm"
                  ).label,
                  value: this.editProduct.selectedProduct.taxes.bm.edited
                },
                // {
                //   name: item.attributes.item_rule.attributes.taxes[2].label,
                //   value: item.attributes.item_rule.attributes.taxes[2].value
                // },
                {
                  name: find(
                    item.attributes.item_rule.attributes.taxes,
                    item => item.label.toLowerCase() === "ppn"
                  ).label,
                  value: this.editProduct.selectedProduct.taxes.ppn.edited
                },
                {
                  name: find(
                    item.attributes.item_rule.attributes.taxes,
                    item => item.label.toLowerCase() === "pph"
                  ).label,
                  value: this.editProduct.selectedProduct.taxes.pph.edited
                },
                {
                  name: "setting_weight",
                  value: item.attributes.item_rule.attributes.weight
                },
                {
                  name: "setting_volume_cbm",
                  value: item.attributes.item_rule.attributes.qty_cbm
                }
              ]
            };
            // if(item.attributes.revised_quantity !== null) {
            //  arrEditProductItem.quantity = item.attributes.revised_quantity
            // }
          }
          payload_edit_product.products.push(arrEditProductItem);
        });

        // console.log('payload_edit_product',payload_edit_product)
        // yogie set2
        // ini ganti
        // const responseCalculateEditItem = await Calculate.calculateOrder(
        //   payload_edit_product
        // )
        // console.log("responseCalculateEditItem", responseCalculateEditItem)
        // console.log('this.responseOrder',this.responseOrder)
        // new
        // this.currentOrderDetails
        // let payloadNewCalc = {
        //   newData: {
        //     data: {
        //       attributes: this.currentOrderDetails
        //     }
        //   }
        // }
        // console.log('payloadNewCalc',payloadNewCalc)
        // this.responseOrder
        // this.currentOrderDetails
        // edited_item_id
        const responseCalculateEditItem = await OrderFunctions.setPayloadCalculationItem(
          newEdit,
          payload_edit_product,
          this.responseOrder,
          edited_item_id
        );
        // console.log("responseCalculateEditItem", responseCalculateEditItem);

        // console.log('responseCalculateEditItem',responseCalculateEditItem.data.data)

        // yogie
        const new_payload: any = {};
        //sinis
        // new_payload.cost = responseCalculateEditItem.data.data.total_goods_value

        new_payload.logistic_cost =
          responseCalculateEditItem.data.data.total_logistic_all_in;
        new_payload.total_logistic_charges =
          responseCalculateEditItem.data.data.total_logistic_charge;
        new_payload.total_weight =
          responseCalculateEditItem.data.data.total_weight;
        new_payload.total_cbm = responseCalculateEditItem.data.data.total_cbm;

        // new_payload.total_duty = this.currentOrderDetails.total_duty
        // new_payload.total_wht = this.currentOrderDetails.total_wht
        // new_payload.total_vat = this.currentOrderDetails.total_vat
        // // new_payload.total_custom_charge = responseCalculateEditItem.data.data.total_custom_charge
        // new_payload.total_custom_charge = Number(this.currentOrderDetails.total_duty)+Number(this.currentOrderDetails.total_wht)+
        //  Number(this.currentOrderDetails.total_vat)

        new_payload.total_duty = null;
        new_payload.total_wht = null;
        new_payload.total_vat = null;
        // new_payload.total_custom_charge = responseCalculateEditItem.data.data.total_custom_charge
        new_payload.total_custom_charges = null;
        // new_payload.total_logistic_charges = responseCalculateEditItem.data.data.total_custom_logistic_charges

        new_payload.total_goods_value =
          responseCalculateEditItem.data.data.total_goods_value;

        new_payload.grand_total =
          responseCalculateEditItem.data.data.grand_total;

        var checkedChangeTaxDuty =
          this.editProduct.selectedProduct.item_rule.attributes.taxes[0]
            .value !== this.editProduct.selectedProduct.taxes.bm.edited;
        var checkedChangeTaxVat =
          this.editProduct.selectedProduct.item_rule.attributes.taxes[2]
            .value !== this.editProduct.selectedProduct.taxes.ppn.edited;
        var checkedChangeTaxWht =
          this.editProduct.selectedProduct.item_rule.attributes.taxes[1]
            .value !== this.editProduct.selectedProduct.taxes.pph.edited;

        var checkedTax =
          checkedChangeTaxDuty || checkedChangeTaxWht || checkedChangeTaxVat;
        this.orderItemHasChangedTax = checkedTax;
        const new_taxes = [
          {
            label: "BM",
            value: this.editProduct.selectedProduct.taxes.bm.edited
          },
          {
            label: "PPN",
            value: this.editProduct.selectedProduct.taxes.ppn.edited
          },
          {
            label: "PPH",
            value: this.editProduct.selectedProduct.taxes.pph.edited
          }
        ];
        // new_total_taxes_rule
        var new_total_taxes_rule = 0;
        forEach(new_taxes, tax => {
          new_total_taxes_rule += Number(tax.value);
        });
        // var new_order_total_tax = Number(new_total_taxes_rule)

        var setQtyCbmFromVolume = 0;
        if (
          this.editProduct.selectedProduct.item_rule.attributes.volume === 0
        ) {
          setQtyCbmFromVolume = 0;
        } else {
          setQtyCbmFromVolume = Number(
            (
              Number(1) /
              Number(
                this.editProduct.selectedProduct.item_rule.attributes.volume
              )
            ).toFixed(3)
          );
        }

        new_payload.items = [
          {
            product_name: this.editProduct.selectedProduct.product_name,
            city_id: this.editProduct.selectedProduct.city_id,
            city_name: this.editProduct.selectedProduct.city_name,
            supplier_id: this.editProduct.selectedProduct.supplier_id,
            supplier_name: this.editProduct.selectedProduct.supplier_name,
            warehouse: this.editProduct.selectedProduct.warehouse,
            original_price: this.editProduct.selectedProduct.original_price,
            detail_id: this.editProduct.selectedProduct.id,
            quantity: this.editProduct.selectedProduct.quantity,
            price: this.editProduct.selectedProduct.price,
            origin_sku: this.editProduct.selectedProduct.origin_sku,
            ali_sku: this.editProduct.selectedProduct.ali_sku,
            landed_cost: this.editProduct.selectedProduct.landed_cost,
            revised_landed_cost: this.editProduct.selectedProduct
              .revised_landed_cost,
            revised_quantity: this.editProduct.selectedProduct.revised_quantity,
            commission_per_sku: this.editProduct.selectedProduct
              .commission_per_sku,
            //old
            // cost: this.editProduct.selectedProduct.cost,
            // yogiesetiarko
            // cost: Number(this.editProduct.selectedProduct.original_price)*Number(this.currentOrderDetails.conversion),
            cost: this.editProduct.selectedProduct.cost_per_product,
            original_name: this.editProduct.selectedProduct.original_name,
            // variants: this.editProduct.selectedProduct.variants,
            sku_status: {
              id: this.editProduct.selectedProduct.new_status_per_item.id
            },
            rule: {
              weight: this.editProduct.selectedProduct.item_rule.attributes
                .weight,
              weight_unit: "Kg",
              total_weight:
                Number(
                  this.editProduct.selectedProduct.item_rule.attributes.weight
                ) * Number(this.editProduct.selectedProduct.quantity),
              total_tax: new_total_taxes_rule,
              qty_cbm: setQtyCbmFromVolume,
              hscodes: this.editProduct.selectedProduct.item_rule.attributes
                .hscodes,
              taxes: new_taxes
            }
          }
        ];
        // if (this.editProduct.selectedProduct.revised_quantity !== null) {
        //   new_payload.items.revised_quantity = this.editProduct.selectedProduct.revised_quantity;
        // }
        // if (this.editProduct.selectedProduct.revised_landed_cost === null) {
        //   new_payload.items.revised_landed_cost = this.editProduct.selectedProduct.revised_landed_cost;
        // }
        this.showLoading({ text: "Saving Data..." });

        // await OrdersService.updateOrder(this.$route.params.id, payload)
        var setParams = {};
        // console.log("new_payload", JSON.stringify(new_payload))
        var returnResp = await OrdersService.updateOrderEdit(
          new_payload,
          setParams,
          this.$route.params.id
        );

        // var returnResp = await OrdersService.updateOrder(new_payload)
        // var id = returnResp.data.data.id

        this.showSnackbar({
          text: "Saved Successfully!",
          color: "green",
          timeout: 2500
        });
        this.onChangeProductModalClosed();
        // this.onChangeProductModalSave()
        this.getOrderDetails();
        // siniy
        // window.location.href = "/orders/" + id + "/details"
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  // Status color chooser
  sourceColor(source: string = "") {
    if (snakeCase(source) === snakeCase("ALI-1")) {
      return "grey lighten-2 black--text";
    } else if (snakeCase(source) === snakeCase("ALI-2")) {
      return "teal lighten-2 white--text";
    } else {
      return "black--text";
    }
  }

  goToSourceUrl(props) {
    const sourceUrl = props.item.original_source_url;
    const url = props.item.original_url;
    // if (isEmpty(sourceUrl)) {
    //  this.showSnackbar({
    //    text: "Sorry, no source URL for this item",
    //    color: "red",
    //    timeout: 2000
    //  })
    // } else {
    //  this.goTo(this.$router, sourceUrl, "_blank")
    // }
    if (sourceUrl) {
      this.goTo(this.$router, sourceUrl, "_blank");
    } else if (url) {
      this.goTo(this.$router, url, "_blank");
    } else {
      this.showSnackbar({
        text: "Sorry, no source URL for this item",
        color: "red",
        timeout: 2000
      });
    }
  }

  goToProductUrl(props) {
    this.goTo(
      this.$router,
      `/products/${props.item.product_id}/edit`,
      "_blank"
    );
  }

  async getQualityControlList() {
    try {
      const opts: any = {
        params: {
          include: "items,invoices,platforms,parent,child"
        }
      };

      this.tableLoadingQc = true;

      const response = await OrdersService.getOneOrder(this.$route.params.id);

      const dataDetailsQC = response.data.attributes;

      this.tableItemsQC = [];

      for (const dataDetailQC of dataDetailsQC.details) {
        this.addQualityControl.failed = 0;
        this.addQualityControl.passed = 0;

        const get_order_item_id: any = {
          id: dataDetailQC.attributes.id
        };

        const opts: any = {
          params: {
            "filter[order_item_id]": get_order_item_id.id,
            include: "images"
          }
        };

        const response = await QualityControlService.getQCs(opts);

        const responseData = response.data.data;

        forEach(responseData, dataQualityControl => {
          const quality_control = {
            id_qc: dataQualityControl.attributes.id,
            order_item_id: dataQualityControl.attributes.order_item_id,
            note: dataQualityControl.attributes.note,
            group: dataQualityControl.attributes.group,
            image: dataQualityControl.attributes.image,
            created_at: dataQualityControl.attributes.created_at
          };

          // if (lowerCase(quality_control.group) === "failed") {
          //  this.addQualityControl.failed = this.addQualityControl.failed + 1
          // } else if (lowerCase(quality_control.group) === "passed") {
          //  this.addQualityControl.passed = this.addQualityControl.passed + 1
          // } else {
          //  this.addQualityControl.failed = 0
          //  this.addQualityControl.passed = 0
          // }
        });

        const item: any = {
          id: dataDetailQC.attributes.id,
          productName: dataDetailQC.attributes.product_name,
          variants: dataDetailQC.attributes.variants,
          id_qc: this.addQualityControl.id_qc,
          qc: responseData.length
          // failed   : this.addQualityControl.failed,
          // passed   : this.addQualityControl.passed
        };

        this.tableItemsQC.push(item);
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.tableLoadingQc = false;
    }
  }

  // Open Modal Quality Control
  onAddQualityControlModalOpen(props) {
    this.getQualityControl(props.item.id);
    this.addQualityControl.order_item_id = props.item.id;
    this.addQualityControl.productName = props.item.productName;
    this.addQualityControl.variants = props.item.variants;
    this.addQualityControl.product_order_id = props.item.product_order_id;
    this.addQualityControl.open = true;
  }

  // Data Quality Control per-SKU
  async getQualityControl(id) {
    try {
      const opts: any = {
        params: {
          "filter[order_item_id]": id,
          include: "images,qcGroup"
        }
      };

      const response = await QualityControlService.getQCs(opts);

      const responseData = response.data.data;

      forEach(responseData, dataQualityControl => {
        const group =
          response.data.included.qcGroup[dataQualityControl.attributes.group]
            .attributes.name;

        const quality_control = {
          productName: this.addQualityControl.productName,
          variants: this.addQualityControl.variants,
          id_qc: dataQualityControl.attributes.id,
          order_item_id: dataQualityControl.attributes.order_item_id,
          note: dataQualityControl.attributes.note,
          group: group,
          image: dataQualityControl.attributes.image,
          created_at: dataQualityControl.attributes.created_at
        };

        this.dataQCs.items.push(quality_control);
      });
    } catch (error) {
      this.catchHandler(error);
    }
  }

  // Create and Save New QC Group
  async onInputGroupQCSave() {
    try {
      const valid = await this.$validator.validateAll(
        "InputGroupQualityControl"
      );
      if (valid) {
        const payload = {
          name: this.toStartCase(this.addQualityControl.group)
        };

        const response = await QualityControlService.saveQCGroupItem(payload);

        this.addQualityControl.valueGroupQC = response.data.data.attributes.id;
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 1500
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  // Images
  imageAdded(file) {}
  imageAddedError(file) {
    this.error();

    if (
      file.type != "image/jpg" &&
      file.type != "image/jpeg" &&
      file.type != "image/png"
    ) {
      (this.$refs as any).imageQC.removeFile(file);
      this.showSnackbar({
        text: "Error! Not an image file(color: s)",
        timeout: ""
      });
    } else if (file.size > this.options.maxFileSizeInMB * 1024 * 1024) {
      (this.$refs as any).imageQC.removeFile(file);
      this.showSnackbar({
        text: `Error! Image(s) size is too large. Max ${this.options.maxFileSizeInMB} MB`,
        color: "red",
        timeout: 5000
      });
    } else if (
      (this.$refs as any).imageQC.getQueuedFiles().length >=
      this.options.maxNumberOfFiles
    ) {
      (this.$refs as any).imageQC.removeFile(file);
      this.showSnackbar({
        text: `Error! Image(s) too much. Max ${this.options.maxNumberOfFiles} Images`,
        color: "red",
        timeout: 5000
      });
    }
  }
  imageDuplicate(file) {
    this.showSnackbar({
      text: "Image is already added",
      color: "orange",
      timeout: 2000
    });
  }
  beforeSendingImage(file, xhr, formData) {
    //   this.showSnackbar({ text: 'Before Sending', color: 'orange', timeout: 2000 })
    formData.append("type", "image");
  }
  beforeSendingImageMultiple(file, xhr, formData) {
    formData.append("type", "image");
  }
  imageUploadSuccess(file, response) {
    this.showSnackbar({
      text: "Image Upload Success",
      color: "orange",
      timeout: 2000
    });
    if ((this.$refs as any).imageQC.getUploadingFiles().length <= 0)
      this.done();
  }
  imageUploadMultipleSuccess(files, response) {
    this.showSnackbar({
      text: "Image Multiple Upload Success",
      color: "orange",
      timeout: 2000
    });
    if ((this.$refs as any).imageQC.getUploadingFiles().length <= 0)
      this.done();
  }
  imageUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {
    //   this.showSnackbar({ text: 'Image Upload Progress', color: 'orange', timeout: 2000 })
  }

  // Add Quality Control
  async onAddQualityControlSave() {
    try {
      // const valid = this.$validator.validateAll("addQualityControl")
      const valid = await this.$validator.validateAll("addQualityControl");
      if (valid) {
        if (
          (this.$refs as any).imageQC.getQueuedFiles().length > 0 &&
          this.addQualityControl.valueGroupQC != null
        ) {
          if (
            this.addQualityControl.valueGroupQC === 0 &&
            this.addQualityControl.group === null
          ) {
            this.showSnackbar({
              text: "Please check all fields requirements",
              color: "red",
              timeout: 2000
            });
          } else {
            if (this.addQualityControl.valueGroupQC === 0) {
              const payload = {
                name: this.toStartCase(this.addQualityControl.group)
              };

              await QualityControlService.saveQCGroupItem(payload).then(
                response => {
                  this.addQualityControl.valueGroupQC =
                    response.data.data.attributes.id;
                }
              );
            }

            this.showLoading({ text: "Saving..." });

            const payload_quality_control: any = {
              order_item_id: this.addQualityControl.order_item_id,
              note: this.addQualityControl.note,
              group: this.addQualityControl.valueGroupQC
            };

            QualityControlService.saveQCs(payload_quality_control)
              .then(response => {
                // Send Images
                if ((this.$refs as any).imageQC.getQueuedFiles().length > 0) {
                  this.addQualityControl.id_qc =
                    response.data.data.attributes.id;
                  this.showLoading({ text: "Uploading Images..." })(
                    this.$refs as any
                  )
                    .imageQC.setOption(
                      "url",
                      `${baseUrl}api/v2/quality-control/${this.addQualityControl.id_qc}/image`
                    )(this.$refs as any)
                    .imageQC.processQueue();
                } else this.done();
              })
              .catch(error => this.catchHandler(error));

            this.showSnackbar({
              text: "Saved Successfully!",
              color: "teal",
              timeout: 1500
            });
          }
        } else {
          this.showSnackbar({
            text: "Please check all fields requirements",
            color: "red",
            timeout: 2000
          });
        }
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  onNewAddGroupQC() {
    this.addQualityControl.valueGroupQC = this.addQualityControl.newQCGroup;

    if (this.addQualityControl.valueGroupQC === 0) {
      this.inputGroupItem = true;
    } else {
      this.inputGroupItem = false;
    }
  }

  async getQCGroup() {
    try {
      const group: any = {
        text: "Others...",
        value: 0
      };

      this.addQualityControl.groupQCItems.push(group);

      const response = await QualityControlService.getQCGroup();

      const groupQC = response.data.data;

      for (const dataGroupQC of groupQC) {
        const group: any = {
          text: dataGroupQC.attributes.name,
          value: dataGroupQC.attributes.id
        };
        this.addQualityControl.groupQCItems.push(group);
      }
    } catch (error) {
      this.catchHandler(error);
    }
  }

  // Quality Control Modal Closed
  onChangeQualityControlClosed() {
    this.$validator.errors.clear();
    this.$validator.reset();
    this.addQualityControl.selectedProduct = null;
    this.addQualityControl.group = null;
    this.addQualityControl.currentGroup = null;
    this.addQualityControl.valueGroupQC = null;
    this.addQualityControl.newQCGroup = null;
    this.addQualityControl.note = null;
    this.addQualityControl.order_item_id = null;
    this.dataQCs.items = [];
    this.addQualityControl.images = null;
    this.getQCGroup();
    this.inputGroupItem = false;
    this.addQualityControl.open = false;
  }

  // Delete Modal 1 QC (Per SKU)
  openDeleteQCModal(qc) {
    this.selectedQC = qc;
    this.selectedDeleteQC = qc;
    this.showDeleteQCModal = true;
  }

  // Delete All QC Modal (Per SKU)
  openDeleteAllQCModal(props) {
    this.addQualityControl.selectedIdOrderItem = props.item.id;
    this.showDeleteAllQCModal = true;
  }

  // Delete One QC (Per SKU)
  async deleteQC(props) {
    try {
      const payload = {
        id: [this.selectedQC.id_qc],
        props: [this.selectedDeleteQC.id],
        action: "delete"
      };

      this.showLoading({ text: "Saving..." });
      this.showDeleteQCModal = false;

      const response = await QualityControlService.deleteQCs(
        this.selectedQC.id_qc
      );
      this.showSnackbar({
        text: startCase(
          response.status === 200
            ? "Success"
            : "Failed with HTTP error: " + response.status
        ),
        color: response.status === 200 ? "green" : "red",
        timeout: 1500
      });
      this.onChangeQualityControlClosed();
      this.getQualityControlList();
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  // Delete All QC (Per SKU)
  async deleteAllQC(id) {
    try {
      const opts: any = {
        params: {
          "filter[order_item_id]": id,
          include: "images"
        }
      };

      const response = await QualityControlService.getQCs(opts);

      const responseData = response.data.data;

      forEach(responseData, dataQualityControl => {
        const quality_control = {
          productName: this.addQualityControl.productName,
          variants: this.addQualityControl.variants,
          id_qc: dataQualityControl.attributes.id,
          order_item_id: dataQualityControl.attributes.order_item_id,
          note: dataQualityControl.attributes.note,
          group: dataQualityControl.attributes.group,
          image: dataQualityControl.attributes.image,
          created_at: dataQualityControl.attributes.created_at
        };
        QualityControlService.deleteQCs(quality_control.id_qc);
      });

      this.showSnackbar({
        text: startCase(
          response.status === 200
            ? "Success"
            : "Failed with HTTP error: " + response.status
        ),
        color: response.status === 200 ? "green" : "red",
        timeout: 1500
      });

      this.getQualityControlList();
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.showDeleteAllQCModal = false;
    }
  }

  // Image Viewer
  getImageUrl(images: object[]): any {
    return map(images, "imageUrl");
  }
  $viewer: any;
  inited(viewer: any): void {
    // console.log('viewer',viewer)
    this.$viewer = viewer;
    this.setViewerAfterInit(viewer);
    // this.$viewer.show()
    // this.$setViewer.push(viewer)
  }

  setViewerAfterInit(value) {
    this.setViewer.push(value);
  }
  show(props: any): void {
    this.$viewer = this.setViewer[props.index];
    // this.inited()
    // this.$viewer = viewer
    // this.inited()
    this.$viewer.show();
    // if (props.item.images[0] !== "no image") this.$viewer.show()
    // else {
    //  this.showSnackbar({
    //    text: "Sorry, no image available for this item",
    //    color: "red",
    //    timeout: 2000
    //  })
    // }
  }

  error() {
    // this.loadingShow = false
    this.showSnackbar({
      text: "Error! Something Happened. Check your internet connection",
      color: "red",
      timeout: 4000
    });
  }

  done() {
    this.showSnackbar({
      text: "Quality Control Successfully Added",
      color: "green",
      timeout: 2000
    });
    this.closeLoading();
    this.onChangeQualityControlClosed();
    this.getOrderDetails();
    this.getQualityControlList();
  }

  onModalPackageImage(id) {
    this.packageId = null;

    this.modalPackageImages = true;
    this.packageId = id;

    forEach(this.dataImage, (images, index) => {
      let img = "";
      if (images.attributes.package_id == id) {
        img =
          "<img src='" +
          images.attributes.source +
          "' style='max-width:100% !importantvertical-align:middlemargin-bottom:10px' />";
        let mockFile: any = {
          name: `${images.attributes.id}_${
            images.attributes.source
              .split("/")
              .pop()
              .split("#")[0]
              .split("?")[0]
          }`,
          id: images.attributes.id,
          accepted: true,
          kind: "image",
          status: "fetched",
          type: "image/jpeg",
          upload: {
            filename: images.attributes.source
              .split("/")
              .pop()
              .split("#")[0]
              .split("?")[0]
          }
        };
        (this.$refs as any).imagesPackage.manuallyAddFile(
          mockFile,
          images.attributes.source,
          () => {},
          null,
          {
            dontSubstractMaxFiles: true,
            addToFiles: true
          }
        );
        (this.$refs as any).imagesPackage.dropzone.emit(
          "thumbnail",
          mockFile,
          images.attributes.source
        );
      }
    });
  }

  imageRemoved(file: any, error: any, xhr: any) {
    if (file.status === "fetched") this.packageImagesDeleted.push(file.id);
  }

  beforeSendingImageMultiplePackage(file, xhr, formData) {
    formData.append("images_for", this.packageId);
  }

  imageUploadMultipleSuccess_package(files, response) {
    this.done_packageImages();
  }

  async packageImagesDone() {
    if (!isEmpty(this.packageImagesDeleted)) {
      this.showLoading({ text: "Loading..." });
      let delData = [];
      forEach(this.packageImagesDeleted, (delImage: any) => {
        delData.push({
          type: "images",
          action: "delete",
          id: delImage
        });
      });

      const payload = {
        data: [
          {
            id: this.packageId,
            relationships: delData
          }
        ]
      };
      const response = await PackageService.deleteImagePackage(payload);
      if (response.data.success) {
        this.done_packageImages();
      }

      if ((this.$refs as any).imagesPackage.getQueuedFiles().length > 0) {
        this.showLoading({ text: "Uploading Images..." })(this.$refs as any)
          .imagesPackage.setOption(
            "url",
            `${baseUrl}api/v2/package/update-bulk`
          )(this.$refs as any)
          .imagesPackage.processQueue();
      }
    } else {
      if ((this.$refs as any).imagesPackage.getQueuedFiles().length > 0) {
        this.showLoading({ text: "Uploading Images..." })(this.$refs as any)
          .imagesPackage.setOption(
            "url",
            `${baseUrl}api/v2/package/update-bulk`
          )(this.$refs as any)
          .imagesPackage.processQueue();
      } else {
        (this.$refs as any).imagesPackage.dropzone
          .getAcceptedFiles()
          .forEach(file => {
            (this.$refs as any).imagesPackage.removeFile(file);
          });
      }
    }
    this.modalPackageImages = false;
  }

  done_packageImages() {
    this.showSnackbar({
      text: "Image Successfully Saved!",
      color: "green",
      timeout: 2000
    });
    this.closeLoading();
    window.location.reload();
  }

  reRenderPaymentHistory() {
    (this.$refs as any).paymentHistory.getPaymentHistory();
  }

  calculateCBMOrder(dimensions, dimension_unit) {
    const width = find(dimensions, dim => lowerCase(dim.label) === "width")
      .value;
    const height = find(dimensions, dim => lowerCase(dim.label) === "height")
      .value;
    const length = find(dimensions, dim => lowerCase(dim.label) === "length")
      .value;

    let dimens = 0;
    if (dimension_unit === "meters") {
      dimens = width * height * length;
    } else if (dimension_unit === "inchs") {
      dimens = (width / 39.37) * (height / 39.37) * (length / 39.37);
    } else if (dimension_unit === "cms") {
      dimens = (width / 100) * (height / 100) * (length / 100);
    }

    return dimens;
  }

  getEstimationWeight(data, weight, productId) {
    let ratioWeight = [];
    forEach(data.included.items, item => {
      ratioWeight.push({
        id: item.attributes.id,
        value:
          item.attributes.pivot.qty *
          item.attributes.item_rule.attributes.weight
      });
    });
    let totalWeight = sumBy(ratioWeight, "value");
    forEach(ratioWeight, (rw: any) => {
      rw.value = Number((rw.value / totalWeight).toFixed(2));
    });

    return ratioWeight.find(w => w.id == productId).value * weight;
  }

  getEstimationCBM(data, cbm, productId) {
    let ratioCbm = [];
    forEach(data.included.items, item => {
      ratioCbm.push({
        id: item.attributes.id,
        value:
          item.attributes.pivot.qty *
          (1 / item.attributes.item_rule.attributes.qty_cbm)
      });
    });
    let totalCbm = sumBy(ratioCbm, "value");
    forEach(ratioCbm, (rw: any) => {
      rw.value = Number((rw.value / totalCbm).toFixed(2));
    });

    return ratioCbm.find(w => w.id == productId).value * cbm;
  }

  currency_order: string = "";
  currency_origin: string = "";

  // sini
  async editCurrencyInfo() {
    // console.log('editCurrencyInfo')
    // get currency from this.currentOrder
    // console.log('this.currentOrder',this.currentOrder)
    // console.log('this.currentOrderDetails',this.currentOrderDetails)
    // console.log('currency_order',this.currentOrder.data.attributes.currency_order)
    // console.log('currency_origin',this.currentOrder.data.attributes.currency_origin)

    // if (this.currentOrder.data.attributes.currency_order === this.currentOrder.data.attributes.currency_origin) {
    //  const opts: any = {
    //    params: {
    //      'filter[currency][is]': this.currentOrder.data.attributes.currency_origin
    //    }
    //  }
    //  const response = await CountryService.getCountries(opts)
    // } else {
    //  const optsFrom: any = {
    //    params: {
    //      'filter[currency][is]': this.currentOrder.data.attributes.currency_origin
    //    }
    //  }
    //  const responseFrom = await CountryService.getCountries(optsFrom)

    //  const optsTo: any = {
    //    params: {
    //      'filter[currency][is]': this.currentOrder.data.attributes.currency_order
    //    }
    //  }
    //  const responseTo = await CountryService.getCountries(optsTo)
    // }

    // ini diisi ama get dari db`
    // const opts: any = {
    //   params: {
    //     'filter[currency][is]': "IDR"
    //   }
    // }
    // const response = await CountryService.getCountries(opts)
    // console.log('response', response)
    // goodsCurrencyItems
    // goodsCurrencyItems

    // pindahin saat di save
    // var theItems = this.currentOrderDetails.details
    // var arrItems = []
    // forEach(theItems, item => {
    //  var aTemp = {
    //    id: item.attributes.id
    //    cost: Number(item.attributes.original_price)*Number(this.currentOrderDetails.conversion)
    //  }
    //  arrItems.push(aTemp)
    // })

    // forEach(set_custom_charge, itms => {
    //  var aTemp = {
    //    "charge_detail_id": itms.attributes.id,
    //    "grand_total": itms.attributes.grand_total,
    //    "group": itms.attributes.group,
    //    "name": itms.attributes.name,
    //    "sub_total": itms.attributes.sub_total,
    //    "vat": itms.attributes.vat,
    //    "vat_value": itms.attributes.vat_value

    //  }
    //  payloadCustChargeDetail.push(aTemp)
    // })

    this.goodsCurrency = this.currentOrder.data.attributes.currency_origin;
    this.orderCurrency = this.currentOrder.data.attributes.currency_order;

    this.currency_order = this.currentOrder.data.attributes.currency_order;
    this.currency_origin = this.currentOrder.data.attributes.currency_origin;
    this.currencyInfoModal.open = true;
  }

  async editCurrencyInfoClose() {
    this.currencyInfoModal.open = false;
  }

  async editCurrencyInfoSave() {
    // yogiesetiarko
    // {
    //    "logistic_cost":5454085.25,
    //    "total_logistic_charges":1951250,
    //    "total_weight":152,
    //    "total_cbm":1,
    //    "total_duty":null,
    //    "total_wht":null,
    //    "total_vat":null,
    //    "total_custom_charges":null,
    //    "total_goods_value":11647000,
    //    "grand_total":17101085.25,
    //    "items":[
    //       {
    //          "original_price":31.32,
    //          "detail_id":29021,
    //          "quantity":100,
    //          "price":0,
    //          "cost":64950,
    //          "original_name":"2020睡衣女夏季新款仿真丝绸短袖卡通米奇宽松大码开衫家居服套装",
    //          "sku_status":{
    //             "id":211
    //          },
    //          "rule":{
    //             "weight":0.76,
    //             "weight_unit":"Kg",
    //             "total_weight":76,
    //             "total_tax":27.5,
    //             "qty_cbm":200,
    //             "hscodes":"42022100",
    //             "taxes":[
    //                {
    //                   "label":"BM",
    //                   "value":"7.5"
    //                },
    //                {
    //                   "label":"PPN",
    //                   "value":"10"
    //                },
    //                {
    //                   "label":"PPH",
    //                   "value":"10"
    //                }
    //             ]
    //          }
    //       }
    //    ]
    // }

    // console.log("BEFORE this.payloadCal",this.payloadCal)
    // console.log("BEFORE this.payloadCalRev",this.payloadCalRev)

    // no var
    // this.payloadCal.products[0].price_edit

    // with var
    // this.payloadCal.products[0].variants[0].price_edit

    try {
      this.showLoading({ text: "Saving..." });
      // yogiesetiarko
      // pada saat save edit item cost juga diganti nilainya = origin_price * this.conversion
      // console.log('this.currentOrderDetails.details',this.currentOrderDetails.details)
      // console.log('this.currentOrderDetails.conversion',this.currentOrderDetails.conversion)
      // console.log('this.payloadCal',this.payloadCal)

      var theItems = this.currentOrderDetails.details;
      var arrItems = [];
      var arrPriceEdit = [];
      forEach(theItems, item => {
        // old salah
        var theNewCost =
          Number(item.attributes.original_price) * Number(this.conversion);
        var theNewPriceCustomEdit =
          Number(item.attributes.original_price) * Number(this.user_conversion);

        // new salah
        // var theNewCost = Number(item.attributes.original_price)*Number(this.user_conversion)
        // var theNewPriceCustomEdit = Number(item.attributes.original_price)*Number(this.conversion)

        var aTemp = {
          detail_id: item.attributes.id,
          cost: theNewCost.toFixed(2)
        };

        var thePriceEdit = {
          price_edit: theNewCost.toFixed(2),
          price_custom_edit: theNewPriceCustomEdit.toFixed(2)
        };
        arrItems.push(aTemp);
        arrPriceEdit.push(thePriceEdit);
      });

      // console.log('arrItems',arrItems)
      // console.log('arrPriceEdit',arrPriceEdit)

      // console.log("this.payloadCal.products",this.payloadCal.products)

      forEach(this.payloadCal.products, (item, index) => {
        // console.log("index",index)
        // console.log("arrPriceEdit",arrPriceEdit[index])
        // console.log('item.hasOwnProperty("variants")',item.hasOwnProperty("variants"))
        // change the payload price_edit here
        if (item.hasOwnProperty("variants")) {
          //  // punya variant
          let newPrice = arrPriceEdit[index].price_edit;
          let newCustomPrice = arrPriceEdit[index].price_custom_edit;
          this.payloadCal.products[index].variants[0].price_edit = newPrice;
          this.payloadCal.products[
            index
          ].variants[0].price_custom_edit = newCustomPrice;
        } else {
          //  // tidak punya variant
          let newPrice = arrPriceEdit[index].price_edit;
          let newCustomPrice = arrPriceEdit[index].price_custom_edit;
          this.payloadCal.products[index].price_edit = newPrice;
          this.payloadCal.products[index].price_custom_edit = newCustomPrice;
        }
      });
      // console.log("this.payloadCal",this.payloadCal)
      // // // baseline
      this.payloadCal.currency_origin = this.goodsCurrency;
      // ini ganti
      const responseCalculation = await Calculate.calculateOrder(
        this.payloadCal
      );
      // console.log("responseCalculation.data.data",responseCalculation.data.data)

      // // // revision
      // forEach(this.payloadCalRev.products, item => {
      //  // console.log("item",item)
      //  // console.log('item.hasOwnProperty("variants")',item.hasOwnProperty("variants"))
      //  // change the payload price_edit here
      //  if(item.hasOwnProperty("variants")) {
      //  //  // punya variant
      //    let newPrice = this.payloadCal.products[0].variants[0].price_edit * 0
      //    this.payloadCal.products[0].variants[0].price_edit = newPrice
      //  } else {
      //  //  // tidak punya variant
      //    let newPrice = this.payloadCal.products[0].variants[0].price_edit * 0
      //    this.payloadCal.products[0].price_edit = newPrice
      //  }
      // })
      // const responseCalculationRev = await Calculate.calculateOrder(this.payloadCalRev)
      // console.log("responseCalculationRev",responseCalculationRev)

      var setPayload = {
        currency_origin: this.goodsCurrency,
        currency_order: this.orderCurrency,
        conversion: Number(this.conversion),
        user_conversion: Number(this.user_conversion),
        items: arrItems,
        total_goods_value: responseCalculation.data.data.total_goods_value.toFixed(
          2
        ),
        grand_total: responseCalculation.data.data.grand_total.toFixed(2),
        total_duty: responseCalculation.data.data.total_duty.toFixed(2),
        total_wht: responseCalculation.data.data.total_wht.toFixed(2),
        total_vat: responseCalculation.data.data.total_vat.toFixed(2),
        total_custom_charges: responseCalculation.data.data.total_custom_charge.toFixed(
          2
        )
      };

      // console.log('setPayload',setPayload)

      // updateOrderEdit
      var setParams = {};
      var returnResp = await OrdersService.updateOrderEdit(
        setPayload,
        setParams,
        this.$route.params.id
      );
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
      this.currencyInfoModal.open = false;
      this.getOrderDetails();
      this.showSnackbar({
        text: "Saved Successfully!",
        color: "teal",
        timeout: 1500
      });
    }
  }

  async editGeneralInfoOrderType() {
    // this.logisticModal.currentOrderType

    var order_type = this.currentOrderDetails.order_type;
    var order_category = this.currentOrderDetails.order_category;
    var terms = this.currentOrderDetails.terms;
    var load_type = this.currentOrderDetails.load_type;

    var insurance_value = this.currentOrderDetails.insurance_value;
    var freight_value = this.currentOrderDetails.freight_value;

    var insurance_value_order = this.currentOrderDetails.insurance_value_order;
    var freight_value_order = this.currentOrderDetails.freight_value_order;

    this.modalGeneralInfoOrderType.order_type = order_type;
    this.modalGeneralInfoOrderType.order_category = order_category;
    this.modalGeneralInfoOrderType.terms = terms;
    this.modalGeneralInfoOrderType.load_type = load_type;

    this.modalGeneralInfoOrderType.insurance_value = insurance_value;
    this.modalGeneralInfoOrderType.freight_value = freight_value;
    this.modalGeneralInfoOrderType.insurance_value_order = insurance_value_order;
    this.modalGeneralInfoOrderType.freight_value_order = freight_value_order;

    if (this.hasFreightInCustomChargeDetail) {
      this.modalGeneralInfoOrderType.insuranceInCustomChargeDetail = this.insuranceInCustomChargeDetail.attributes.sub_total;
    }

    if (this.hasInsuranceInCustomChargeDetail) {
      this.modalGeneralInfoOrderType.freightInCustomChargeDetail = this.freightInCustomChargeDetail.attributes.sub_total;
    }

    this.generalInfoOrderTypeModal.open = true;
    // console.log('editGeneralInfoOrderType')
  }

  async editGeneralInfoOrderTypeClose() {
    this.generalInfoOrderTypeModal.open = false;
    // this.getOrderDetails()
  }

  async editGeneralInfoOrderTypeSave() {
    // console.log('editGeneralInfoOrderTypeSave',this.currentOrderDetails)

    try {
      this.showLoading({ text: "Saving..." });
      // order_type
      // terms
      // load_type
      // insurance_value = insurance origin value
      // freight_value = freight origin value

      // calculation :
      // insurance_value => yang mata uang luar (currency_origin)
      // insurance_value_order => mata uang dalam (currency_order)
      // freight_value => yang mata uang luar (currency_origin)
      // freight_value_order => mata uang dalam (currency_order)

      // var a = this.
      // this.payloadCal
      // this.payloadCalRev

      // modalGeneralInfoOrderType.freight_value
      // modalGeneralInfoOrderType.freight_value_order

      // modalGeneralInfoOrderType.insurance_value
      // modalGeneralInfoOrderType.insurance_value_order

      let the_freight_value_order = 0;
      if (Number(this.modalGeneralInfoOrderType.freight_value_order) > 0) {
        the_freight_value_order = Number(
          this.modalGeneralInfoOrderType.freight_value_order
        );
        this.payloadCal.freight_value = Number(
          this.modalGeneralInfoOrderType.freight_value
        );
        this.payloadCal.freight_value_order = Number(the_freight_value_order);
      } else {
        this.payloadCal.freight_value = Number(
          this.modalGeneralInfoOrderType.freight_value
        );
        delete this.payloadCal.freight_value_order;
      }

      let the_insurance_value_order = 0;
      if (Number(this.modalGeneralInfoOrderType.insurance_value_order) > 0) {
        the_insurance_value_order = Number(
          this.modalGeneralInfoOrderType.insurance_value_order
        );
        this.payloadCal.insurance_value = Number(
          this.modalGeneralInfoOrderType.insurance_value
        );
        this.payloadCal.insurance_value_order = Number(
          the_insurance_value_order
        );
      } else {
        this.payloadCal.insurance_value = Number(
          this.modalGeneralInfoOrderType.insurance_value
        );
        delete this.payloadCal.insurance_value_order;
      }

      // console.log("this.payloadCal",this.payloadCal)

      // const responseCalculation = await Calculate.calculateOrder(payload_from_order)
      // ini ganti
      const responseCalculation = await Calculate.calculateOrder(
        this.payloadCal
      );
      // console.log("responseCalculation",responseCalculation)
      // this.responseCalculation = responseCalculation.data.data

      // total_csutom_charges
      // grand_total
      // total_duty
      // total_wht
      // total_vat

      // set payload for upload
      var setPayload = {
        // "pay_custom": this.pay_custom
        order_type: this.modalGeneralInfoOrderType.order_type,
        order_category: this.modalGeneralInfoOrderType.order_category,
        terms: this.modalGeneralInfoOrderType.terms,
        load_type: this.modalGeneralInfoOrderType.load_type,
        insurance_value: Number(this.modalGeneralInfoOrderType.insurance_value),
        insurance_value_order: Number(
          this.modalGeneralInfoOrderType.insurance_value_order
        ),
        freight_value: Number(this.modalGeneralInfoOrderType.freight_value),
        freight_value_order: Number(
          this.modalGeneralInfoOrderType.freight_value_order
        ),
        // "custom_charges": null
        total_custom_charges: responseCalculation.data.data.total_custom_charge,
        grand_total: responseCalculation.data.data.grand_total,
        total_duty: responseCalculation.data.data.total_duty,
        total_wht: responseCalculation.data.data.total_wht,
        total_vat: responseCalculation.data.data.total_vat
      };

      // var newInsuranceForCustormChargeDetail = {
      // }
      // var newFreightForCustormChargeDetail = {
      // }

      // var amountIns = 0
      // var amountFr = 0

      // var idIns = null
      // var idFr = null

      // var id_customCharge = this.currentOrder.data.relationships.customCharge.id
      // var set_custom_charge = this.currentOrder.included.customCharge[id_customCharge].attributes.custom_charge_detail

      // if (this.hasFreightInCustomChargeDetail) {
      //   idFr = this.freightInCustomChargeDetail.attributes.id
      //   // update nilai custom charge detail
      //   // update nilai amount
      //   this.freightInCustomChargeDetail.attributes.sub_total = this.modalGeneralInfoOrderType.freightInCustomChargeDetail

      //   amountFr = this.freightInCustomChargeDetail.attributes.sub_total

      // payloadCustChargeDetail.push(newFreightForCustormChargeDetail)
      // payloadCustChargeDetail.push(newInsuranceForCustormChargeDetail)
      //   set_custom_charge = set_custom_charge.filter(x => Number(x.id) !== idFr)

      //   newFreightForCustormChargeDetail = {
      //     "charge_detail_id": this.freightInCustomChargeDetail.attributes.id,
      //     "grand_total": this.freightInCustomChargeDetail.attributes.grand_total,
      //     "group": this.freightInCustomChargeDetail.attributes.group,
      //     "name": this.freightInCustomChargeDetail.attributes.name,
      //     "sub_total": this.freightInCustomChargeDetail.attributes.sub_total,
      //     "vat": this.freightInCustomChargeDetail.attributes.vat,
      //     "vat_value": this.freightInCustomChargeDetail.attributes.vat_value
      //   }

      // } else {
      //   // insert new nilai custom charge detail
      //   // nilai amount + ama freight baru
      //   newFreightForCustormChargeDetail = {
      //     "grand_total": 0,
      //     "group": "Insurance & Freight",
      //     "name": "Freight",
      //     "sub_total": Number(this.modalGeneralInfoOrderType.freightInCustomChargeDetail),
      //     "vat": 0,
      //     "vat_value": 0
      //   }
      //   amountFr = Number(this.modalGeneralInfoOrderType.freightInCustomChargeDetail)
      // }

      // if (this.hasInsuranceInCustomChargeDetail) {
      //   idIns = this.insuranceInCustomChargeDetail.attributes.id
      //   // update nilai custom charge detail
      //   // update nilai amount
      //   this.insuranceInCustomChargeDetail.attributes.sub_total = this.modalGeneralInfoOrderType.insuranceInCustomChargeDetail
      //   // newInsuranceForCustormChargeDetail = this.insuranceInCustomChargeDetail.attributes
      //   // newInsuranceForCustormChargeDetail.sub_total = 666
      //   amountIns = this.insuranceInCustomChargeDetail.attributes.sub_total

      //   set_custom_charge = set_custom_charge.filter(x => Number(x.id) !== idIns)

      //   // restruct payload for new insurance
      //   newInsuranceForCustormChargeDetail = {
      //     "charge_detail_id": this.insuranceInCustomChargeDetail.attributes.id,
      //     "grand_total": this.insuranceInCustomChargeDetail.attributes.grand_total,
      //     "group": this.insuranceInCustomChargeDetail.attributes.group,
      //     "name": this.insuranceInCustomChargeDetail.attributes.name,
      //     "sub_total": this.insuranceInCustomChargeDetail.attributes.sub_total,
      //     "vat": this.insuranceInCustomChargeDetail.attributes.vat,
      //     "vat_value": this.insuranceInCustomChargeDetail.attributes.vat_value
      //   }

      // } else {
      //   // insert new nilai custom charge detail
      //   // nilai amount + ama insurance baru
      //   newInsuranceForCustormChargeDetail = {
      //     "grand_total": 0,
      //     "group": "Insurance & Freight",
      //     "name": "Insurance",
      //     "sub_total": Number(this.modalGeneralInfoOrderType.insuranceInCustomChargeDetail),
      //     "vat": 0,
      //     "vat_value": 0
      //   }
      //   amountIns = Number(this.modalGeneralInfoOrderType.insuranceInCustomChargeDetail)
      // }

      // var payloadCustChargeDetail = []
      // forEach(set_custom_charge, itms => {
      //   var aTemp = {
      //     "charge_detail_id": itms.attributes.id,
      //     "grand_total": itms.attributes.grand_total,
      //     "group": itms.attributes.group,
      //     "name": itms.attributes.name,
      //     "sub_total": itms.attributes.sub_total,
      //     "vat": itms.attributes.vat,
      //     "vat_value": itms.attributes.vat_value

      //   }
      //   payloadCustChargeDetail.push(aTemp)
      // })

      // payloadCustChargeDetail.push(newFreightForCustormChargeDetail)
      // payloadCustChargeDetail.push(newInsuranceForCustormChargeDetail)

      // var newTotalAmount = Number(this.totalCustomChargeDetail) - Number(this.originalFreightCustomChargeDetail) - Number(this.originalInsuranceCustomChargeDetail) + Number(amountIns) + Number(amountFr)

      // setPayload.custom_charges = setPayloadCustChages
      // var setPayloadCustChages = {
      //   "amount": newTotalAmount,
      //   "details": payloadCustChargeDetail
      // }

      // setPayload.custom_charges = setPayloadCustChages

      // console.log('setPayload',setPayload)
      // console.log('this.totalCustomChargeDetail',this.totalCustomChargeDetail)
      // console.log('newTotalAmount',newTotalAmount)
      // console.log('setPayload',setPayload)

      // updateOrderEdit
      var setParams = {};
      var returnResp = await OrdersService.updateOrderEdit(
        setPayload,
        setParams,
        this.$route.params.id
      );
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
      this.generalInfoOrderTypeModal.open = false;
      this.getOrderDetails();
      this.showSnackbar({
        text: "Saved Successfully!",
        color: "teal",
        timeout: 1500
      });
    }
  }

  async editOrderCode() {
    this.orderCodeModal.open = true;
    // console.log("editOrderCode",this.currentOrderDetails)
    this.currentOrderCode = this.currentOrderDetails.order_code;
  }

  async closeOrderCode() {
    this.currentOrderCode = null;
    this.orderCodeModal.open = false;
  }

  async saveOrderCode() {
    // console.log("saveOrderCode")

    try {
      this.showLoading({ text: "Saving..." });

      var setPayload = {
        order_code: this.currentOrderCode
      };
      // console.log('setPayload',setPayload)

      // updateOrderEdit
      var setParams = {};
      var returnResp = await OrdersService.updateOrderEdit(
        setPayload,
        setParams,
        this.$route.params.id
      );
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
      this.orderCodeModal.open = false;
      this.getOrderDetails();
      this.showSnackbar({
        text: "Saved Successfully!",
        color: "teal",
        timeout: 1500
      });
    }

    this.orderCodeModal.open = false;
  }

  async editOrderDate() {
    this.orderDateModal.open = true;

    var str = this.currentOrderDetails.created_at;
    var res = str.split("T");

    this.dateCreated = res[0];
  }

  async closeOrderDate() {
    this.orderDateModal.open = false;
  }

  async saveOrderDate() {
    try {
      this.showLoading({ text: "Saving..." });

      // this.editPaidStatus.paidDate.date = this.toDatabaseDate(
      //  this.currentOrderDetails.paid_date
      // )
      // this.editPaidStatus.paidDate.dateFormatted = this.toFullDate(
      //  this.currentOrderDetails.paid_date
      // )

      // console.log('this.dateCreated',this.dateCreated)
      // console.log('this.currentOrderDetails.created_at',this.currentOrderDetails.created_at)

      var str = this.currentOrderDetails.created_at;
      // var n = str.indexOf("T")

      // var resDate = str.substr(0, n)
      // console.log('resDate',resDate)

      var resSplit = str.split("T");
      resSplit[0] = this.dateCreated;

      var timestampNow = Date.now();
      var t = new Date(timestampNow);
      var h = t.getHours();
      var m = t.getMinutes();
      // var s = t.getSeconds()

      // console.log('timestampNow',timestampNow)
      // console.log('t',t)
      // console.log('h',h)
      // console.log('m',m)
      // console.log('s',s)

      var setHourJoin = this.dateCreated + " " + h + ":" + "00" + ":" + "00";
      // console.log('setHourJoin',setHourJoin)

      // var dateSet = resSplit.join("T")
      // console.log('dateSet',dateSet)

      var setPayload = {
        created_at: setHourJoin
      };
      // console.log('setPayload',setPayload)

      // updateOrderEdit
      var setParams = {};
      var returnResp = await OrdersService.updateOrderEdit(
        setPayload,
        setParams,
        this.$route.params.id
      );
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
      this.orderDateModal.open = false;
      this.getOrderDetails();
      this.showSnackbar({
        text: "Saved Successfully!",
        color: "teal",
        timeout: 1500
      });
    }
  }

  async setDateCreated(dateProp) {
    this.dateCreated = dateProp;
    this.dateMenu = false;
  }

  async getCityById(id) {
    // console.log("getCityById", typeof id, id)
    if (Number(id) !== 0 || id !== null) {
      const response = await CityService.getOneCities(id);
      return response.data.data.attributes.name;
    } else {
      // console.log("else getCityById")
      return "-";
    }
  }

  onDelAgentModal() {
    this.delAgentModal = true;
  }

  async deleteAgent() {
    try {
      this.delAgentModal = false;
      this.showLoading({ text: "Deleting..." });
      const payload = {
        ordered_by_user_id: null
      };

      var setParams = {};
      var returnResp = await OrdersService.updateOrderEdit(
        payload,
        setParams,
        this.$route.params.id
      );

      this.showSnackbar({
        text: "Deleted Successfully!",
        color: "teal",
        timeout: 1500
      });
      this.getOrderDetails();
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  async getAllContact() {
    this.newAddress.contact.loading = true;
    try {
      const opts: any = {
        params: {
          "page[num]": 1,
          "page[limit]": 5,
          "filter[first_name][like]": ""
        }
      };
      if (!isEmpty(this.newAddress.contact.keyword)) {
        var textFilter = "filter[first_name][like]";
        opts.params[textFilter] = this.newAddress.contact.keyword;
      } else {
        var textFilter = "filter[first_name][like]";
        delete opts.params[textFilter];
      }
      const response = await UserSettingsService.getContactUser(
        this.currentOrderDetails.user_id,
        opts
      );
      const responseData = response.data.data;
      this.newAddress.contact.item = [];
      let check = false;
      for (const contact of responseData) {
        const item = {
          ...contact.attributes,
          name: contact.attributes.first_name
          // name: contact.attributes.first_name + " " + contact.attributes.last_name
        };
        this.newAddress.contact.item.push(item);
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.newAddress.contact.loading = false;
    }
  }

  onSelectedContact() {
    const selected = this.newAddress.contact.selected;
    this.newAddress.newFirstName = selected.first_name;
    this.newAddress.newLastName = selected.last_name;
    this.newAddress.newCountry = selected.country;
    this.newAddress.newProvince = selected.province;
    this.newAddress.newSubdistrict = selected.subdistrict;
    this.newAddress.newStreetAddress = selected.address;
    this.newAddress.newPostCode = selected.postcode;
    this.newAddress.newPhone = selected.phone;
    this.newAddress.newEmail = selected.email;
    this.newAddress.newCompany = selected.company;
    this.newAddress.newCity.text = selected.city_name;
    this.newAddress.newCity.value = selected.city_id;
  }

  /*ADDITIONAL INFO*/
  onModalOpenDropship() {
    this.updateAdditionalInfo = true;
    this.editAdditionalInfo.senderName = this.currentOrderDetails.order_infos.store_name;
    this.editAdditionalInfo.newNumberOrder = this.currentOrderDetails.order_infos.order_number;
    this.editAdditionalInfo.platforms.selected = this.currentOrderDetails.order_infos.platform;
  }

  cancelModalDropship() {
    this.updateAdditionalInfo = false;
  }

  async saveAdditionalModal() {
    try {
      const valid = await this.$validator.validateAll("editAdditionalInfo");
      if (valid) {
        this.showLoading({ text: "Saving..." });
        const payload = {
          order_infos: {
            billing: {
              address_1: this.currentOrderDetails.order_infos.billing.address_1,
              address_2: this.currentOrderDetails.order_infos.billing.address_2,
              city: this.currentOrderDetails.order_infos.billing.city,
              city_id: this.currentOrderDetails.order_infos.billing.city_id,
              company: this.currentOrderDetails.order_infos.billing.company,
              country: this.currentOrderDetails.order_infos.billing.country,
              email: this.currentOrderDetails.order_infos.billing.email,
              first_name: this.currentOrderDetails.order_infos.billing
                .first_name,
              last_name: this.currentOrderDetails.order_infos.billing.last_name,
              phone: this.currentOrderDetails.order_infos.billing.phone,
              postcode: this.currentOrderDetails.order_infos.billing.postcode,
              state: this.currentOrderDetails.order_infos.billing.state
            },
            shipping: {
              address_1: this.currentOrderDetails.order_infos.shipping
                .address_1,
              address_2: this.currentOrderDetails.order_infos.shipping
                .address_2,
              city: this.currentOrderDetails.order_infos.shipping.city,
              city_id: this.currentOrderDetails.order_infos.shipping.city_id,
              company: this.currentOrderDetails.order_infos.shipping.company,
              country: this.currentOrderDetails.order_infos.shipping.country,
              first_name: this.currentOrderDetails.order_infos.shipping
                .first_name,
              last_name: this.currentOrderDetails.order_infos.shipping
                .last_name,
              phone: this.currentOrderDetails.order_infos.shipping.phone,
              postcode: this.currentOrderDetails.order_infos.shipping.postcode,
              state: this.currentOrderDetails.order_infos.shipping.state,
              total_cbm: this.currentOrderDetails.order_infos.shipping
                .total_cbm,
              total_weight: this.currentOrderDetails.order_infos.shipping
                .total_weight
            },
            order_number: this.editAdditionalInfo.newNumberOrder,
            platform: this.editAdditionalInfo.platforms.selected,
            store_name: this.editAdditionalInfo.senderName
          }
        };
        var setParams = {};
        var returnResp = await OrdersService.updateOrderEdit(
          payload,
          setParams,
          this.$route.params.id
        );
        console.log(payload, "payload");
        this.showSnackbar({
          text: "Saved Successfully!",
          color: "teal",
          timeout: 1500
        });
        this.cancelModalDropship();
        this.getOrderDetails();
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }
  /*END*/

  /*
  ==============================
  DOCUMENTS LIST
  ==============================
  */
  openAddDoc() {
    this.addDocData = {
      name: null,
      document: null
    };
    this.addDocModal = true;
  }

  closeAddDoc() {
    (this.$refs as any).document.value = "";
    this.addDocModal = false;
  }

  async getDocuments() {
    try {
      const response = await OrdersService.getDocument(this.$route.params.id);
      this.documents = [];
      forEach(response.data, doc => {
        this.documents.push(doc.attributes);
      });
    } catch (error) {
      this.catchHandler(error);
    }
  }

  download(url) {
    window.open(url, "_blank");
  }

  changeDoc(e) {
    const file = e.target.files[0];
    if (file.size / 1024 < 10000) {
      this.addDocData.document = file;
    } else {
      this.addDocData.document = null;
      (this.$refs as any).document.value = "";
      this.showSnackbar({
        text: "File size exceeds 10 MB",
        color: "red",
        timeout: 2000
      });
    }
  }

  async addDocument() {
    try {
      this.showLoading({ text: "Saving..." });
      let configfile = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Bearer " + window.localStorage.getItem("access_token"),
          Identifier: "manage"
        }
      };
      let data = new FormData();
      data.append("document", this.addDocData.document);
      data.append("name", this.addDocData.name);

      await OrdersService.addDocument(this.$route.params.id, data, configfile);
      this.addDocModal = false;
      this.showSnackbar({
        text: "Saved Successfully!",
        color: "teal",
        timeout: 1500
      });
      this.getDocuments();
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  openDelDoc(id) {
    this.selectedDoc = id;
    this.delDocModal = true;
  }

  async deleteDocument() {
    try {
      this.showLoading({ text: "Saving..." });
      this.delDocModal = false;
      const response = await OrdersService.deleteDocument(this.selectedDoc);
      this.showSnackbar({
        text: startCase(
          response.status === 200
            ? "Success"
            : "Failed with HTTP error: " + response.status
        ),
        color: response.status === 200 ? "green" : "red",
        timeout: 1500
      });
      await this.getDocuments();
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }
  /*
  ==============================
  END DOCUMENTS LIST
  ==============================
  */

  /*
  ==============================
  ADD AND DELETE ORDER ITEMS
  ==============================
  */
  @Watch("remarkStatus")
  checkAddProduct() {
    const statuses = [
      "shipping",
      "shipping partially",
      "finished",
      "ready to ship"
    ];
    this.canAddProduct = true;
    for (let status of statuses) {
      if (lowerCase(this.remarkStatus) === status) {
        this.canAddProduct = false;
        break;
      }
    }
  }

  openAddProduct() {
    this.addProductData = {
      product: {
        loading: false,
        keyword: null,
        selected: null,
        items: []
      },
      variations: {
        getData: [],
        variants: [],
        headers: [],
        loading: false
      }
    };
    this.getProducts();
    this.addProductDialog = true;
  }

  async getProducts() {
    try {
      this.addProductData.product.loading = true;
      const opts = {
        params: {
          "page[num]": 1,
          "page[limit]": 10
        }
      };

      if (!isEmpty(this.addProductData.product.keyword)) {
        var textFilter = "filter[title][like]";
        opts.params[textFilter] = this.addProductData.product.keyword;
      } else {
        var textFilter = "filter[title][like]";
        delete opts.params[textFilter];
      }
      const response = await ProductService.getProductsLists(opts);
      this.addProductData.product.items = [];
      forEach(response.data, product => {
        const item = {
          ...product.attributes,
          total_qty: 0
        };
        this.addProductData.product.items.push(item);
      });

      if (this.addProductData.product.selected) {
        this.addProductData.product.items.push(
          this.addProductData.product.selected
        );
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.addProductData.product.loading = false;
    }
  }

  async getCurrency(from, to) {
    try {
      const optsCurrency: any = {
        params: {
          "filter[currency_code][is]": from,
          "filter[currency_code_target][is]": to
        }
      };
      const response = await CurrencyService.getCurrencies(optsCurrency);
      const responseData = response.data.data;
      return responseData[0].attributes.value;
    } catch (error) {
      this.catchHandler(error);
    }
  }

  async getChangeSuppliers(val) {
    const opts: any = {
      params: {
        "filter[id][is]": val
      }
    };

    // if (this.searchEditItemSupplier.length > 0) {
    //   opts.params = {
    //     ...opts.params,
    //     "filter[name][like]": this.searchEditItemSupplier
    //   }
    // } else {
    //   var textFilter = "filter[name][like]"
    //   delete opts.params[textFilter]
    // }

    const dataSupplier = await SupplierService.getSuppliers(opts);
    // console.log("getChangeSuppliers", dataSupplier)
    this.editProduct.selectedProduct.supplier_name =
      dataSupplier.data[0].attributes.name;
  }

  async getChangeItemCities(val) {
    const opts: any = {
      params: {
        // "filter[id][is]": val
        "filter[name][like]": val
      }
    };
    // this.theSelectCityName
    // console.log("getChangeItemCities", this.addCityData.keyword)

    const dataCities = await CityService.getCities(opts);
    // console.log("getChangeItemCities", dataCities)

    this.editProduct.selectedProduct.city_name =
      dataCities.data.data[0].attributes.name;
  }

  async getVariants() {
    try {
      this.addProductData.variations.loading = true;
      this.selectedBulkPrice = {};
      const opts: any = {
        params: {
          convert_to: this.currentOrderDetails.currency_order,
          include: "variations,bulk_prices"
        }
      };
      const response = await ProductService.getOneProduct(
        this.addProductData.product.selected.id,
        opts
      );
      const responseVariations = response.included.variations;
      const responsedata = response.data.attributes;
      const responseDataRelationships = response.data.relationships;
      const responseBulkPrices = response.included.bulk_prices;
      const responseWholesale = response.included.wholesale_prices;

      this.addProductData.variations.getData = [];

      // Bulk Price / Bundle Price
      let is_bulk = false;
      const bulkPricesData = [];
      if (responseBulkPrices.length != 0) {
        is_bulk = true;
        var rate_bulk = 0;
        if (
          this.addProductData.product.selected.original_currency !==
          this.currentOrderDetails.currency_order
        ) {
          rate_bulk = await this.getCurrency(
            this.addProductData.product.selected.original_currency,
            this.currentOrderDetails.currency_order
          );
        }
        forEach(responseBulkPrices, bulkPrice => {
          let dataBulk = {
            id: bulkPrice.attributes.id,
            product_id: bulkPrice.attributes.product_id,
            unit_name: bulkPrice.attributes.unit_name,
            quantity: bulkPrice.attributes.quantity,
            original_price: bulkPrice.attributes.price,
            price: rate_bulk
              ? bulkPrice.attributes.price * rate_bulk
              : bulkPrice.attributes.price,
            label:
              "1" +
              " " +
              upperCase(bulkPrice.attributes.unit_name) +
              " " +
              "(" +
              bulkPrice.attributes.quantity +
              " pcs)",
            unit_name_uppercase: upperCase(bulkPrice.attributes.unit_name),
            weight: bulkPrice.attributes.weight,
            weight_unit: bulkPrice.attributes.weight_unit
          };
          bulkPricesData.push(dataBulk);
        });
        this.selectedBulkPrice = bulkPricesData[0];
      }

      // check is wholesale
      var wholesale_prices: any = {};
      var is_wholesale = false;
      if (
        !isEmpty(responseDataRelationships.wholesale_prices) &&
        isEmpty(responseDataRelationships.bulk_prices)
      ) {
        is_wholesale = true;
        wholesale_prices =
          responseWholesale[responseDataRelationships.wholesale_prices[0].id]
            .attributes;
        if (
          this.addProductData.product.selected.original_currency !==
          this.currentOrderDetails.currency_order
        ) {
          const rate_wholesale = await this.getCurrency(
            this.addProductData.product.selected.original_currency,
            this.currentOrderDetails.currency_order
          );
          wholesale_prices.price = wholesale_prices.price * rate_wholesale;
        }
      }
      this.addProductData.product.selected = {
        ...this.addProductData.product.selected,
        is_wholesale: is_wholesale,
        wholesale_prices: wholesale_prices
      };

      // if null so the products has variants
      if (responsedata.variants == null) {
        forEach(responseDataRelationships.variations, the_var => {
          const dataTemp = responseVariations[the_var.id].attributes;

          const data = {
            ...dataTemp,
            weight: responsedata.weight,
            image_of_data: responsedata.image
          };
          this.addProductData.variations.getData.push(data);
        });
      } else {
        this.addProductData.variations.getData.push(responsedata.variants);
      }

      this.addProductData.variations.variants = [];
      for (const variation of this.addProductData.variations.getData) {
        const data = {
          variation_id: variation.id,
          price_per_item: !isEmpty(this.selectedBulkPrice)
            ? this.selectedBulkPrice.price
            : variation.regular_price,
          sku: variation.sku,
          stock: variation.quantity,
          quantity: 0,
          image: variation.image_url
            ? variation.image_url
            : variation.image_of_data,
          attributes: variation.options_values,
          bulk_data: bulkPricesData,
          is_bulk: is_bulk
        };
        this.addProductData.variations.variants.push(data);
      }
      this.addProductData.variations.headers = [];
      if (
        this.addProductData.variations.getData[0].options_values.length == "1"
      ) {
        this.addProductData.variations.headers = [
          {
            text: "Image",
            value: "image",
            sortable: false
          },
          {
            text: this.addProductData.variations.getData[0].options_values[0]
              .name,
            sortable: false,
            value: "option"
          },
          {
            text: "Stock",
            value: "stock",
            sortable: false
          },
          {
            text: "Product Cost",
            value: "price_per_item",
            sortable: false
          },
          {
            text: "Quantity",
            value: "quantity",
            sortable: false
          },
          {
            text: "Subtotal",
            value: "subtotal",
            sortable: false
          }
        ];
      } else {
        this.addProductData.variations.headers = [
          {
            text: "Image",
            value: "image",
            sortable: false
          },
          {
            text: this.addProductData.variations.getData[0].options_values[0]
              .name,
            sortable: false,
            value: "option"
          },
          {
            text: this.addProductData.variations.getData[0].options_values[1]
              .name,
            sortable: false,
            value: "option"
          },
          {
            text: "Stock",
            value: "stock",
            sortable: false
          },
          {
            text: "Product Cost",
            value: "price_per_item",
            sortable: false
          },
          {
            text: "Quantity",
            value: "quantity",
            sortable: false
          },
          {
            text: "Subtotal",
            value: "subtotal",
            sortable: false
          }
        ];
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.addProductData.variations.loading = false;
    }
  }

  // save quantity variant
  async saveQuantity(index) {
    // check stock
    if (
      this.addProductData.variations.variants[index].quantity >
      this.addProductData.variations.variants[index].stock
    ) {
      this.showSnackbar({
        text: "Quantity exceeds stock",
        color: "red",
        timeout: 2000
      });
      this.addProductData.variations.variants[index].quantity = 0;
    }
    var total_qty = 0;
    forEach(this.addProductData.variations.variants, variant => {
      // count total quantity product all variant
      total_qty += variant.quantity;

      // change price if product is bulk
      if (variant.is_bulk) {
        variant.price_per_item = this.selectedBulkPrice.price;
      }
    });
    this.addProductData.product.selected.total_qty = total_qty;
  }

  async addProductOrder() {
    try {
      const validationResponse = await this.$validator.validateAll(
        "productDialogScope"
      );
      if (validationResponse) {
        this.showLoading({ text: "Saving..." });
        let checkQty = false;
        let product_variant = {
          items: []
        };
        let new_item = {
          product_id: this.addProductData.product.selected.id,
          product_name: this.addProductData.product.selected.title,
          image_url: this.addProductData.product.selected.image
            ? this.addProductData.product.selected.image
            : this.viaPlaceholder,
          original_price: !isEmpty(this.selectedBulkPrice)
            ? this.selectedBulkPrice.original_price
            : this.addProductData.product.selected.regular_price,
          variants: [],
          currency: this.addProductData.product.selected.original_currency,
          base_unit_name: this.addProductData.product.selected.quantity_unit,
          unit_name: !isEmpty(this.selectedBulkPrice)
            ? this.selectedBulkPrice.unit_name
            : this.addProductData.product.selected.quantity_unit,
          quantity: 0,
          quantity_per_unit: 0,
          price: 0,
          cost: 0,
          original_name: this.addProductData.product.selected.original_title,
          weight: this.addProductData.product.selected.weight,
          sku_status: {
            id: null
          },
          rule: {
            weight_unit: null,
            total_weight: null,
            total_tax: null,
            qty_cbm: null,
            hscodes: null,
            taxes: [],
            weight: null
          }
        };

        let id_status = await this.getSkuStatusPending();
        var hscode = "";
        if (this.addProductData.product.selected.rules.category_rule) {
          hscode = await this.getHscodeProduct(
            this.addProductData.product.selected.rules.category_rule.id
          );
        }
        forEach(
          this.addProductData.variations.variants,
          async (variant, index) => {
            if (variant.quantity) {
              checkQty = true;

              const selectedProduct = this.addProductData.product.selected;
              if (
                selectedProduct.is_wholesale &&
                selectedProduct.total_qty >
                  selectedProduct.wholesale_prices.min_quantity
              ) {
                new_item.cost = selectedProduct.wholesale_prices.price;
              } else {
                new_item.cost = variant.price_per_item;
              }

              new_item.quantity = variant.quantity;
              (new_item.quantity_per_unit = !isEmpty(this.selectedBulkPrice)
                ? this.selectedBulkPrice.quantity
                : variant.quantity),
                (new_item.sku_status.id = id_status);
              new_item.variants = [...variant.attributes];

              // if category rules not null
              if (this.addProductData.product.selected.rules.category_rule) {
                var weight = 0;
                var weight_unit = "";
                var total_weight = 0;
                if (!isEmpty(this.selectedBulkPrice)) {
                  weight = this.selectedBulkPrice.weight;
                  weight_unit = this.selectedBulkPrice.weight_unit;
                  total_weight =
                    this.selectedBulkPrice.weight * variant.quantity;
                } else {
                  if (
                    this.addProductData.product.selected.rules.category_rule
                      .attributes.weight
                  ) {
                    weight = this.addProductData.product.selected.rules
                      .category_rule.attributes.weight;
                    weight_unit = this.addProductData.product.selected.rules
                      .category_rule.attributes.weight_unit;
                    total_weight =
                      this.addProductData.product.selected.rules.category_rule
                        .attributes.weight * variant.quantity;
                  } else {
                    weight = this.addProductData.product.selected.weight;
                    weight_unit = this.addProductData.product.selected
                      .weight_unit;
                    total_weight =
                      this.addProductData.product.selected.weight *
                      variant.quantity;
                  }
                }

                new_item.rule = {
                  weight_unit: weight_unit,
                  total_weight: total_weight,
                  total_tax: this.addProductData.product.selected.rules
                    .category_rule.attributes.total_tax
                    ? this.addProductData.product.selected.rules.category_rule
                        .attributes.total_tax
                    : 0,
                  qty_cbm: this.addProductData.product.selected.rules
                    .category_rule.attributes.qty_cbm
                    ? this.addProductData.product.selected.rules.category_rule
                        .attributes.cbm
                    : this.addProductData.product.selected.cbm,
                  hscodes: hscode,
                  taxes: [],
                  weight: weight
                };

                // if tax is not empty
                if (
                  !isEmpty(
                    this.addProductData.product.selected.rules.category_rule
                      .attributes.quick_tax_details
                  )
                ) {
                  new_item.rule.taxes = [
                    ...this.addProductData.product.selected.rules.category_rule
                      .attributes.quick_tax_details
                  ];
                } else {
                  new_item.rule.taxes = [
                    {
                      label: "BM",
                      value: 0
                    },
                    {
                      label: "PPN",
                      value: 0
                    },
                    {
                      label: "PPH",
                      value: 0
                    }
                  ];
                }
              } else {
                new_item.rule = {
                  weight_unit: this.addProductData.product.selected.weight_unit,
                  total_weight:
                    this.addProductData.product.selected.weight *
                    variant.quantity,
                  total_tax: 0,
                  qty_cbm: this.addProductData.product.selected.cbm,
                  hscodes: hscode,
                  weight: this.addProductData.product.selected.weight,
                  taxes: [
                    {
                      label: "BM",
                      value: 0
                    },
                    {
                      label: "PPN",
                      value: 0
                    },
                    {
                      label: "PPH",
                      value: 0
                    }
                  ]
                };
              }
              product_variant.items.push(new_item);
            }
          }
        );

        if (checkQty) {
          this.addProductDialog = false;

          // add items
          const response = await OrdersService.addItems(
            this.$route.params.id,
            product_variant
          );

          // calculate order
          var payload = {
            items: []
          };
          forEach(this.tableItems, item => {
            const variant = {
              product_id: item.product_id,
              product_name: item.product_name,
              image_url: item.image_url,
              original_price: item.original_price,
              variants: item.variants,
              currency: item.currency,
              base_unit_name: item.base_unit_name,
              detail_id: item.id,
              quantity: item.quantity,
              price: item.price,
              cost: item.cost,
              original_name: item.original_name,
              sku_status: {
                id: item.item_status[0].attributes.id
              },
              rule: item.rule.attributes
            };
            payload.items.push(variant);
          });
          this.payloadCal.product = [
            ...product_variant.items,
            ...payload.items
          ];
          const resCalculate = await Calculate.calculateOrder(this.payloadCal);

          this.showSnackbar({
            text: "Saved successfully",
            color: "green",
            timeout: 3500
          });
          this.getOrderDetails();
        } else {
          this.showSnackbar({
            text: "Please add quantity of product variant(s)",
            color: "red",
            timeout: 3500
          });
        }
      } else {
        this.showSnackbar({
          text: "Please select a product",
          color: "red",
          timeout: 3500
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  openDeleteProduct(id) {
    this.selectedDelProduct = id;

    this.deleteProductDialog = true;
  }

  async deleteProduct() {
    try {
      this.showLoading({ text: "Deleting..." });
      this.deleteProductDialog = false;

      // delete items
      const payload_del = {
        item_ids: [this.selectedDelProduct]
      };
      const response = await OrdersService.deleteItems(
        this.$route.params.id,
        payload_del
      );
      // end delete items

      // calculate order
      let index = findIndex(this.tableItems, { id: this.selectedDelProduct });
      let items_deleted = [...this.tableItems];
      items_deleted.splice(index, 1);
      var payload = {
        items: []
      };
      forEach(items_deleted, item => {
        const variant = {
          product_id: item.product_id,
          product_name: item.product_name,
          image_url: item.image_url,
          original_price: item.original_price,
          variants: item.variants,
          currency: item.currency,
          base_unit_name: item.base_unit_name,
          detail_id: item.id,
          quantity: item.quantity,
          price: item.price,
          cost: item.cost,
          original_name: item.original_name,
          sku_status: {
            id: item.item_status[0].attributes.id
          },
          rule: item.rule.attributes
        };
        payload.items.push(variant);
      });

      this.payloadCal.product = [...payload.items];
      // ini ganti
      const resCalculate = await Calculate.calculateOrder(this.payloadCal);
      // end calculate order

      this.showSnackbar({
        text: "Deleted successfully",
        color: "green",
        timeout: 3500
      });
      this.getOrderDetails();
      this.getQualityControlList();
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  async getSkuStatusPending() {
    const opts = {
      params: {
        "filter[status_type][is]": 4,
        "filter[name][like]": "pending"
      }
    };
    const response = await StatusService.getOrderStatus(opts);
    return response.data[0].attributes.id;
  }

  async getHscodeProduct(id) {
    const response = await CategoryRules.getCategoryRulesById(id);
    const hscodes = response.data.data.relationships.hscodes;
    var hscode_code = "";
    for (const hscode of hscodes) {
      var hscode_included = response.data.included.hscodes[hscode.id];
      if (
        hscode_included.attributes.country_id ===
        this.currentOrderDetails.shipment_to
      ) {
        hscode_code = hscode_included.attributes.code;
        break;
      }
    }
    return hscode_code;
  }
  /*
  ==============================
  END ADD AND DELETE ORDER ITEMS
  ==============================
  */

  // Payment Methods
  async getPaymentChannelOrder(data) {
    this.dataPaymentChannel = [];
    this.paymentMethods.payment_channel_ids = [];
    this.paymentMethods.instruction = null;

    forEach(data.channels, async channel => {
      const responseChannel = await PaymentChannelsService.getOnePaymentChannel(
        channel.attributes.payment_channel_id
      );
      const responseChannelData = responseChannel.data.data;
      this.paymentMethods.payment_channel_ids.push(
        responseChannelData.attributes.id
      );
      this.paymentMethods.instruction = channel.attributes.instruction;
      this.dataPaymentChannel.push(responseChannelData.attributes.gateway);
    });
  }

  onEditPaymentMethods() {
    this.getPaymentChannelOrder(this.currentOrderDetailsIncluded);
    this.paymentMethodsModal = true;
  }

  closePaymentMethodsModal() {
    this.paymentMethods = [];
    this.$validator.errors.clear();
    this.$validator.reset();
    this.paymentMethodsModal = false;
  }

  async getPaymentChannelsList() {
    try {
      const opts: any = {
        params: {
          "page[limit]": 10000
        }
      };
      const response = await PaymentChannelsService.getPaymentChannels();
      const responseData = response.data.data;

      for (const dataPaymentChannels of responseData) {
        this.paymentChannelsList.push(dataPaymentChannels.attributes);
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
    }
  }

  async savePaymentChannel() {
    try {
      const valid = await this.$validator.validateAll("paymentMethods");

      if (valid) {
        this.showLoading({ text: "Saving..." });

        const payload = {
          channels: {
            payment_channel_ids: this.paymentMethods.payment_channel_ids,
            instruction: this.paymentMethods.instruction
          }
        };

        const setParams = {};
        const response = await OrdersService.updateOrderEdit(
          payload,
          setParams,
          this.$route.params.id
        );

        if (response.data.success) {
          this.showSnackbar({
            text: "Payment Methods Successfully Added",
            color: "green",
            timeout: 1500
          });
          this.closeLoading();
          this.closePaymentMethodsModal();
          this.getOrderDetails();
        } else {
          this.showSnackbar({
            text: "Saved Failed",
            color: "red",
            timeout: 1500
          });
        }
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
    }
  }
}
