import BaseService from '@/services/BaseService'
// import orderBy = require('lodash/orderBy');

class PaymentHistoryService extends BaseService {
  constructor() {
    super()
  }

  async setPaymentHistory(data: any, params = {}) {
    this.endPoint = 'api/v2/payments'
    return await this.post(data, params)
  }

  updatePaymentHistory(id: any, data: any, params = {}) {
    this.endPoint = 'api/v2/payments'
    return this.putOne(id, data, params).then(response => {
      return response.data
    })
  }

  deletePaymentHistory(id: any, params = {}) {
    this.endPoint = 'api/v2/payments'
    return this.delete(id, params).then(response => {
      return response
    })
  }
}

export default new PaymentHistoryService()