import BaseService from '@/services/BaseService'

class Categories extends BaseService {
  constructor() {
    super()
  }
  async calculateOrder(data = {}) {
    this.endPoint = 'api/v2/calculation'
    return this.post(data)
  }
}
export default new Categories()
