import BaseService from '@/services/BaseService'
// import orderBy = require('lodash/orderBy');

class ProofOfPaymentService extends BaseService {
  constructor() {
    super()
  }

  async getPaymentProof(params = {}) {
    this.endPoint = 'api/v2/payment-confirmation'
    return this.get(params).then(response => {
      return response.data
    })
  }

  updatePaymentProof(id: any, data: any, params = {}) {
    this.endPoint = `api/v2/payment-confirmation/${id}`
    return this.post(data, params).then(response => {
      return response.data
    })
  }

  deletePaymentProof(id: any, params = {}) {
    this.endPoint = 'api/v2/payment-confirmation'
    return this.delete(id, params).then(response => {
      return response
    })
  }
}

export default new ProofOfPaymentService()