


























































































import BasePage from '@/pages/Base'
import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import OrdersService from '@/services/Orders'
import forEach from 'lodash/forEach'
import Calculate from '@/services/Calculate'
import OrderFunctions from '@/pages/Orders/OrderBaseFunctions'

@Component
export default class currencyInfo extends BasePage {

  constructor() {
    super()
  }


  // emit
  @Emit('refreshData')
  getOrderDetails() {}

  // props
  @Prop() orderData: any
  @Prop() calculationData: object
  // for payload calculation
  @Prop()
  currentPayloadCal: any
  // for payload calculation revised
  @Prop()
  currentPayloadCalRev: any


  // data
  currencyInfoModal: any = {
    open: false
  }
  orderCurrency: string = 'USD'
  goodsCurrency: string = 'IDR'
  goodsCurrencyItems: any = ['IDR', 'USD', 'THB', 'SGD', 'PHP', 'CNY', 'AUD']
  orderTypeTerms: any = [
    'EXW',
    'FCA',
    'FAS',
    'FOB',
    'CIF',
    'CNF(CFR)',
    'DDP',
    'DDU'
  ]
  responseOrder: any = {}
  currentOrder: any = {}
  conversion: number = 0
  user_conversion: number = 0
  orderTypeLoad: any = ['FCL', 'LTL', 'FTL', 'LCL']
  pay_custom: boolean = false
  pay_via_ali: boolean = false
  currentOrderDetails: any = {}
  currency_order: string = ''
  currency_origin: string = ''

  // methods
  async editCurrencyInfo() {
    this.goodsCurrency = this.orderData.currency_origin
    this.orderCurrency = this.orderData.currency_order
    this.currencyInfoModal.open = true
  }

  async editCurrencyInfoClose() {
    this.currencyInfoModal.open = false
  }

  async editCurrencyInfoSave() {
    try {
      this.showLoading({ text: 'Saving...' })

      var theItems = this.orderData.details
      var arrItems = []
      var arrPriceEdit = []
      forEach(theItems, item => {
        var theNewCost =
          Number(item.attributes.original_price) * Number(this.orderData.conversion)
        var theNewPriceCustomEdit =
          Number(item.attributes.original_price) * Number(this.orderData.user_conversion)

        var aTemp = {
          detail_id: item.attributes.id,
          cost: theNewCost.toFixed(2)
        }

        var thePriceEdit = {
          price_edit: theNewCost.toFixed(2),
          price_custom_edit: theNewPriceCustomEdit.toFixed(2)
        }
        arrItems.push(aTemp)
        arrPriceEdit.push(thePriceEdit)
      })

      forEach(this.currentPayloadCal.products, (item, index) => {
        if (item.hasOwnProperty('variants')) {
          //  // punya variant
          let newPrice = arrPriceEdit[index].price_edit
          let newCustomPrice = arrPriceEdit[index].price_custom_edit
          this.currentPayloadCal.products[index].variants[0].price_edit = newPrice
          this.currentPayloadCal.products[
            index
          ].variants[0].price_custom_edit = newCustomPrice
        } else {
          let newPrice = arrPriceEdit[index].price_edit
          let newCustomPrice = arrPriceEdit[index].price_custom_edit
          this.currentPayloadCal.products[index].price_edit = newPrice
          this.currentPayloadCal.products[index].price_custom_edit = newCustomPrice
        }
      })

      this.currentPayloadCal.currency_origin = this.goodsCurrency
      // ini ganti
      const responseCalculation = await Calculate.calculateOrder(
        this.currentPayloadCal
      )

      var setPayload = {
        currency_origin: this.orderData.currency_origin,
        currency_order: this.orderData.currency_order,
        conversion: Number(this.orderData.conversion),
        user_conversion: Number(this.orderData.user_conversion),
        items: arrItems,
        total_goods_value: responseCalculation.data.data.total_goods_value.toFixed(
          2
        ),
        grand_total: responseCalculation.data.data.grand_total.toFixed(2),
        total_duty: responseCalculation.data.data.total_duty.toFixed(2),
        total_wht: responseCalculation.data.data.total_wht.toFixed(2),
        total_vat: responseCalculation.data.data.total_vat.toFixed(2),
        total_custom_charges: responseCalculation.data.data.total_custom_charge.toFixed(
          2
        )
      }
      var setParams = {}
      var returnResp = await OrdersService.updateOrderEdit(
        setPayload,
        setParams,
        this.$route.params.id
      )
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
      this.currencyInfoModal.open = false
      this.getOrderDetails()
      this.showSnackbar({
        text: 'Saved Successfully!',
        color: 'teal',
        timeout: 1500
      })
    }
  }
  async currencyData() {
    const value = await OrderFunctions.checkFunction()
    console.log(value)
  }
}
