import BaseService from '@/services/BaseService'
import Calculate from '@/services/Calculate'
import forEach from 'lodash/forEach'
import find from 'lodash/find'

class OrderBaseFunctions extends BaseService {
  constructor() {
    super()
  }
  async checkFunction() {
    // console.log()
    // this.endPoint = 'api/v2/calculation'
    // return this.post(data)
    return 'from checkFunction'
  }

  async setPayloadCalculationItem(newEdit, payload_edit_product, data, itemId) {

    const response = data
    var payload_from_order: any = {
      'order_id': response.data.attributes.id,
      'type': 'manage-order',
      'ship_from': response.data.attributes.shipment_from,
      'ship_to': response.data.attributes.shipment_to,
      'shipping_method': response.data.attributes.shipment_method,
      'currency_origin': response.data.attributes.currency_origin,
      'currency_order': response.data.attributes.currency_order,
      'custom_conversion': response.data.attributes.conversion,
      'goods_conversion': response.data.attributes.user_conversion,
      'logistic_per_unit': response.data.attributes.logistic_per_unit,
      'pay_custom': response.data.attributes.pay_custom,
      'insurance_value': response.data.attributes.insurance_value,
      'freight_value': response.data.attributes.freight_value,
      'insurance_value_order': response.data.attributes.insurance_value_order,
      'freight_value_order': response.data.attributes.freight_value_order,
      'items': [],
      'customized_logistic_charge': []
    }

    const custId = response.data.relationships.customCharge.id
    let arr_custom_charge_detail = response.included.customCharge[custId].attributes.custom_charge_detail

    forEach(arr_custom_charge_detail, custom_charge => {
      const newCustomized = {
        'group': custom_charge.attributes.group,
        'name': custom_charge.attributes.name,
        'vat': custom_charge.attributes.vat,
        'total': custom_charge.attributes.sub_total
      }
      payload_from_order.customized_logistic_charge.push(newCustomized)
    })

    forEach(response.data.attributes.details, detail => {
      let newItem = {}
      if (Number(detail.id) === Number(itemId)) {
        let newQty = newEdit.attributes.quantity
        if (newEdit.attributes.revised_quantity !== null) {
          newQty = newEdit.attributes.revised_quantity
        }
        newItem = {
          'id': newEdit.id,
          'product_name': newEdit.attributes.product_name,
          'quantity': newQty,
          'origin_price': newEdit.attributes.original_price,
          'rules': [
            {
              'label': 'weight',
              'value': newEdit.attributes.item_rule.attributes.weight
            },
            {
              'label': 'qty_per_cbm',
              'value': newEdit.attributes.item_rule.attributes.qty_cbm
            },
            {
              'label': 'BM',
              'value': newEdit.attributes.item_rule.attributes.taxes[0].value
            },
            {
              'label': 'PPN',
              'value': newEdit.attributes.item_rule.attributes.taxes[1].value
            },
            {
              'label': 'PPH',
              'value': newEdit.attributes.item_rule.attributes.taxes[2].value
            },
            {
              'label': 'hscodes',
              'value': newEdit.attributes.item_rule.attributes.hscodes
            }
          ]
        }
      } else {
        let newQty = newEdit.attributes.quantity
        if (newEdit.attributes.revised_quantity !== null) {
          newQty = newEdit.attributes.revised_quantity
        }
        newItem = {
          'id': detail.id,
          'product_name': detail.attributes.product_name,
          'quantity': newQty,
          'origin_price': detail.attributes.original_price,
          'rules': [
            {
              'label': 'weight',
              'value': detail.attributes.item_rule.attributes.weight
            },
            {
              'label': 'qty_per_cbm',
              'value': detail.attributes.item_rule.attributes.qty_cbm
            },
            {
              'label': 'BM',
              'value': detail.attributes.item_rule.attributes.taxes[0].value
            },
            {
              'label': 'PPN',
              'value': detail.attributes.item_rule.attributes.taxes[2].value
            },
            {
              'label': 'PPH',
              'value': detail.attributes.item_rule.attributes.taxes[1].value
            },
            {
              'label': 'hscodes',
              'value': detail.attributes.item_rule.attributes.hscodes
            }
          ]
        }
      }
      payload_from_order.items.push(newItem)
    })

    const newResponseCalculation = await Calculate.calculateOrder(
      payload_from_order
    )
    // console.log("newResponseCalculation", newResponseCalculation)
    return newResponseCalculation
  }

  async setPayloadCalculation(data) {
    console.log('setPayloadCalculation data', data)
    //   // console.log("setPayloadCalculation data.data.attributes.details", data.data.attributes.details)
    const response = data
    //   // console.log("setPayloadCalculation response", response)
    //   // const details = data.data.attributes.details
    var payload_from_order: any = {
      'order_id': response.data.attributes.id,
      'type': 'manage-order',
      'ship_from': response.data.attributes.shipment_from,
      'ship_to': response.data.attributes.shipment_to,
      'shipping_method': response.data.attributes.shipment_method,
      'currency_origin': response.data.attributes.currency_origin,
      'currency_order': response.data.attributes.currency_order,
      'custom_conversion': response.data.attributes.conversion,
      'goods_conversion': response.data.attributes.user_conversion,
      'logistic_per_unit': response.data.attributes.logistic_per_unit,
      'pay_custom': response.data.attributes.pay_custom,

      // "currency_order": setCurrencyOrder,
      // "currency_origin": setCurrencyOrigin,
      // "weight_measurement": "kg",
      'insurance_value': response.data.attributes.insurance_value,
      'freight_value': response.data.attributes.freight_value,
      'insurance_value_order': response.data.attributes.insurance_value_order,
      'freight_value_order': response.data.attributes.freight_value_order,
      // "order_type": this.currentOrderDetails.order_type,
      'items': [],
      'customized_logistic_charge': []
      // "customized_logistic_charge": [
      //   {
      //     "group": "Handling Charge",
      //     "name": "Handling Charge",
      //     "vat": 0,
      //     "total": 0
      //   },
      // ],


      // "custom_logistic_charges": custom_logistic_charges,
      // "conversion_rate": this.currentOrderDetails.user_conversion,
      // "user_conversion": this.currentOrderDetails.conversion
      // conversion_rate: this.currentOrderDetails.conversion,
      // user_conversion: this.currentOrderDetails.user_conversion
    }
    // // detail.attributes.item_rule.attributes.taxes
    // // set customized_logistic_charge
    // // console.log("response.data.relationships.customCharge",response.data.relationships.customCharge.id)
    const custId = response.data.relationships.customCharge.id
    let arr_custom_charge_detail = response.included.customCharge[custId].attributes.custom_charge_detail
    forEach(arr_custom_charge_detail, custom_charge => {
      const newCustomized = {
        'group': custom_charge.attributes.group,
        'name': custom_charge.attributes.name,
        'vat': custom_charge.attributes.vat,
        'total': custom_charge.attributes.sub_total
      }
      payload_from_order.customized_logistic_charge.push(newCustomized)
    })

    //   // // // set items
    forEach(response.data.attributes.details, detail => {
      const newItem = {
        'id': detail.id,
        'product_name': detail.attributes.product_name,
        'quantity': detail.attributes.quantity,
        'origin_price': detail.attributes.original_price,
        'rules': [
          {
            'label': 'weight',
            'value': detail.attributes.item_rule.attributes.weight
          },
          {
            'label': 'qty_per_cbm',
            'value': detail.attributes.item_rule.attributes.qty_cbm
          },
          {
            'label': 'BM',
            'value': detail.attributes.item_rule.attributes.taxes[0].value
          },
          {
            'label': 'PPN',
            'value': detail.attributes.item_rule.attributes.taxes[2].value
          },
          {
            'label': 'PPH',
            'value': detail.attributes.item_rule.attributes.taxes[1].value
          },
          {
            'label': 'hscodes',
            'value': detail.attributes.item_rule.attributes.hscodes
          }
        ]
      }
      payload_from_order.items.push(newItem)
      // var set_charge = {
      //   type: "custom-optional",
      //   group: charge.attributes.name,
      //   name: charge.attributes.name,
      //   price: charge.attributes.sub_total,
      //   // vat dihilangkan dulu
      //   // "vat": charge.attributes.vat
      //   vat: 0
      // }
      // custom_logistic_charges.push(set_charge)
    })
    console.log('XXXXX setPayloadCalculation payload_from_order XXXXX', payload_from_order)
    const newResponseCalculation = await Calculate.calculateOrder(
      payload_from_order
    )
    console.log('XXXXX newResponseCalculation XXXXX', newResponseCalculation)
    //   // // setPayloadCalculationRevised
    return newResponseCalculation.data
    // return "setPayloadCalculation"
  }

  async setPayloadCalculationRevised(dataRevised) {
    // console.log("setPayloadCalculationRevised", dataRevised)
    const response = dataRevised
    // console.log("setPayloadCalculationRevised response", response)
    var payload_from_order_revised: any = {
      'order_id': response.data.attributes.id,
      'type': 'manage-order',
      'ship_from': response.data.attributes.shipment_from,
      'ship_to': response.data.attributes.shipment_to,
      'shipping_method': response.data.attributes.shipment_method,
      'currency_origin': response.data.attributes.currency_origin,
      'currency_order': response.data.attributes.currency_order,
      'custom_conversion': response.data.attributes.conversion,
      'goods_conversion': response.data.attributes.user_conversion,
      'logistic_per_unit': response.data.attributes.logistic_per_unit,
      'pay_custom': response.data.attributes.pay_custom,

      // "currency_order": setCurrencyOrder,
      // "currency_origin": setCurrencyOrigin,
      // "weight_measurement": "kg",
      'insurance_value': response.data.attributes.insurance_value,
      'freight_value': response.data.attributes.freight_value,
      'insurance_value_order': response.data.attributes.insurance_value_order,
      'freight_value_order': response.data.attributes.freight_value_order,
      // "order_type": this.currentOrderDetails.order_type,
      'items': [],
      'customized_logistic_charge': []
      // "customized_logistic_charge": [
      //   {
      //     "group": "Handling Charge",
      //     "name": "Handling Charge",
      //     "vat": 0,
      //     "total": 0
      //   },
      // ],


      // "custom_logistic_charges": custom_logistic_charges,
      // "conversion_rate": this.currentOrderDetails.user_conversion,
      // "user_conversion": this.currentOrderDetails.conversion
      // conversion_rate: this.currentOrderDetails.conversion,
      // user_conversion: this.currentOrderDetails.user_conversion
    }

    //  revised
    forEach(response.data.attributes.details, detail => {
      console.log('detail', detail)
      let theQty = detail.attributes.quantity
      if (detail.attributes.revised_quantity !== null) {
        theQty = detail.attributes.revised_quantity
      }

      let bm_val = find(detail.attributes.item_rule.attributes.taxes, function(o) { return o.label.toUpperCase() === 'BM' })
      let ppn_val = find(detail.attributes.item_rule.attributes.taxes, function(o) { return o.label.toUpperCase() === 'PPN' })
      let pph_val =find(detail.attributes.item_rule.attributes.taxes, function(o) { return o.label.toUpperCase() === 'PPH' })

      // console.log("bm_val", bm_val)
      // console.log("ppn_val", ppn_val)
      // console.log("pph_val", pph_val)

      // detail.attributes.item_rule.attributes.taxes


      const newItem = {
        'id': detail.attributes.id,
        'product_name': detail.attributes.product_name,
        'quantity': theQty,
        'origin_price': detail.attributes.original_price,
        'rules': [
          {
            'label': 'weight',
            'value': detail.attributes.item_rule.attributes.weight
          },
          {
            'label': 'qty_per_cbm',
            'value': detail.attributes.item_rule.attributes.qty_cbm
          },
          {
            'label': 'BM',
            'value': bm_val.value
          },
          {
            'label': 'PPN',
            'value': ppn_val.value
          },
          {
            'label': 'PPH',
            'value': pph_val.value
          },
          {
            'label': 'hscodes',
            'value': detail.attributes.item_rule.attributes.hscodes
          }
        ]
      }
      payload_from_order_revised.items.push(newItem)
      // var set_charge = {
      //   type: "custom-optional",
      //   group: charge.attributes.name,
      //   name: charge.attributes.name,
      //   price: charge.attributes.sub_total,
      //   // vat dihilangkan dulu
      //   // "vat": charge.attributes.vat
      //   vat: 0
      // }
      // custom_logistic_charges.push(set_charge)
    })

    const custId = response.data.relationships.customCharge.id
    let arr_custom_charge_detail = response.included.customCharge[custId].attributes.custom_charge_detail
    forEach(arr_custom_charge_detail, custom_charge => {
      const newCustomized = {
        'group': custom_charge.attributes.group,
        'name': custom_charge.attributes.name,
        'vat': custom_charge.attributes.vat,
        'total': custom_charge.attributes.sub_total
      }
      payload_from_order_revised.customized_logistic_charge.push(newCustomized)
    })

    console.log('payload_from_order_revised', payload_from_order_revised)

    const newResponseCalculationRevised = await Calculate.calculateOrder(
      payload_from_order_revised
    )
    console.log('XXXXX newResponseCalculationRevised XXXXX', newResponseCalculationRevised)

    // return "setPayloadCalculationRevised"
    return newResponseCalculationRevised.data
  }

  // async setTheOrder() {
  //   return "nope"
  // }
}

export default new OrderBaseFunctions()
