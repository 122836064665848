import BaseService from '@/services/BaseService'
// import orderBy = require('lodash/orderBy');

class PaymentChannelsService extends BaseService {
  constructor() {
    super()
  }

  async getPaymentChannels(opts = {}) {
    this.endPoint = 'api/v2/payment-channels'
    return this.get(opts)
  }

  async saveNewPaymentChannel(data: any) {
    this.endPoint = 'api/v2/payment-channels'
    return this.post(data)
  }

  async updatePaymentChannel(id: any, data: any) {
    this.endPoint = `api/v2/payment-channels/${id}/update`
    return this.post(data)
  }
    
  async deletePaymentChannel(id) {
    this.endPoint = 'api/v2/payment-channels'
    return this.delete(id)
  }

  async getOnePaymentChannel(id) {
    this.endPoint = `api/v2/payment-channels/${id}`
    return this.get()
  }

  async saveDefaultPaymentCountry(data: any){
    this.endPoint = 'api/v2/country-payments'
    return this.post(data)
  }

  async getCountryPayments(opts) {
    this.endPoint = 'api/v2/country-payments'
    return this.get(opts)
  }
}

export default new PaymentChannelsService()