// node_modules dependencies
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import forEach from "lodash/forEach";
import map from "lodash/map";
import startCase from "lodash/startCase";
import isEmpty from "lodash/isEmpty";
import join from "lodash/join";
import replace from "lodash/replace";
import moment from "moment";

// Base Page
import BaseContainer from "@/containers/Base";

// Services
import OrderService from "@/services/Orders";
import PaymentHistoryService from "@/services/PaymentHistory";
import Calculate from "@/services/Calculate";

// Mixins
import { formatMoney, formatNumber } from "@/mixins/helper";
import Currency from "@/services/Currency";

@Component({})
export default class PaymentHistory extends BaseContainer {
  constructor() {
    super();
  }

  formatMoney = formatMoney;
  formatNumber = formatNumber;

  // Modal Add Payment History
  addPaymentHistory: any = {
    open: false,
    labelItems: [
      {
        text: "Payment",
        value: "payment"
      },
      {
        text: "Refund",
        value: "refund"
      }
    ],
    methodItems: [
      {
        text: "Bank Transfer",
        value: "Bank Transfer"
      },
      {
        text: "Paypal",
        value: "Paypal"
      },
      {
        text: "Credit Card",
        value: "Credit Card"
      }
    ],
    currencyItems: [],
    // currencyItems: [
    //   {
    //     text: 'IDR',
    //     value: 'IDR'
    //   },
    //   {
    //     text: 'USD',
    //     value: 'USD'
    //   }
    // ],
    label: null,
    method: null,
    paidAt: {
      open: false,
      date: null,
      dateFormatted: null,
      tempDate: null
    },
    value: null,
    currency: null,
    note: null
  };

  // Table
  tableHeaders: object[] = [
    {
      text: "Action",
      align: "center",
      sortable: false,
      value: "action",
      class: "action-table-width"
    },
    {
      text: "Payment ID",
      align: "center",
      sortable: false,
      value: "paymentId"
    },
    {
      text: "Type",
      align: "center",
      sortable: false,
      value: "type"
    },
    {
      text: "Payment Method",
      align: "center",
      sortable: false,
      value: "paymentMethod"
    },
    {
      text: "Date Received",
      align: "center",
      sortable: false,
      value: "dateReceived"
    },
    {
      text: "Payment Amount",
      align: "right",
      sortable: false,
      value: "paymentAmount"
    },
    {
      text: "Note",
      align: "center",
      sortable: false,
      value: "note"
    }
  ];

  tableItems: object[] = [];
  tableTotalItems: number | string = 0;
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100];
  tablePagination: any = {
    sortBy: "created_at",
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  };
  tableLoading: boolean = false;

  // Grand Total
  grandTotal: number = 0;
  custom_grand_total: number = 0;
  beenPaid: number = 0;
  needPaid: number = 0;
  currency: string = "";

  orderIdAfterSaved: number = 0;

  modalEdit: boolean = false;

  selectedPaymentHistory: any = null;
  selectedDelete: any = null;
  showDeleteModal: boolean = false;

  @Prop({ required: true })
  orderId: number;

  @Prop({ required: true })
  responseCalculation: any;

  @Prop({ required: true })
  responseCalculationRevised: any;

  // for payload calculation
  @Prop({ required: true })
  payloadCal: any;

  // for payload calculation revised
  @Prop({ required: true })
  payloadCalRev: any;

  // for check comparison
  @Prop({ required: true })
  currentOrderDetails: any;

  // for check order has revised qty or not
  @Prop({ required: true })
  orderItemHasRevisedQty: number;

  mounted() {
    this.getPaymentHistory();
  }

  async getPaymentHistory() {
    const opts: any = {
      params: {
        include: "payments"
      }
    };

    this.tableLoading = true;
    this.tableItems = [];
    try {
      // console.log('this.currentOrderDetails', this.currentOrderDetails)
      // console.log('this.orderItemHasRevisedQty', this.orderItemHasRevisedQty)

      // // init var
      // var resultResponseCalculation = {
      //   data: {
      //     data: {}
      //   }
      // }
      // if (Object.keys(this.payloadCal).length > 0 && this.payloadCal.constructor === Object) {
      //   resultResponseCalculation = await Calculate.calculateOrder(this.payloadCal)
      //   this.responseCalculation = resultResponseCalculation.data.data
      //   // console.log('resultResponseCalculation',resultResponseCalculation)
      // }

      // // init var
      // var resultResponseCalculationRevised = {
      //   data: {
      //     data: {}
      //   }
      // }
      // if (Object.keys(this.payloadCalRev).length > 0 && this.payloadCalRev.constructor === Object) {
      //   resultResponseCalculationRevised = await Calculate.calculateOrder(this.payloadCalRev)
      //   this.responseCalculationRevised = resultResponseCalculationRevised.data.data
      //   // console.log('resultResponseCalculationRevised',resultResponseCalculationRevised)
      // }

      // console.log('this.calculationGrandTotal',this.calculationGrandTotal)
      const response = await OrderService.getOneOrder(this.orderId, opts);
      var dataRelationships = response.data.relationships.payments;
      var dataIncluded = response.included.payments;
      var dataAttributes = response.data.attributes;
      this.grandTotal = this.responseCalculationRevised.grand_total;

      // must same with grand_total from calculation
      // if (this.orderItemHasRevisedQty > 0) {
      //   if (
      //     this.responseCalculation.grand_total ===
      //     this.responseCalculationRevised.grand_total
      //   ) {
      //     this.grandTotal = this.responseCalculation.grand_total;
      //   } else {
      //     this.grandTotal = this.responseCalculationRevised.grand_total;
      //   }
      // } else {
      //   if (
      //     this.responseCalculation.total_duty !==
      //       this.currentOrderDetails.total_duty ||
      //     this.responseCalculation.total_wht !==
      //       this.currentOrderDetails.total_wht ||
      //     this.responseCalculation.total_vat !==
      //       this.currentOrderDetails.total_vat
      //   ) {
      //     this.grandTotal = this.responseCalculation.grand_total;
      //   } else {
      //     this.grandTotal = this.responseCalculationRevised.grand_total;
      //   }
      // }

      this.custom_grand_total = dataAttributes.custom_grand_total;
      this.beenPaid = dataAttributes.been_paid;
      this.needPaid = this.grandTotal - this.beenPaid;
      this.currency = dataAttributes.currency;
      this.addPaymentHistory.currencyItems.push({
        text: this.currency,
        value: this.currency
      });

      forEach(dataRelationships, relationshipPayment => {
        var id_payment = relationshipPayment.id;
        const payments = dataIncluded[id_payment].attributes;
        payments.paidFormatted = moment(payments.paid_at).format("DD/MM/YYYY");
        payments.actionButton = false;
        this.tableItems.push(payments);
      });
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.tableLoading = false;
    }
  }

  // Open Modal Payment History
  onAddPaymentHistoryModalOpen() {
    this.modalEdit = false;
    this.addPaymentHistory.currency = this.currency;
    this.addPaymentHistory.open = true;
  }

  // Close Modal Payment History
  onAddPaymentHistoryModalClosed() {
    this.addPaymentHistory.open = false;
    this.addPaymentHistory.label = null;
    this.addPaymentHistory.method = null;
    // this.addPaymentHistory.currency = null
    this.addPaymentHistory.currency = this.currency;
    this.addPaymentHistory.paidAt.date = null;
    this.addPaymentHistory.paidAt.dateFormatted = null;
    this.addPaymentHistory.value = null;
    this.addPaymentHistory.note = null;
  }

  async onAddPaymentHistoryModalSave() {
    try {
      const valid = await this.$validator.validateAll("addPaymentHistory");
      if (valid) {
        this.showLoading({ text: "Saving..." });

        const payload: any = {
          order_id: this.orderId,
          label: this.addPaymentHistory.label,
          method: this.addPaymentHistory.method,
          paid_at: this.addPaymentHistory.paidAt.date,
          value: this.addPaymentHistory.value,
          currency: this.addPaymentHistory.currency,
          note: this.addPaymentHistory.note
        };

        await PaymentHistoryService.setPaymentHistory(payload);

        // let order_id = response.data.data.attributes.order_id
        // this.orderIdAfterSaved = response.data.data.attributes.order_id

        await this.onAddPaymentHistoryModalClosed();
        // this.getPaymentHistory()
        // setTimeout(
        //   window.location.href = '/orders/' + order_id + '/details'
        //   , 10000);
        this.showSnackbar({
          text: "Saved Successfully!",
          color: "teal",
          timeout: 1500
        });
        this.getPaymentHistory();
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
      // setTimeout(() => {
      //   window.location.href = '/orders/' + this.orderIdAfterSaved + '/details'
      // }, 2000);
    }
  }

  // Open Modal Edit Payment History
  onEditPaymentHistoryModalOpen(props) {
    this.modalEdit = true;
    this.addPaymentHistory.open = true;
    this.addPaymentHistory.label = props.item.label;
    this.addPaymentHistory.method = props.item.method;
    this.addPaymentHistory.currency = props.item.currency;
    this.addPaymentHistory.paidAt.date = props.item.paid_at;
    this.addPaymentHistory.paidAt.dateFormatted = moment(
      this.addPaymentHistory.paidAt.date
    ).format("DD MMMM YYYY");
    this.addPaymentHistory.value = props.item.value;
    this.addPaymentHistory.note = props.item.note;
    this.addPaymentHistory.id = props.item.id;
  }

  // Save Modal Edit Payment History
  async onEditPaymentHistoryModalSave() {
    try {
      const valid = await this.$validator.validateAll("addPaymentHistory");
      if (valid) {
        this.showLoading({ text: "Saving..." });

        const payload: any = {
          order_id: this.orderId,
          label: this.addPaymentHistory.label,
          method: this.addPaymentHistory.method,
          paid_at: this.addPaymentHistory.paidAt.date,
          value: this.addPaymentHistory.value,
          currency: this.addPaymentHistory.currency,
          note: this.addPaymentHistory.note
        };

        await PaymentHistoryService.updatePaymentHistory(
          this.addPaymentHistory.id,
          payload
        );

        this.onAddPaymentHistoryModalClosed();
        // this.closeLoading()
        this.showSnackbar({
          text: "Saved Successfully!",
          color: "teal",
          timeout: 1500
        });
        this.getPaymentHistory();
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
      // this.showSnackbar({
      //   text: "Saved Successfully!",
      //   color: "teal",
      //   timeout: 1500
      // })
      // this.getPaymentHistory()
    }
  }

  // Input Date Received
  onPaidDateInput(event) {
    this.addPaymentHistory.paidAt.tempDate = event;
  }
  onPaidDateCancel() {
    this.addPaymentHistory.paidAt.open = false;
  }
  onPaidDateDone() {
    this.addPaymentHistory.paidAt.date = this.addPaymentHistory.paidAt.tempDate;
    this.addPaymentHistory.paidAt.dateFormatted = moment(
      this.addPaymentHistory.paidAt.tempDate
    ).format("DD MMMM YYYY");
    this.addPaymentHistory.paidAt.open = false;
  }

  openDeleteModal(props) {
    this.selectedPaymentHistory = props.item;
    this.selectedDelete = props.item;
    this.showDeleteModal = true;
  }

  async deletePaymentHistory() {
    try {
      this.showLoading({ text: "Saving..." });
      this.showDeleteModal = false;
      const response = await PaymentHistoryService.deletePaymentHistory(
        this.selectedPaymentHistory.id
      );
      this.showSnackbar({
        text: startCase(
          response.status === 200
            ? "Success"
            : "Failed with HTTP error: " + response.status
        ),
        color: response.status === 200 ? "green" : "red",
        timeout: 1500
      });
      this.getPaymentHistory();
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }
}
