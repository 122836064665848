














































































































































import BasePage from '@/pages/Base'
import { Component, Emit, Prop } from 'vue-property-decorator'
import OrdersService from '@/services/Orders'
@Component
export default class orderId extends BasePage {
  constructor() {
    super()
  }
  // props
  @Prop() orderData: any;
  @Prop({ type: String }) createdBy: string;
  @Prop({ type: String }) updatedBy: string;

  // emit
  @Emit('refreshData')
  getOrderDetails() {}

  // data
  orderCodeModal: any = {
    open: false
  };
  currentOrderCode: any = null;
  orderDateModal: any = {
    open: false
  };
  dateCreated: any = null;
  dateMenu: boolean = false;

  // methods
  async editOrderCode() {
    this.orderCodeModal.open = true
    this.currentOrderCode = this.orderData.order_code
  }

  async closeOrderCode() {
    this.currentOrderCode = null
    this.orderCodeModal.open = false
  }

  async saveOrderCode() {
    try {
      this.showLoading({ text: 'Saving...' })

      var setPayload = {
        order_code: this.orderData.order_code
      }
      // console.log('setPayload',setPayload)

      // updateOrderEdit
      var setParams = {}
      var returnResp = await OrdersService.updateOrderEdit(
        setPayload,
        setParams,
        this.$route.params.id
      )
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
      this.orderCodeModal.open = false
      this.getOrderDetails()
      this.showSnackbar({
        text: 'Saved Successfully!',
        color: 'teal',
        timeout: 1500
      })
    }

    this.orderCodeModal.open = false
    console.log(this.getOrderDetails)
  }
  async editOrderDate() {
    this.orderDateModal.open = true

    var str = this.orderData.created_at
    var res = str.split('T')

    this.dateCreated = res[0]
  }

  async closeOrderDate() {
    this.orderDateModal.open = false
  }

  async saveOrderDate() {
    try {
      this.showLoading({ text: 'Saving...' })

      var str = this.orderData.created_at
      var resSplit = str.split('T')
      resSplit[0] = this.dateCreated

      var timestampNow = Date.now()
      var t = new Date(timestampNow)
      var h = t.getHours()
      var m = t.getMinutes()

      var setHourJoin = this.dateCreated + ' ' + h + ':' + '00' + ':' + '00'
      var setPayload = {
        created_at: setHourJoin
      }
      var setParams = {}
      await OrdersService.updateOrderEdit(
        setPayload,
        setParams,
        this.$route.params.id
      )
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
      this.orderDateModal.open = false
      this.getOrderDetails()
      this.showSnackbar({
        text: 'Saved Successfully!',
        color: 'teal',
        timeout: 1500
      })
    }
  }
  async setDateCreated(dateProp) {
    this.dateCreated = dateProp
    this.dateMenu = false
  }
}
