import BaseService from '@/services/BaseService'

class QualityControlService extends BaseService {
  constructor() {
    super()
  }

  // Save QC Group Item
  saveQCGroupItem(payload: any) {
    this.endPoint = '/api/v2/quality-control/group'
    return this.post(payload).then(response => {
      return response
    })
  }
  // QC Index
  // async getQCs(opts: object = {}) {
  //   this.endPoint = 'qc'
  //   return await this.get(opts)
  // 

  // Get QC
  async getQCs(opts = {}) {
    this.endPoint = 'api/v2/quality-control'
    return await this.get(opts)
  }

  // Get QC Group
  async getQCGroup(opts = {}) {
    this.endPoint = 'api/v2/quality-control/group'
    return await this.get(opts)
  }

  // Delete QC
  async deleteQCs(qcId) {
    this.endPoint = 'api/v2/quality-control'
    return this.delete(qcId)
  }

  // Add and Edit QC
  async saveQCs(payload: any) {
    this.endPoint = '/api/v2/quality-control'
    return this.post(payload).then(response => {
      return response
    })
  }
  
  async approveQc(id: string | number, data: object, opts: object = {}) {
    this.endPoint = `qc/${id}`
    return await this.put(data, opts)
  }
  async deleteQc(id: string | number, opts: object = {}) {
    this.endPoint = 'qc'
    return await this.delete(id, opts)
  }

  // QC Detail
  async getScreenshots(id: string | number, opts: object = {}) {
    this.endPoint = `qc/${id}/screenshots`
    return await this.get(opts)
  }
  async getScreenshotDetail(id: string | number, opts: object = {}) {
    this.endPoint = `qc-screenshots/${id}`
    return await this.get(opts)
  }
  async getScreenshotConversation(id: string | number, opts: object = {}) {
    this.endPoint = `qc-screenshots/${id}/conversations`
    return await this.get(opts)
  }
  async updateScreenshotStatus(id: string | number, data: object, opts: object = {}) {
    this.endPoint = `qc-screenshots/${id}`
    return await this.put(data, opts)
  }

  changeQCStatus(data: object, opts: object = {}) {
    this.endPoint = 'qc'
    return this.post(data, opts).then(response => {
      return response.data
    })
  }
}

export default new QualityControlService()
