// node_modules dependencies
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import forEach from "lodash/forEach";
import map from "lodash/map";
import startCase from "lodash/startCase";
import isEmpty from "lodash/isEmpty";
import join from "lodash/join";
import findIndex from "lodash/findIndex";
import moment from "moment";
import Viewer from "v-viewer/src/component.vue";

// Base Page
import BaseContainer from "@/containers/Base";

// Services
import ProofOfPaymentService from "@/services/ProofOfPayment";
import UserServices from "@/services/UserSettings";
import PaymentChannelServices from "@/services/PaymentChannels";

// Mixins
import { formatMoney, formatNumber } from "@/mixins/helper";
import { lowerCase, upperCase } from "lodash";

@Component({ components: { Viewer } })
export default class ProofOfPayment extends BaseContainer {
  constructor() {
    super();
  }

  // Table
  tableHeaders: object[] = [
    {
      text: "Action",
      align: "center",
      sortable: false,
      value: "action",
      class: "action-table-width"
    },
    {
      text: "Bank Name",
      align: "left",
      sortable: false,
      value: "bank"
    },
    {
      text: "Bank Account Name",
      align: "center",
      sortable: false,
      value: "customer_name"
    },
    {
      text: "Bank Account Number",
      align: "center",
      sortable: false,
      value: "account_number"
    },
    {
      text: "Nominal Transfer",
      align: "right",
      sortable: false,
      value: "total_paid"
    },
    {
      text: "Payment Date",
      align: "center",
      sortable: true,
      value: "transfer_date"
    },
    {
      text: "Submitted by",
      align: "center",
      sortable: false,
      value: "created_by"
    },
    {
      text: "Submitted on",
      align: "center",
      sortable: false,
      value: "created_by"
    },
    {
      text: "Transfer to",
      align: "center",
      sortable: false,
      value: "transfer_to"
    },
    {
      text: "Image",
      align: "center",
      sortable: false,
      value: "proof_of_transfer"
    }
  ];

  tableItems: any = [];
  tableTotalItems: number | string = 0;
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100];
  tablePagination: any = {
    sortBy: "transfer_date",
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  };
  tableLoading: boolean = false;

  selectedId: number = 0;
  modalEdit: boolean = false;
  showDeleteModal: boolean = false;

  paymentProofData: any = {
    open: false,
    id: null,
    bank: {
      items: [
        {
          text: "BCA",
          value: "BCA"
        },
        {
          text: "BRI",
          value: "BRI"
        },
        {
          text: "Mandiri",
          value: "MANDIRI"
        },
        {
          text: "BNI",
          value: "BNI"
        },
        {
          text: "Permata",
          value: "PERMATA"
        },
        {
          text: "Credit Card",
          value: "CREDIT_CARD"
        },
        {
          text: "OVO",
          value: "OVO"
        },
        {
          text: "Other Bank",
          value: "other_bank"
        }
      ],
      selected: null,
      loading: false
    },
    customer_name: null,
    account_number: null,
    total_paid: 0,
    transfer_date: {
      open: false,
      date: null,
      dateFormatted: null,
      tempDate: null
    },
    preview_image: null,
    proof_of_transfer: null,
    transfer_to: {
      items: [],
      selected: null
    }
  };

  viaPlaceholder: any =
    "https://via.placeholder.com/150?text=No Image Available";

  @Prop({ required: true })
  orderId: number;

  @Prop({ required: true })
  currentOrderDetails: any;

  @Prop({ required: true })
  responseCalculation: any;

  @Prop({ required: true })
  responseCalculationRevised: any;

  // for payload calculation
  @Prop({ required: true })
  payloadCal: any;

  // for payload calculation revised
  @Prop({ required: true })
  payloadCalRev: any;

  // for check order has revised qty or not
  @Prop({ required: true })
  orderItemHasRevisedQty: number;

  mounted() {
    this.getPaymentProof();
  }

  @Watch("tablePagination", { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0);
    this.getPaymentProof();
  }

  async getUserById(id) {
    const response = await UserServices.getOneUser(id);
    return response.data.data.attributes;
  }

  async getPaymentProof() {
    try {
      this.tableLoading = true;
      const opts = {
        params: {
          "filter[order_id][is]": this.orderId
        }
      };

      const response = await ProofOfPaymentService.getPaymentProof(opts);
      this.tableItems = [];
      forEach(response.data, proof => {
        const item = {
          ...proof.attributes,
          payment_date: moment(proof.attributes.transfer_date).format(
            "DD MMM YYYY"
          ),
          submitted_at: moment(proof.attributes.created_at).format(
            "DD MMM YYYY"
          ),
          submitted_by: "",
          bank_name: proof.attributes.bank.replace("_", " ")
        };
        this.tableItems.push(item);
      });

      forEach(this.tableItems, async item => {
        const user = await this.getUserById(item.created_by);
        item.submitted_by = user.name;
      });
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.tableLoading = false;
    }
  }

  openDeleteModal(props) {
    this.selectedId = props.item.id;
    this.showDeleteModal = true;
  }

  async deleteProofOfPayment() {
    try {
      this.showLoading({ text: "Saving..." });
      this.showDeleteModal = false;
      const response = await ProofOfPaymentService.deletePaymentProof(
        this.selectedId
      );
      this.showSnackbar({
        text: startCase(
          response.status === 200
            ? "Success"
            : "Failed with HTTP error: " + response.status
        ),
        color: response.status === 200 ? "green" : "red",
        timeout: 1500
      });
      this.getPaymentProof();
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  onEditPaymentProofModalOpen(props) {
    this.getCountryPayments();
    this.modalEdit = true;
    this.paymentProofData = {
      open: true,
      id: props.item.id,
      bank: {
        items: [
          {
            text: "BCA",
            value: "BCA"
          },
          {
            text: "BRI",
            value: "BRI"
          },
          {
            text: "Mandiri",
            value: "MANDIRI"
          },
          {
            text: "BNI",
            value: "BNI"
          },
          {
            text: "Permata",
            value: "PERMATA"
          },
          {
            text: "Credit Card",
            value: "CREDIT_CARD"
          },
          {
            text: "OVO",
            value: "OVO"
          },
          {
            text: "Other Bank",
            value: "other_bank"
          }
        ],
        selected: props.item.bank,
        loading: false
      },
      customer_name: props.item.customer_name,
      account_number: props.item.account_number,
      total_paid: props.item.total_paid,
      transfer_date: {
        open: false,
        date: props.item.transfer_date,
        dateFormatted: moment(props.item.transfer_date).format("DD MMMM YYYY"),
        tempDate: null
      },
      preview_image: props.item.proof_of_transfer,
      proof_of_transfer: null,
      transfer_to: {
        items: [],
        selected: props.item.transfer_to,
        loading: false
      }
    };
  }

  onPaymentProofModalClosed() {
    this.paymentProofData.open = false;
  }

  // Input Date Received
  onTransferDateInput(event) {
    this.paymentProofData.transfer_date.tempDate = event;
  }
  onTransferDateCancel() {
    this.paymentProofData.transfer_date.open = false;
  }
  onTransferDateDone() {
    this.paymentProofData.transfer_date.date = this.paymentProofData.transfer_date.tempDate;
    this.paymentProofData.transfer_date.dateFormatted = moment(
      this.paymentProofData.transfer_date.tempDate
    ).format("DD MMMM YYYY");
    this.paymentProofData.transfer_date.open = false;
  }

  changeImage(e) {
    const file = e.target.files[0];
    if (file.size / 1024 < 5000) {
      this.paymentProofData.proof_of_transfer = file;
    } else {
      this.paymentProofData.proof_of_transfer = null;
      (this.$refs as any).resetData.value = "";
      this.showSnackbar({
        text: "File size exceeds 5 MB",
        color: "red",
        timeout: 2000
      });
    }
  }

  // Image Viewer
  getImageUrl(images: object[]): any {
    return map(images, "imageUrl");
  }
  $viewer: any;
  inited(viewer: any): void {
    this.$viewer = viewer;
  }
  show(): void {
    this.$viewer.show();
  }

  async getCountryPayments() {
    this.paymentProofData.transfer_to.loading = true;
    try {
      const orderBy = await this.getUserById(this.currentOrderDetails.user_id);
      const country_signup = orderBy.country_signup;
      const opts = {
        params: {
          "filter[country_id][is]": country_signup
        }
      };
      const response = await PaymentChannelServices.getCountryPayments(opts);
      this.paymentProofData.transfer_to.items = [];
      forEach(response.data.data, async country_payment => {
        const responseChannel = await PaymentChannelServices.getOnePaymentChannel(
          country_payment.attributes.payment_channel_id
        );
        const item = {
          ...responseChannel.data.data.attributes,
          value: upperCase(
            responseChannel.data.data.attributes.name.replace("VA ", "")
          )
        };
        this.paymentProofData.transfer_to.items.push(item);
      });

      this.paymentProofData.transfer_to.loading = false;
    } catch (error) {
      this.catchHandler(error);
    }
  }

  async editPaymentProof() {
    try {
      const validationResponse = await this.$validator.validateAll(
        "paymentProof"
      );
      if (validationResponse) {
        this.showLoading({ text: "Saving..." });
        this.paymentProofData.open = false;
        let configfile = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token"),
            Identifier: "manage"
          }
        };
        let data = new FormData();
        data.append("order_id", this.currentOrderDetails.id);
        data.append("bank", this.paymentProofData.bank.selected);
        data.append("customer_name", this.paymentProofData.customer_name);
        data.append("account_number", this.paymentProofData.account_number);
        data.append("total_paid", this.paymentProofData.total_paid);
        data.append("transfer_date", this.paymentProofData.transfer_date.date);
        data.append("transfer_to", this.paymentProofData.transfer_to.selected);
        if (this.paymentProofData.proof_of_transfer) {
          data.append(
            "proof_of_transfer",
            this.paymentProofData.proof_of_transfer
          );
        }

        ProofOfPaymentService.updatePaymentProof(this.paymentProofData.id, data)
          .then(response => {
            this.showSnackbar({
              text: "Saved Successfully!",
              color: "green",
              timeout: 1500
            });
            this.getPaymentProof();
          })
          .catch(error => {
            this.showSnackbar({
              text: error.response.data.message,
              color: "red",
              timeout: 1500
            });
          });
      } else {
        this.showSnackbar({
          text: "Please check all fields requirements",
          color: "red",
          timeout: 2000
        });
      }
    } catch (error) {
      this.catchHandler(error);
    } finally {
      this.closeLoading();
    }
  }

  replaceBrokenImage(id) {
    const newIndex = findIndex(this.tableItems, { id: id });
    this.tableItems[newIndex].proof_of_transfer = this.viaPlaceholder;
  }
}
