import Vue from 'vue'
import accounting from 'accounting'
import moment from 'moment'
import { Mutation } from 'vuex-class'
import upperCase from 'lodash/upperCase'
import join from 'lodash/join'
import startCase from 'lodash/startCase'
import snakeCase from 'lodash/snakeCase'
import startsWith from 'lodash/startsWith'
import isEmpty from 'lodash/isEmpty'

// Local Containers
import SearchFilter from '@/containers/SearchFilter/SearchFilter.vue'

// Services
import AuthService from '@/services/Auth'

// Global component use
Vue.component('SearchFilter', SearchFilter)

export default class BaseContainer extends Vue {
  constructor() {
    super()
  }

    // Search and Filters
    searchFilterParams: object = {}

    // veeValidation config
    $_veeValidate: {
        validator: 'new'
    }

    // Vuex Mutations
    @Mutation('showSnackbar')
    showSnackbar
    @Mutation('showLoading')
    showLoading
    @Mutation('closeLoading')
    closeLoading

    // Router goTo
    goTo(routerInstance: any, url: string, target: string = ''): void {
      if (target === '') routerInstance.push(url)
      else window.open(url, target)
    }

    // Money Format
    formatMoney(money: number | string, currency: string = '', decimal: number = 2): any {
      return accounting.formatMoney(money, upperCase(currency) + ' ', decimal)
    }
    unformatMoney(money: any): any {
      return accounting.unformat(money)
    }

    // Number Format
    formatNumber(number: any): any {
      return accounting.formatNumber(number)
    }
    unformatNumber(number: any): any {
      return accounting.unformat(number)
    }
    toFixed(number: any): any {
      return accounting.toFixed(number, 2)
    }
    toFixedCbm(number: any): any {
      return accounting.toFixed(number, 6)
    }
    // Date Format
    now() {
      return moment().format('YYYY-MM-DD')
    }
    toFullDate(date?: string) {
      return moment(date).format('DD MMMM YYYY')
    }
    toMediumDate(date?: string) {
      return moment(date).format('DD MMM YYYY')
    }
    toDatabaseDate(date?: string) {
      return moment(date).format('YYYY-MM-DD')
    }
    toISOString(date?: string) {
      return moment(date).toISOString()
    }
    toUnixMillisecondTimestamp(date?: string): number {
      return moment(date).valueOf()
    }

    // API Handler
    catchHandler(error: any) {
      console.error('CATCH HANDLER -> ', error)

      this.closeLoading()
      let errorMessage: string = ''

      if (error) {
        if (error.response.status === 401 && window.localStorage.getItem('access_token')) {
          errorMessage = 'Session Expired! Please Re-Login'
          AuthService.logout()
        } else {
          const errorResponse: any = error.response.data
          if (!isEmpty(errorResponse)) {
            errorMessage = `Error ${error.response.status} - ${startCase(error.message)}`
            if (!isEmpty(errorResponse.message)) {
              errorMessage = `${errorMessage} - ${startCase(errorResponse.message)}`
            } else if (!isEmpty(errorResponse.error) && !isEmpty(errorResponse.error_description)) {
              const desc = Object.keys(errorResponse.error_description).reduce((carry: any, item: any) => {
                return carry.concat(errorResponse.error_description[item])
              }, [])
              errorMessage = `${startCase(join(desc, ' - '))}`
            }
          } else errorMessage = `Error ${error.response.status} -  ${startCase(error.message)}`
        }
      } else errorMessage = 'Unknown Error - Check Your Console'
      this.showSnackbar({ text: errorMessage, color: 'primary', timeout: 4000 })
    }

    // Case-ing
    toStartCase(text: string): string {
      return startCase(text)
    }
    toSnakeCase(text: string): string {
      return snakeCase(text)
    }

    // Status color chooser
    statusColor(status: string = '') {
      if (
        snakeCase(status) === snakeCase('Pending') || 
            snakeCase(status) === snakeCase('on hold') || 
            snakeCase(status) === snakeCase('Failed')) {
        return 'orange darken-2 white--text'
      } else if (
        snakeCase(status) === snakeCase('Waiting Quote Approval') ||
            startsWith(snakeCase(status), snakeCase('Progress')) ||
            startsWith(snakeCase(status), snakeCase('On Process')) ||
            snakeCase(status) === snakeCase('Processing') ||
            snakeCase(status) === snakeCase('Question') ||
            snakeCase(status) === snakeCase('Found') ||
            snakeCase(status) === snakeCase('Passed')
      ) {
        return 'green darken-2 white--text'
      } else if (
        snakeCase(status) === snakeCase('Delivered') ||
            snakeCase(status) === snakeCase('Complete') ||
            snakeCase(status) === snakeCase('Completed') ||
            snakeCase(status) === snakeCase('Done') ||
            snakeCase(status) === snakeCase('Processed') ||
            snakeCase(status) === snakeCase('Accepted') ||
            snakeCase(status) === snakeCase('Paid') ||
            snakeCase(status) === snakeCase('Paid and Processed')
      ) {
        return 'blue darken-2 white--text'
      } else if (snakeCase(status) === snakeCase('Rejected')) {
        return 'pink white--text'
      } else if (snakeCase(status) === snakeCase('Unpaid')) {
        return 'grey lighten-3 black--text'
      } else {
        return 'black--text'
      }
    }

    // Lodash Helper for template views
    isEmpty(variable) {
      return isEmpty(variable)
    }

    log(variable) {
      console.log(variable)
    }
}
